import {
  IUploadedQuote,
  IUploadedQuoteDetails,
} from '@interfaces';

//TODO Implement methods and use it across app

export class SubQuoteModel implements IUploadedQuote {
  public id: string;
  public _id: string;
  public quotedAt: string; // "2019-03-27T18:19:55.809Z",
  public fileUrl?: string; // "https://staging-uploaded-quotes.s3.amazonaws.com/file.pdf-1553710795394",
  public details: IUploadedQuoteDetails;
  public __v: number;

  constructor(data: any = {}) {
    this._id = data._id || 0;
    this.id = data.id || null;
    this.quotedAt = data.quotedAt|| null;
    this.fileUrl = data.fileUrl || null;
    this.details = data.details || null;
    this.__v = data.__v || 0;
  }

}

