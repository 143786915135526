/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *  Registration component contains form to register in the application
 *

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = [".registration[_ngcontent-%COMP%]{font-family:Lato}.registration[_ngcontent-%COMP%]   .registration-title[_ngcontent-%COMP%]{font-size:16px;color:#666980;line-height:26px;margin-bottom:2em}@media only screen and (max-width:768px){.registration[_ngcontent-%COMP%]   .registration-title[_ngcontent-%COMP%]{margin-bottom:1em}}.registration[_ngcontent-%COMP%]   .registration-subtitle[_ngcontent-%COMP%]{position:relative;font-size:22px;color:#222326}.registration[_ngcontent-%COMP%]   .notice[_ngcontent-%COMP%]{font-family:'Open Sans';font-size:12px;text-align:center}.registration[_ngcontent-%COMP%]   .icon-success[_ngcontent-%COMP%]{background:url(success_checkmark.9f1b10c8319291598aee.svg) center no-repeat;display:inline-block;width:110px;height:110px}.registration[_ngcontent-%COMP%]   .success-title[_ngcontent-%COMP%]{font-size:30px;color:#222326;margin-bottom:0}.registration[_ngcontent-%COMP%]   .success-subtitle[_ngcontent-%COMP%]{font-size:16px;color:#6f717d}"];
export { styles as styles };
