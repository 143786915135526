/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./field-error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./field-error.component";
var styles_FieldErrorComponent = [i0.styles];
var RenderType_FieldErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FieldErrorComponent, data: {} });
export { RenderType_FieldErrorComponent as RenderType_FieldErrorComponent };
export function View_FieldErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "field-error"]], [[2, "show", null], [2, "minimal", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "strong", [], [[2, "text-center", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "error-icon"], ["src", "/assets/error-icon.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isInvalid && _co.errors); var currVal_1 = _co.minimalError; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.textCenter; _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.getErrorMessage(); _ck(_v, 3, 0, currVal_3); }); }
export function View_FieldErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-field-error", [], null, null, null, View_FieldErrorComponent_0, RenderType_FieldErrorComponent)), i1.ɵdid(1, 114688, null, 0, i2.FieldErrorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FieldErrorComponentNgFactory = i1.ɵccf("app-field-error", i2.FieldErrorComponent, View_FieldErrorComponent_Host_0, { errors: "errors", label: "label", isInvalid: "isInvalid", noLabel: "noLabel", minimalError: "minimalError", textCenter: "textCenter" }, {}, []);
export { FieldErrorComponentNgFactory as FieldErrorComponentNgFactory };
