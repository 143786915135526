export const MARITAL_STATUSES: any = {
  SINGLE: 'single',
  MARRIED: 'married',
  DIVORCED: 'divorced',
  SEPARATED: 'separated',
  WINDOWED: 'widowed',
};

export const RELATIONSIP_TYPES: any = {
  SPOUSE: 'spouse',
  PARENT: 'parent',
  SIBLING: 'sibling',
  CHILD: 'child',
  GRANDPARENT: 'grandparent',
  GRANDCHILD: 'grandchild',
  OTHER: 'other',
};
