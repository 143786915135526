import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  constructor(private _elementRef: ElementRef) {
  }

  @Output() public clickOutside: EventEmitter<null> = new EventEmitter();

  @HostListener('window:touch', ['$event.target'])
  @HostListener('document:click', ['$event.target'])
  @HostListener('document:touchend', ['$event.target']) // Fix ios devices
  public onClick(targetElement: any): void {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit(null);
    }
  }
}
