<div
  class='{{customClass}}'
  [class.floating-label]='floatingLabel && !fullBorder'
  [class.floating-label-new]='floatingLabel && fullBorder'
  [class.iconized-input]='iconized'
  [class.disabled]='isDisabled'
  [class.contrast]='contrast'
  [class.right]='rightIcon'>
  <i
    *ngIf='iconized'
    [class]='iconClass'
  ></i>
  <label *ngIf='showLabel' for='id_{{name}}'>{{label}}</label>
  <input
    id='id_{{name}}'
    *ngIf='!decimalNumber'
    #input
    [type]='type'
    [name]='name'
    [placeholder]='actualPlaceholder'
    [class.error]='isInvalid()'
    [class.success]='isValid()'
    [class.floating-input]='floatingLabel'
    (focus)='onFocus()'
    (blur)='onBlur()'
    [formControl]='control'
    [value]='control.value'
    [min]='min'
    [max]='max'
    [maxlength]='maxlength'
  />
  <input
    id='id_{{name}}'
    *ngIf='decimalNumber'
    #input
    [type]='type'
    [name]='name'
    [placeholder]='actualPlaceholder'
    [class.error]='isInvalid()'
    [class.success]='isValid()'
    [class.floating-input]='floatingLabel'
    (focus)='onFocus()'
    (blur)='onBlur()'
    [formControl]='control'
    decimal-number
    [value]='control.value'
  />
  <label *ngIf='floatingLabel' for='id_{{name}}'>{{label}}</label>
</div>
<app-field-error
  [class.absoluteError]='absoluteError'
  [isInvalid]='isInvalid()'
  [errors]='control.errors'
  [label]='label'
  [minimalError]='minimalError'
></app-field-error>
