import {
 Directive,
 ElementRef,
 HostListener,
 Input,
} from '@angular/core';

import {
 parse,
 format,
 AsYouType,
} from 'libphonenumber-js';

interface IFormattedPhone {
  libFormatted: string;
  international: string;
  parsed: any;
  dashed: string;
  [key: string]: any;
}

@Directive({
  selector: '[appTelInput]',
})
export class TelInputDirective {

  @Input() formatType: string = 'libFormatted';

  constructor(
    private _elementRef: ElementRef,
  ) {

  }

  @HostListener('input', ['$event'])
  public onInput(event: any): void {
    const targetElement = event.target;
    const value = targetElement.value;
    const formatted = this.parseValue(value);

    this._elementRef.nativeElement.setAttribute('data-lib-formatted', formatted.libFormatted);
    this._elementRef.nativeElement.setAttribute('data-international', formatted.international);
    this._elementRef.nativeElement.setAttribute('data-parsed', formatted.parsed.phone);
    this._elementRef.nativeElement.setAttribute('data-dashed', formatted.dashed);
    const formattedType = formatted[this.formatType];

    switch (this.formatType) {
      case 'parsed':
        if (formatted.parsed.phone) {
          this._elementRef.nativeElement.value = formattedType.phone;
        } else {
          this._elementRef.nativeElement.value = formatted.libFormatted;
        }
        break;

      default:
        this._elementRef.nativeElement.maxLength = this.typeLength();
        this._elementRef.nativeElement.value = formattedType;

        setTimeout(() => {
          this._elementRef.nativeElement.setSelectionRange(formattedType.length, formattedType.length);
        }, 0);
        break;
    }
  }

  private typeLength(): Number {
    switch (this.formatType) {
      case 'libFormatted': return 14;
      case 'dashed': return 12;
      case 'parsed': return 10;
      case 'international': return 15;
      default: return 12;
    }
  }

  private parseValue(value: string): IFormattedPhone {

    const asYouType = new AsYouType('US');

    let libFormatted = asYouType.input(value);
    // Seems that libphonenumber-js doesn't handle deleting the parenthesis properly.
    if (libFormatted.length === 5) {
      libFormatted = libFormatted.replace(')', '').trim();
    }
    const dashed = libFormatted.replace(' )', '').replace('(', '').replace(') ', '-').replace(')', '');

    return {
      libFormatted: libFormatted, // (xxx) xxx-xxxx
      international: format(value, 'US', 'International'), // +x xxx xxx xxxx
      parsed: parse(value, 'US'), // { country: 'US', phone: 'xxxxxxxxxx' }
      dashed: dashed, // xxx-xxx-xxxx
    } as IFormattedPhone;

  }

}
