import * as i0 from "@angular/core";
export class AccountingService {
    constructor() { }
    formatMoney(value, decimals = 0) {
        if (typeof accounting === 'undefined') {
            return value;
        }
        else {
            return accounting.formatMoney(value, '$', decimals);
        }
    }
    formatNumber(value, decimals = 0) {
        if (typeof accounting === 'undefined') {
            return value;
        }
        else {
            return accounting.formatNumber(value, decimals);
        }
    }
    parse(value) {
        if (typeof accounting === 'undefined') {
            return value;
        }
        else {
            return accounting.parse(value);
        }
    }
    unformat(value) {
        if (typeof accounting === 'undefined') {
            return value;
        }
        else {
            return accounting.unformat(value);
        }
    }
}
AccountingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountingService_Factory() { return new AccountingService(); }, token: AccountingService, providedIn: "root" });
