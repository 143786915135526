import {
  Injectable,
} from '@angular/core';
import { Subject } from 'rxjs/Rx';
import { WebsocketService } from './websocket.service';
import { environment } from '@environments/environment';
import { RequestService } from './request.service';

@Injectable()
export class NotificationsService {

  constructor(
    private wsService: WebsocketService,
    private requestService: RequestService,
  ) {
  }

  private url: string = `wss://${environment.push_api_url}/subscribe`;

  public notifications: Subject<any> | null = null;

  public init(): void {
    if (this.notifications) {
      return;
    }
    // FED: I don't think we want to establish a connection in the server
    // so check for instance of window in a browser context
    if (typeof window !== 'undefined') {

      this.notifications = <Subject<any>>this.wsService
        .connect(this.url)
        .map((response: MessageEvent): any => {
          const data = JSON.parse(response.data);
          return data;
        });
    }
  }

  public getMessages(): Promise<any> {
    return this.requestService.promise('get', '/api/conversations/messages', { read: false });
  }

  public sendReadReceipt(id: string): Promise<any> {
    return this.requestService.promise('patch', `/api/conversations/messages/${id}/read`);
  }
}
