<div class="forgot-container col-md-5">
  <div class="forgot-header text-center">
    <h1 class="forgot-title">Reset your password</h1>
  </div>

  <div class="icon-sqz"></div>
  <div class="forgot-body">
    <h4 *ngIf="loading" class='loading text-center'>Sending...</h4>

    <p *ngIf="message" class='error text-center'><b>{{message}}</b></p>

    <form class="form-forgot" (submit)="sendReset()" *ngIf="!loading" [formGroup]="form">
      <app-text-input
        label="Email"
        name="email"
        [floatingLabel]="true"
        [control]='form.controls.email'
      ></app-text-input>
      <button type="submit"
        class="btn btn-lg btn pink btn-block btn-signin"
        [disabled]="!form.valid">
        Send Reset Email
      </button>
    </form>

  </div>
</div>
<div class="contact-footer text-center">
  <p>support@squeeze.com</p>
  <p>901 NW 35th Street, Boca Raton 33431</p>
</div>
