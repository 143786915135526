import { Injectable } from '@angular/core';
import { GlobalModalTypes } from '@constants';
import { IGlobalModalsOptions } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class GlobalModalService extends GlobalModalTypes {

  // managment
  public type: string = this.REGULAR;
  public iconType: string | null = null;
  public show: boolean = false;

  // content
  public title: string | null = null;
  public description: string | null = null;

  // sideeffects
  public callbacks: any = {
    confirm: null,
    close: null,
  };

  constructor() {
    super();
  }

  public setConfigs(options: IGlobalModalsOptions): void {
    const {
      show,
      title,
      type,
      description,
      callbacks: {
        confirm,
        close,
      },
      } = options;

    this.show = show;
    this.title = title;
    this.type = type;
    this.description = description;

    // Callbacks
    this.callbacks.confirm = confirm;
    this.callbacks.close = close;

    // Extended
    if (options.iconType) {
      this.iconType = options.iconType;
    }
  }

  public toogleModal(confrimFlag?: boolean): void {
    this.show = !this.show;

    confrimFlag ? this.confirm() : this.close();
    if (!this.show) {
      this.reset();
    }
  }

  public close(): void {
    this.callbacks.close && this.callbacks.close();
  }

  public confirm(): void {
    this.callbacks.confirm && this.callbacks.confirm();
  }

  public reset(): void {
    this.show = false;
    this.title = '';
    this.type = this.REGULAR;
    this.callbacks = {
      confirm: null,
      close: null,
    };
  }
}
