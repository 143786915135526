import { Component } from '@angular/core';

@Component({
  selector: 'app-home-quote',
  templateUrl: './home-quote.component.html',
  styleUrls: [
    '../../home.component.scss',
    './home-quote.component.scss',
  ],
})

export class HomeQuoteComponent {

  constructor(
    ) {
  }

}
