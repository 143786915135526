export const promocodesStatus = {
  ALREADY_APPLIED: 'already_applied',
  SUCCESS: 'success',
  EXPIRED: 'expired',
  NOT_FOUND: 'not_found',
  VALID: 'valid',
};

export const messagesAboutStatus = {
  [promocodesStatus.SUCCESS]: 'Success',
  [promocodesStatus.ALREADY_APPLIED]: 'Already applied',
  [promocodesStatus.EXPIRED]: 'Promo code expired',
  [promocodesStatus.NOT_FOUND]: 'Promo code not found',
};
