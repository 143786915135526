/// <reference types="@types/googlemaps" />
import { Injectable ,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {
  ValidationErrors,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class LocationAsyncValidatorService {
  invalidAddress: ValidationErrors = {
    invalid: true,
    data: '',
    message: 'Must be a Valid address',
  } as ValidationErrors;

  public isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private mapsAPILoader: MapsAPILoader,
  ) {
    this.validateLocation = this.validateLocation.bind(this);
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public validateLocation(control: FormControl | AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    if (this.isBrowser) {
      return this.mapsAPILoader.load().then(() => {
        const geocoder = new google.maps.Geocoder();
        const _this = this;
    
        return new Promise(function (resolve: any): any {
          geocoder.geocode({
            'address': `?address=${control.value.state}`,
            componentRestrictions: {
              country: 'US',
              postalCode: control.value.zipcode,
              administrativeArea: control.value.state,
            },
          }, (_data: any, status: any) => {
    
            /*
              remove so strict validation (partly match is ON),
              if need set to strict add "|| !_this.checkResults(data)" to check status, (partly match is OFF),
              and retur street to control value
             */
            if (status === 'ZERO_RESULTS') {
              resolve(_this.invalidAddress);
            } else {
              resolve(null);
            }
          });
        });
      });
    } else {
      return new Promise((): void => {});
    }
  }

  checkResults(data: any): any {
    return data.find((item: any) => item.types.includes('street_address'));
  }
}
