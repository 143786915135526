import { Pipe, PipeTransform } from '@angular/core';
import { AccountingService } from '../services/accounting.service';

@Pipe({ name: 'formatMoney' })
export class FormatMoneyPipe implements PipeTransform {

  constructor(
    private accounting: AccountingService,
  ) {}

  transform(value: number, precision: number = 0): string {
    return this.accounting.formatMoney(value, precision);
  }
}
