/** Registration component contains form to register in the application
 *
 */

import {
  Component,
  OnInit,
} from '@angular/core';

import { AuthService } from '../../auth/auth.service';

import {
  REGISTRATION_STAGE_INFO,
} from '../../common/constants';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})

export class RegistrationComponent implements OnInit {
  public stage: string = REGISTRATION_STAGE_INFO;

  constructor(
    private authService: AuthService,
  ) {

  }

  ngOnInit(): void {
  }

  public hideRegistrationModal(): void {
    this.authService.changeRegistrationModalVisibility(false);
  }

  public stageChange(stage: string): void {
    this.stage = stage;
  }

  public isStage(stageName: string): boolean {
    return this.stage === stageName;
  }
}
