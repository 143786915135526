/** Header component contains header of the application
 *
 */
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { IHeaderLinks } from './interfaces';
import { AuthService } from '../../auth/auth.service';
import {
  HEADER_LINKS,
  HEADER_OTHER_STYLE_URLS,
} from './header.constants';
import { NotificationModel } from '@common/models';
import { SessionStorage } from 'ngx-webstorage';
import { NotificationsService } from '@common/services/notifications.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ],
})
export class HeaderComponent implements OnInit, OnDestroy {

  private menu: Array<IHeaderLinks>;
  private toggleRegisterSubscription: any;
  private toggleLoginSubscription: any;
  private loggedInSubscription: any;
  private notificationsSubscription: any;

  public showNav: boolean = false;
  public isShowRegistrationModal: boolean = false;
  public isShowLoginModal: boolean = false;
  public showNotifications: boolean = false;
  public notifications: Array<any> = [];
  public messages: Array<any> = [];

  @Input() navIsFixed: boolean = false;
  @Input() browserName: any;

  public showUnsupportedBrowserMessage: boolean = false;

  @SessionStorage('acknowledgedBrowserSupport', false)
  public acknowledgedBrowserSupport!: boolean;

  get otherStyleOfHeader(): boolean {
    return !!(this.authService.buyer || HEADER_OTHER_STYLE_URLS.find((url: string) => this.router.url.indexOf(url) !== -1));
  }

  get headerLinks(): any {
    return this.menu;
  }

  get notificationCount(): number {
    const unreadFilter = (obj: any): any => !obj.read;

    const unreadNotifications = this.notifications.filter(unreadFilter);
    const unreadMessages = this.messages.filter(unreadFilter);

    return unreadNotifications.length + unreadMessages.length;
  }

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  get userAccount(): any {
    return this.authService.userAccount;
  }

  constructor(
    public authService: AuthService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private notificationsService: NotificationsService,

  ) {
    this.menu = HEADER_LINKS;

    this.authService.showUnsupportedModalObserv.subscribe((value: any) => {
      this.showUnsupportedBrowserMessage = value;
      this.cdRef.detectChanges();
      if (!this.showUnsupportedBrowserMessage && !this.authService.acknowledgedBrowserSupport) {
        this.acknowledgedBrowserSupport = true;
        this.authService.acknowledgedBrowserSupport = this.acknowledgedBrowserSupport;
      }
    });

    this.toggleRegisterSubscription = this.authService.toggleRegistrationModal.subscribe(
      (result: boolean): void => {
        this.isShowRegistrationModal = result;
      },
      (error: any): void => {
        console.log('error', error);
      });

    this.toggleLoginSubscription = this.authService.toggleLoginModal.subscribe(
      (result: boolean): void => {
        this.isShowLoginModal = result;
      },
      (error: any): void => {
        console.log('error', error);
      });

    this.loggedInSubscription = this.authService.getLoggedIn().subscribe((loggedIn: boolean) => {
      if (loggedIn) {
        this.initNotifications();
      }
    });
  }

  ngOnInit(): void {
    this.authService.browserName = this.browserName;
    this.authService.acknowledgedBrowserSupport = this.acknowledgedBrowserSupport;

    this.getMessages();
  }

  ngOnDestroy(): void {
    if (this.toggleRegisterSubscription) {
      this.toggleRegisterSubscription.unsubscribe();
    }
    if (this.toggleLoginSubscription) {
      this.toggleLoginSubscription.unsubscribe();
    }
    if (this.notificationsSubscription) {
      this.notificationsSubscription.unsubscribe();
    }
    if (this.loggedInSubscription) {
      this.loggedInSubscription.unsubscribe();
    }
  }

  public initNotifications(): void {
    this.notificationsService.init();
    this.subscribeToPushNotifications();
  }

  public toggleNotifications(event: any): void {
    event.stopPropagation();
    this.showNotifications = !this.showNotifications;
  }

  public showRegistrationModal(): void {
    if (this.authService.browserName !== 'IE' || this.acknowledgedBrowserSupport) {
      this.authService.changeRegistrationModalVisibility(true);
    } else {
      this.authService.checkBrowser();
      this.authService.browserModalClosed.subscribe((value: any): void => {
        if (value) {
          this.authService.changeRegistrationModalVisibility(true);
        }
      });
    }
  }

  emitShowUnsupportedBrowser(val: boolean): void {
    this.authService.emitShowUnsupportedBrowserModal(val);
  }

  public showLoginModal(): void {
    if (this.authService.browserName !== 'IE' || this.acknowledgedBrowserSupport) {
      this.authService.changeLoginModalVisibility(true);
      this.showNav = false;
    } else {
      this.authService.checkBrowser();
      this.authService.browserModalClosed.subscribe((value: any): void => {
        if (value) {
          this.authService.changeLoginModalVisibility(true);
          this.showNav = false;
        }
      });
    }
  }

  public logout(): void {
    this.authService.logout().then((_response: any): void => {
      this.router.navigate(['/']);
    });
  }

  public getLink(): string {
    return this.isLoggedIn ? '/portal/dashboard' : '/';
  }

  public onCloseBrowserSupportModal(): void {
    this.showUnsupportedBrowserMessage = false;
    this.acknowledgedBrowserSupport = true;
    this.authService.acknowledgedBrowserSupport = this.acknowledgedBrowserSupport;
    this.authService.browserModalClosed.next(true);
  }

  private subscribeToPushNotifications(): void {
    if (!this.notificationsService.notifications) {
      console.warn('Attempt to subscribe to notifications too early.');
      return;
    }

    if (this.notificationsSubscription) {
      this.notificationsSubscription.unsubscribe();
    }

    this.notificationsSubscription = this.notificationsService.notifications
      .subscribe((notif: any) => {
        console.log('header notification', notif);
        const notification: any = new NotificationModel(notif);
        this.notifications.push(notification);
      });
  }

  private getMessages(): void {
    this.notificationsService.getMessages()
      .then((response: any) => {
        if (response && response.body) {
          this.messages = response.body.results
            .filter((msg: any) => !msg.read)
            .map((msg: any) => new NotificationModel(msg));
        }
      })
      .catch((errorResponse: any) => {
        console.log('Could not retrieve messages', errorResponse);
      });
  }
}
