/** Login form component contains form to register in the application
 *
 */

import {
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { LocalStorage } from 'ngx-webstorage';
import { Router } from '@angular/router';

import { EmailAsyncValidatorService } from '../../../common/validators/email-async-validator.service';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})

export class LoginFormComponent implements OnInit, OnDestroy {

  private subscription: any;

  public loginForm!: FormGroup;
  public showLoginForm: boolean = false;
  public isInfoStage: boolean = true;
  public loading: boolean = false;
  public errorMessage: {[key: string]: string} | null = null;

  @LocalStorage('buyer')
  public buyer: any;

  constructor(
    private formBuilder: FormBuilder,
    private emailAsyncValidator: EmailAsyncValidatorService,
    private authService: AuthService,
    private router: Router,
  ) {
    this.subscription = this.authService.isShowLoginModal().subscribe(
      (result: boolean): void => {
        this.showLoginForm = result;
      },
      (error: any): void => {
        console.log('error in subscription', error);
      });
  }

  ngOnInit(): void {
    this.createForms();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public hideLoginModal(): void {
    this.authService.changeLoginModalVisibility(false);
  }

  private createForms(): void {
    this.loginForm = this.formBuilder.group({
      emailAddress: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
        this.emailAsyncValidator.validateEmailFormatOnly,
      ],
      password: [
        null,
        Validators.compose([
          Validators.minLength(0),
          Validators.maxLength(100),
          Validators.required,
        ]),
      ],
    });
  }

  public onSubmit(): void {

    if ( !this.loginForm || !this.loginForm.value ) {
      return;
    }

    const buyerData = { ...this.loginForm.value };
    this.loading = true;

    this.authService.login(buyerData)
      .then((buyer: any): void => {

        this.buyer = buyer;
        this.loading = false;
        if (this.loginForm) {
          this.loginForm.markAsPristine({onlySelf: true});
        }
        this.hideLoginModal();
        this.router.navigate(['/portal/dashboard']);
      })
      .catch((errorResponse: any): void => {

        if (this.loginForm) {
          this.loginForm.markAsPristine({ onlySelf: true });
        }

        this.loading = false;
        this.errorMessage = { message: 'Oh dear, something went wrong' };

        // not sure why, but just in case:
        try {
          errorResponse = errorResponse.json();
        } catch (e) {}
        if (errorResponse && errorResponse.error) {
          if (errorResponse.error &&
            typeof errorResponse.error === 'string') {
            this.errorMessage = { message: errorResponse.error };
          } else if (errorResponse.error.error &&
            typeof errorResponse.error.error === 'string') {
            this.errorMessage = { message: errorResponse.error.error };
          }
        }
      });
  }

  public isLoginError(): boolean {
    return !!this.errorMessage && (this.loginForm.touched || this.loginForm.dirty);
  }
}
