/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./layout/header/header.component.ngfactory";
import * as i3 from "./layout/header/header.component";
import * as i4 from "./auth/auth.service";
import * as i5 from "@angular/router";
import * as i6 from "./common/services/notifications.service";
import * as i7 from "./layout/footer/footer.component.ngfactory";
import * as i8 from "./layout/footer/footer.component";
import * as i9 from "./shared/global-modals/global-modals.component.ngfactory";
import * as i10 from "./shared/global-modals/global-modals.component";
import * as i11 from "./common/services/global-modals.service";
import * as i12 from "@angular/common";
import * as i13 from "./app.component";
import * as i14 from "./common/services/guid.service";
import * as i15 from "ngx-device-detector";
import * as i16 from "./common/services/version-check.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [["class", "no-print"]], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i3.HeaderComponent, [i4.AuthService, i5.Router, i1.ChangeDetectorRef, i6.NotificationsService], { navIsFixed: [0, "navIsFixed"], browserName: [1, "browserName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navIsFixed; var currVal_1 = _co.deviceInfo.browser; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, i7.View_FooterComponent_0, i7.RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i8.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-global-modals", [], null, null, null, i9.View_GlobalModalsComponent_0, i9.RenderType_GlobalModalsComponent)), i1.ɵdid(1, 49152, null, 0, i10.GlobalModalsComponent, [i11.GlobalModalService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "site-wrapper"]], [[2, "fixed-nav", null], [2, "common-page", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(4, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(8, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.useHeader; _ck(_v, 4, 0, currVal_2); _ck(_v, 6, 0); var currVal_3 = _co.useFooter; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navIsFixed; var currVal_1 = (_co.buyer && _co.buyer.account); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i13.AppComponent, [i1.PLATFORM_ID, i12.DOCUMENT, i14.GuidService, i15.DeviceDetectorService, i16.VersionCheckService, i12.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
