import { RequestService } from '../services/request.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/request.service";
export class CreditCardValidatorService {
    constructor(requestService) {
        this.requestService = requestService;
        this.url = '/api/creditcards/validator';
        this.invalid = {
            invalid: true,
            message: 'is invalid',
        };
        this.invalidFormat = {
            format: true,
            message: 'has invalid format',
        };
        this.invalidCardExpDate = {
            format: true,
            message: 'has invalid expiration',
        };
        this.invalidCvc = {
            length: true,
            message: 'has invalid format',
        };
        this.validateNumberAsync = this.validateNumberAsync.bind(this);
        this.validateCardExpDateAsync = this.validateCardExpDateAsync.bind(this);
        this.validateCVC = this.validateCVC.bind(this);
    }
    validateNumberAsync(control) {
        const value = control.value;
        return this.verifyNumber(value)
            .then((response) => {
            this.validateNumberResponse = response;
            return response && response.isValid ? null : this.invalid;
        })
            .catch(() => this.invalid);
    }
    validateCVC(control) {
        const value = control.value;
        if (!value) {
            return null;
        }
        let isFormatValid;
        try {
            isFormatValid = value.length === this.validateNumberResponse.card.code.size;
        }
        catch (e) {
            isFormatValid = false;
        }
        return isFormatValid ? null : this.invalidCvc;
    }
    validateCardExpDateAsync(control) {
        const value = control.value;
        return this.verifyExpiration(value)
            .then((response) => response && response.isPotentiallyValid ? null : this.invalid)
            .catch(() => this.invalidCardExpDate);
    }
    verifyNumber(cardNumber) {
        return this.requestService
            .promise('get', `${this.url}/number`, { value: cardNumber });
    }
    verifyExpiration(cardExpDate) {
        return this.requestService
            .promise('get', `${this.url}/expiration-date`, { value: cardExpDate });
    }
}
CreditCardValidatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreditCardValidatorService_Factory() { return new CreditCardValidatorService(i0.ɵɵinject(i1.RequestService)); }, token: CreditCardValidatorService, providedIn: "root" });
