import { IHeaderLinks } from './interfaces';

export const HEADER_LINKS: Array<IHeaderLinks> = [
//   {
//   path: './',
//   fragment: 'whatWeDo',
//   label: 'What we do',
// }, {
//   path: './',
//   fragment: 'chooseUs',
//   label: 'Why Choose Us',
// }, {
//   path: './',
//   fragment: 'leadTypes',
//   label: 'Lead types',
// }, {
//   path: './',
//   fragment: 'testimonials',
//   label: 'Testimonials',
// }, {
//   path: './',
//   fragment: 'contact',
//   label: 'Contact',
// },
];

export const HEADER_OTHER_STYLE_URLS: Array<any> = [
  '/404',
  '/forgot-password',
  '/reset-password',
];
