/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../global-modals.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./global-notify.component";
import * as i3 from "../../../common/services/global-modals.service";
var styles_GlobalNotifyComponent = [i0.styles];
var RenderType_GlobalNotifyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GlobalNotifyComponent, data: {} });
export { RenderType_GlobalNotifyComponent as RenderType_GlobalNotifyComponent };
export function View_GlobalNotifyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-notify"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn pink uppercase"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toogleModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Got it"]))], null, null); }
export function View_GlobalNotifyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-global-notify", [], null, null, null, View_GlobalNotifyComponent_0, RenderType_GlobalNotifyComponent)), i1.ɵdid(1, 49152, null, 0, i2.GlobalNotifyComponent, [i3.GlobalModalService], null, null)], null, null); }
var GlobalNotifyComponentNgFactory = i1.ɵccf("app-global-notify", i2.GlobalNotifyComponent, View_GlobalNotifyComponent_Host_0, {}, {}, []);
export { GlobalNotifyComponentNgFactory as GlobalNotifyComponentNgFactory };
