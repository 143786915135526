/** Login component contains form to register in the application
 *
 */

import {
  Component,
  OnInit,
} from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { REGISTRATION_STAGE_INFO } from '@common/constants';
import {
  ALREADY_EXIST,
  DEFAULT_LOGIN,
} from '../auth.constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
  public stage: string = REGISTRATION_STAGE_INFO;

  get defaultStatus(): string {
    return DEFAULT_LOGIN;
  }

  get alreadyExistStatus(): string {
    return ALREADY_EXIST;
  }

  get currentStatus(): string | void {
    return this.authService && this.authService.loginStatus;
  }

  constructor(
    private authService: AuthService,
    ) {
  }

  ngOnInit(): void {
  }

  public goToRegister(): void {
    this.authService.changeLoginModalVisibility(false);
    this.authService.changeRegistrationModalVisibility(true);
    this.authService.changeLoginStatus(DEFAULT_LOGIN);
  }

  public hideLoginModal(): void {
    this.authService.changeLoginModalVisibility(false);
    this.authService.changeLoginStatus(DEFAULT_LOGIN);
  }

  public stageChange(stage: string): void {
    this.stage = stage;
  }

  public isStage(stageName: string): boolean {
    return this.stage === stageName;
  }
}
