import { Injectable } from '@angular/core';
import {
  IRequestOptions,
  ILazyLoadOptions,
  IQuote,
  ISummaryQuotesInfo,
} from '@interfaces';
import { environment } from '@environments/environment';
import { RequestService } from './request.service';
import { SORT_NAMES } from '@constants';
import moment from 'moment';
import { IBuyer } from '@interfaces';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root',
})
export class QuotesService {

  private quotes: IQuote[] = [];
  private status: string | undefined = '';
  private vertical: string | undefined;
  private loading: boolean = false;

  public optionsForLazyLoad: ILazyLoadOptions = {
    limit: 10,
    offset: 0,
    totalCount: 0,
    uploaded: 0,
  };
  public extraQuoteOptions: any = {
    loaded: false,
    id: undefined,
  };
  public summaryQuotesInfo: ISummaryQuotesInfo | undefined;

  get quotesForView(): IQuote[] {
    return this.quotes;
  }

  get processStatus(): boolean {
    return this.loading;
  }

  constructor(
    private requestService: RequestService,
    ) { }

  public init(status?: string, extraQuoteId?: string | undefined): Promise<any> {
    this.status = status;
    this.vertical = undefined;

    this.optionsForLazyLoad.limit = 10;
    this.optionsForLazyLoad.offset = 0;
    this.optionsForLazyLoad.totalCount = 0;
    this.optionsForLazyLoad.uploaded = 0;
    this.quotes = [];

    this.extraQuoteOptions.loaded = false;
    this.extraQuoteOptions.id = extraQuoteId;

    this.getSummary();

    return this.getQuotes();
  }

  private getQuotes(): Promise<any> {
    if (this.loading) {
      return new Promise((): any => {});
    }
    this.loading = true;

    const options: IRequestOptions = {
      limit: this.optionsForLazyLoad.limit,
      include_details: true,
      offset: this.optionsForLazyLoad.offset,
      sortBy: 'partnerQuotes.partners.startedAt', // old sort param worked incorrect
    };

    if (this.status) {
      options.status = this.status;
    }

    if (this.vertical) {
      options.vertical = this.vertical;
    }

    return this.getQuotesRequest(options)
      .then((response: any): any => {
        const cloneDetecter = this.cloneDetecter(response.body.results);
        const findQuote = cloneDetecter.next().value; // find extra quote in download quote
        const quotesWithoutClone = cloneDetecter.next().value; // filter donwload quote if extra quote already uploaded
        const settedQuote = findQuote && !this.extraQuoteOptions.loaded ? response.body.results : quotesWithoutClone;

        // update lazyload settings
        this.optionsForLazyLoad.totalCount = response.body.totalCount;
        this.optionsForLazyLoad.uploaded += response.body.results.length;
        this.optionsForLazyLoad.offset += response.body.limit;

        // Grouped with saved quote, with\without extra quote
        this.quotes = [...this.quotes, ...settedQuote];

        if (this.extraQuoteOptions.id) {
          if (!this.extraQuoteOptions.loaded &&
            !findQuote) { // Check extra quote upload and exist in last request

            return this.getQuote(this.extraQuoteOptions.id).then((quote: IQuote) => {
              this.extraQuoteOptions.loaded = true;
              this.optionsForLazyLoad.uploaded += 1;
              this.quotes = [quote, ...this.quotes];
            });
          } else {
            this.extraQuoteOptions.loaded = true;
          }
        }
      })
      .finally((): void => {
        this.loading = false;
      });
  }

  public * cloneDetecter(quotes: IQuote[]): any {
    yield quotes.find((quote: IQuote) => quote.id === this.extraQuoteOptions.id);
    yield quotes.filter((quote: IQuote) => quote.id !== this.extraQuoteOptions.id);
  }

  public getQuote(id: string): Promise<any> {
    return this.getQuoteRequest(id)
      .then(({body}: {body: IQuote}) => body)
      .finally((): void => {
        this.loading = false;
      });
  }

  public getSummary(): Promise<any> {
    return this.getSummaryRequest()
      .then((response: ISummaryQuotesInfo): void => {
        this.summaryQuotesInfo = response;
      })
      .finally((): void => {
        this.loading = false;
      });
  }

  public uploadMore(): void {
    this.getQuotes();
  }

  public sortQuotes(filterKey: string): void {
    if (SORT_NAMES.newest === filterKey) {
      this.quotes = this.quotes.sort((first: any, second: any) => moment(second.details.createdAt).unix() - moment(first.details.createdAt).unix());
    } else if (SORT_NAMES.oldest === filterKey) {
      this.quotes = this.quotes.sort((first: any, second: any) => moment(first.details.createdAt).unix() - moment(second.details.createdAt).unix());
    }
  }

  public uploadByType(vertical: string): void {
    this.optionsForLazyLoad.limit = this.optionsForLazyLoad.uploaded !== 0 &&
    this.optionsForLazyLoad.uploaded >= 10 ? this.optionsForLazyLoad.uploaded : 10; // set limit , if before uploaded less then 10

    this.optionsForLazyLoad.offset = 0;
    this.optionsForLazyLoad.totalCount = 0;
    this.optionsForLazyLoad.uploaded = 0;
    this.quotes = [];
    this.vertical = vertical;
    this.extraQuoteOptions.loaded = false;
    this.extraQuoteOptions.id = undefined;

    this.getQuotes();
  }

  public getQuotesRequest(options?: any): Promise<any> {
    return this.requestService.promise('get', '/api/quotes', options)
      .catch(this.requestService.handleError.bind(this));
  }

  public getQuoteRequest(id: string, options?: any): Promise<any> {
    return this.requestService.promise('get', `/api/quotes/${id}`, options);
  }

  public getSummaryRequest(): Promise<any> {
    return this.requestService.promise('get', '/api/quotes/summary').then((response: any) => response.body)
      .catch(this.requestService.handleError.bind(this));
  }

  public rejectQuoteRequest(id: string, options?: any): Promise<any> {
    return this.requestService.promise('post', `/api/quotes/${id}/reject`, options)
      .catch(this.requestService.handleError.bind(this));
  }

  public boundQuoteRequest(id: string, options: any): Promise<any> {
    return this.requestService.promise('post', `/api/quotes/${id}/bound`, options)
      .catch(this.requestService.handleError.bind(this));
  }

  public deleteQuoteRequest(id: string, sid: string): Promise<any> {
    return this.requestService.promise('post', `/api/quotes/${id}/delete/${sid}`)
      .catch(this.requestService.handleError.bind(this));
  }

  // TODO make api for that

  public uploadQuote(
    id: string,
    data: FormData,
    buyer: IBuyer,
    isUpdate: boolean = false,
    sid?: string): Promise<any> {

    const headers = {
      'Accept': 'application/json',
      authorization: 'Bearer ' + buyer.jwt,
    };

    let apiUrl = environment.squeeze_quotes_api_url;

    if (typeof location !== 'undefined') {
      if (location.href.indexOf('localhost') > -1 || location.href.indexOf('stagin') > -1) {
        apiUrl = 'https://squeeze-quotes-staging.herokuapp.com/api/v1';
      }
    }

    const url = sid ? `${apiUrl}/quotes/${id}/quote/${sid}` : `${apiUrl}/quotes/${id}/quote`;
    const method = isUpdate ? 'patch' : 'post';
    return this.requestService.promise(method, url, data, headers)
      .catch(this.requestService.handleError.bind(this));
  }

  public downloadFile(id: string, fileUrl: string, filetype: string = 'file'): Observable<any> {
    return this.requestService.getArrayBuffer(`/api/quotes/${id}/${filetype}`, {fileUrl: fileUrl});
  }

  public getConversations(options: any = {}): Observable<any> {
    /*
      options can be:
        conversationId optional  String
          Conversation id.

        quoteId optional  String
          quote request id.

        subQuoteId optional  String
          partner's quote id.

        minDate optional  String
          Date from (YYYY-MM-DD).

        maxDate optional  String
          Date to (YYYY-MM-DD).

        limit optional  Integer
          maximum number to return.
          Default value: 50

        next optional  String
          next page

        previous optional  String
          previous page
    */
    return this.requestService
      .send('get', '/api/conversations', options);
  }

  public createConversation(quoteId: string, options: any = {}): Promise<any> {
    /*
      For a consumer, only 'quote' type conversations can be created and must include
      a subQuoteId provided by partner.
    */
    return this.requestService
      .promise('post', '/api/conversations', {quoteId: quoteId, ...options});
  }

  public addComment(conversationId: string, options: any = {}): Promise<any> {
    return this.requestService
      .promise('post', `/api/conversations/${conversationId}/comments`, options);
  }
}
