import { EventEmitter, } from '@angular/core';
import { RequestService } from '@common/services/request.service';
import { environment } from '@environments/environment';
import { CARRIERS_TYPES } from '@constants';
import * as i0 from "@angular/core";
import * as i1 from "./request.service";
export class CarriersService {
    constructor(requestService) {
        this.requestService = requestService;
        // TODO: make model for this service and campaign.service
        this.store = {};
        this.type = CARRIERS_TYPES.auto;
        this.ready = new EventEmitter();
    }
    get carriers() {
        return this.store[this.type] ? this.store[this.type] : null;
    }
    get blockingValues() {
        // These carrier will be locked for selection, but showed for users which selected it before
        return ['GEICO'];
    }
    init(carrierType = CARRIERS_TYPES.auto) {
        this.type = carrierType;
        if (!this.store[carrierType]) {
            this.fetchCarriers(carrierType);
        }
        else {
            this.ready.emit(this.store[this.type]);
        }
    }
    fetchCarriers(type = CARRIERS_TYPES.auto) {
        this.requestService
            .promise('GET', `${environment.squeeze_internal_api_url}/carriers?tags=${type}`)
            .then((response) => {
            this.store[type] = response;
            this.ready.emit(response);
        })
            .catch(this.handleError.bind(this));
    }
    handleError(errorResponse) {
        console.log('CarrierService|error', errorResponse);
    }
}
CarriersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CarriersService_Factory() { return new CarriersService(i0.ɵɵinject(i1.RequestService)); }, token: CarriersService, providedIn: "root" });
