import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: [ './modal.component.scss' ],
})
export class ModalComponent {

  @Input() show: boolean = false;
  @Input() showCloseBtn: boolean = true;
  @Input() noTitle: boolean = false;
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() customClass: string = '';

  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor() {

  }

  hideModal(): void {
    this.close.emit(null);
  }

}
