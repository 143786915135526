/** Home strategy component contains blocks of strategy
 *
 */

import {
  Component,
} from '@angular/core';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-home-strategy',
  templateUrl: './home-strategy.component.html',
  styleUrls: [ '../../home.component.scss', './home-strategy.component.scss' ],
})

export class HomeStrategyComponent {

  public acknowledgedBrowserSupport!: boolean;

  constructor(
    private authService: AuthService,
    ) {
  }

  public showRegistrationModal(): void {
    if (this.authService.browserName !== 'IE' || this.authService.acknowledgedBrowserSupport) {
      this.authService.changeRegistrationModalVisibility(true);
    } else {
      this.authService.checkBrowser();
      this.authService.browserModalClosed.subscribe((value: any): void => {
        if (value) {
          this.authService.changeRegistrationModalVisibility(true);
        }
      });
    }
  }
}
