<div class="as-seen-container">
  <div class="container">
    <div class="col-xs-12">
      <h4
        *ngIf="showTitle"
        class="as-seen-text"
        [class.as-seen-text-min]="minVersion"
       >As seen in
      </h4>
      <div class="as-seen-icons">
        <div
          *ngFor="let brand of asSeenBrands"
          [class]="brand"
          [class.as-seen-icons-item]="true"
          [class.as-seen-icons-item-min]="minVersion"
        ></div>
      </div>
    </div>
  </div>
</div>
