import {
  IQuote,
  IQuoteDetails,
  IPartnerInfo,
} from '@interfaces';

//TODO Implement methods and use it across app

export class QuoteModel implements IQuote {
  public id: string;
  public _id?: string;
  public vertical: string;
  public status: string; // "queued" | "selecting" | "quoting" | "quoted" | "rejected" | "accepted"
  public partner: IPartnerInfo;
  public details: IQuoteDetails;
  public acceptedSubQuoteId?: string;
  public viewedAt?: any;
  public expiresAt?: string;
  public __v: number;

  constructor(data: any = {}) {
    this._id = data._id || 0;
    this.id = data.id || null;
    this.vertical = data.vertical|| null;
    this.status = data.status || null;
    this.partner = data.partner || null;
    this.details = data.details || null;
    this.acceptedSubQuoteId = data.acceptedSubQuoteId || null;
    this.viewedAt = data.viewedAt || null;
    this.expiresAt = data.expiresAt || null;
    this.__v = data.__v || 0;
  }

}

