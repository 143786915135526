import {
  Component,
  Input,
} from '@angular/core';
import { InputClass } from '@common/models/input.model';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: [
    '../form-components.scss',
    '../floating-label.scss',
    '../contrast.scss',
  ],
})

export class TextInputComponent extends InputClass {

  @Input()
  public decimalNumber: boolean = false;

  @Input()
  public min: number = 0;

  @Input()
  public max: number = 999999999999;

  @Input()
  public maxlength: number = 999999999999;

  @Input()
  public absoluteError: boolean = false;

  @Input()
  public minimalError: boolean = false;

  constructor(
  ) {
    super();
  }
}
