import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(
      private request: RequestService,
  ) { }

  public log(message: any, url: any, stackString: any): void {
    const isLocalHost = typeof location !== 'undefined' && location.hostname.indexOf('localhost') > -1;
    if (environment.production && !isLocalHost) {
      this.request.send('post', `${environment.squeeze_internal_api_url}/logger/log`, {
        message: message,
        url: url,
        stack: stackString
      }).subscribe();
    }
  }
}
