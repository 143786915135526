export const INSURANCE_TYPES = {
  auto_insurance: 'auto_insurance',
  home_insurance: 'home_insurance',
  renters_insurance: 'renters_insurance',
  condo_insurance: 'condo_insurance',
  business_insurance: 'business_insurance',
  health_insurance: 'health_insurance',
  motorcycle_insurance: 'motorcycle_insurance',
  boat_insurance: 'boat_insurance',
};
export type TFrequency = 'monthly' | '6_months' | 'yearly';
export type TVehicleInsuredFor = 'less_than_6_months' | '6_to_12_months' | '1_to_3_years' | '3_to_5_years' | '+5_years';
export type TVehicleCoverageLevel = 'basic' | 'medium' | 'highest';
export type TVehicleComprehensive = -1 | 100 | 250 | 500 | 1000;
export type TVehicleCollision = -1 | 100 | 250 | 500 | 1000;
export type TVehicleBIPerPerson = 10 | 25 | 50 | 100 | 250;
export type TVehicleBIPerAccident = 20 | 50 | 100 | 300 | 500;
export type TVehiclePD = 10 | 25 | 50 | 100;
export type TVehicleUMPerPerson = 0 | 10 | 25 | 50 | 100 | 250;
export type TVehicleUMPerAccident = 0 | 20 | 50 | 100 | 300 | 500;
export type TVehicleMedicalPayments = 0 | 1000 | 2500 | 5000 | 10000 | 25000;
export type TVehicleTotaledOption = 'no_coverage' | 'agreed_value' | 'actual_cash_value' | 'replacement_price';
