import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthGuardService as AuthGuard } from './common/services/auth-guard.service';
const ɵ0 = () => import("./static/static.module.ngfactory").then(m => m.StaticModuleNgFactory), ɵ1 = () => import("./portal/portal.module.ngfactory").then(m => m.PortalModuleNgFactory), ɵ2 = () => import("./questionnaire/questionnaire.module.ngfactory").then(m => m.QuestionnaireModuleNgFactory);
const routes = [
    { path: '', component: HomeComponent, pathMatch: 'full' },
    /* tslint:disable-next-line */
    { path: 'static', loadChildren: ɵ0 },
    /* tslint:disable-next-line */
    { path: 'portal', loadChildren: ɵ1, canActivate: [AuthGuard] },
    /* tslint:disable-next-line */
    { path: 'questionnaire', loadChildren: ɵ2, canActivate: [AuthGuard] },
    /* tslint:disable-next-line */
    // PJW: Remove the closers for now (10.24.19)
    // { path: 'closers', loadChildren: (): Promise<any> => import('./closers/closers.module').then(m => m.ClosersModule)},
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'set-password', component: ResetPasswordComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: '404', component: NotFoundComponent },
    { path: '**', redirectTo: '/404', pathMatch: 'full' },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
