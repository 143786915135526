<!-- top banner block -->
<div class='home-banner'>
  <div class='overlay'></div>
  <div class='home-block'>
    <h1 class='home-heading'>
      Stop Chasing Leads & Start Closing Customers
    </h1>
    <p class='home-heading-subtitle'>
      Participate in Our Auto Insurance Quote Marketplace to Save Time & Money for You and Your Customers
    </p>
  </div>
</div>

<!-- join block -->
<div class='home-join'>
  <p class='home-join-title'>
    Start quoting qualified customers today!
  </p>
  <button class='btn round btn-signup pink'
    (click)='showRegistrationModal()'
  >
    Join our network
  </button>
</div>

<!-- strategy block -->
<app-home-strategy id='whatWeDo'></app-home-strategy>

<app-home-workflow></app-home-workflow>

<!-- choose block -->
<app-home-choose id='chooseUs'></app-home-choose>

<!-- as seen block -->
<!-- <div class='as-seen-icons'>
  <div *ngFor='let brand of brands' [class]='brand'></div>
</div> -->

<app-home-quote></app-home-quote>

<app-as-seen-in
  [showTitle]='false'
></app-as-seen-in>
