import { IState } from '@common/interfaces';

export const REGISTRATION_STAGE_INFO: string = 'info';
export const REGISTRATION_STAGE_PASSWORD: string = 'password';
export const REGISTRATION_STAGE_COMPLETE: string = 'complete';

export const BUDGETS: Array<any> = [
  {
    name: 'dailyLimit',
    value: 'dailyLimit',
    label: 'Daily',
    selected: false,
  }, {
    name: 'weeklyLimit',
    value: 'weeklyLimit',
    label: 'Weekly',
    selected: false,
  }, {
    name: 'monthlyLimit',
    value: 'monthlyLimit',
    label: 'Monthly',
    selected: false,
  },
];

export const RATES: Array<any> = [
  {
    name: 'poor',
    label: 'Poor',
    rateValue: '500/579',
    value: 'poor',
    selected: false,
    class: 'orange',
  }, {
    name: 'average',
    label: 'Average',
    rateValue: '580/619',
    value: 'average',
    selected: false,
    class: 'yellow',
  }, {
    name: 'good',
    label: 'Good',
    rateValue: '620/699',
    value: 'good',
    selected: false,
    class: 'greed',
  }, {
    name: 'excellent',
    label: 'Excellent',
    rateValue: '700/850',
    value: 'excellent',
    selected: false,
    class: 'deepgreen',
  },
];

export const STATES: Array<IState> = [
  {
    name: 'Alabama',
    shortName: 'AL',
    selected: false,
  }, {
    name: 'Alaska',
    shortName: 'AK',
    selected: false,
    //  }, {
    //    name: 'American Samoa',
    //    shortName: 'AS',
    //    selected: true,
  }, {
    name: 'Arizona',
    shortName: 'AZ',
    selected: false,
  }, {
    name: 'Arkansas',
    shortName: 'AR',
    selected: false,
  }, {
    name: 'California',
    shortName: 'CA',
    selected: false,
  }, {
    name: 'Colorado',
    shortName: 'CO',
    selected: false,
  }, {
    name: 'Connecticut',
    shortName: 'CT',
    selected: false,
  }, {
    name: 'Delaware',
    shortName: 'DE',
    selected: false,
  }, {
    name: 'District of Columbia',
    shortName: 'DC',
    selected: false,
    //  }, {
    //    name: 'Federated States of Micronesia',
    //    shortName: 'FM',
    //    selected: false,
  }, {
    name: 'Florida',
    shortName: 'FL',
    selected: false,
  }, {
    name: 'Georgia',
    shortName: 'GA',
    selected: false,
    //  }, {
    //    name: 'Guam',
    //    shortName: 'GU',
    //    selected: false,
  }, {
    name: 'Hawaii',
    shortName: 'HI',
    selected: false,
  }, {
    name: 'Idaho',
    shortName: 'ID',
    selected: false,
  }, {
    name: 'Illinois',
    shortName: 'IL',
    selected: false,
  }, {
    name: 'Indiana',
    shortName: 'IN',
    selected: false,
  }, {
    name: 'Iowa',
    shortName: 'IA',
    selected: false,
  }, {
    name: 'Kansas',
    shortName: 'KS',
    selected: false,
  }, {
    name: 'Kentucky',
    shortName: 'KY',
    selected: false,
  }, {
    name: 'Louisiana',
    shortName: 'LA',
    selected: false,
  }, {
    name: 'Maine',
    shortName: 'ME',
    selected: false,
    //  }, {
    //    name: 'Marshall Islands',
    //    shortName: 'MH',
    //    selected: false,
  }, {
    name: 'Maryland',
    shortName: 'MD',
    selected: false,
  }, {
    name: 'Massachusetts',
    shortName: 'MA',
    selected: false,
  }, {
    name: 'Michigan',
    shortName: 'MI',
    selected: false,
  }, {
    name: 'Minnesota',
    shortName: 'MN',
    selected: false,
  }, {
    name: 'Mississippi',
    shortName: 'MS',
    selected: false,
  }, {
    name: 'Missouri',
    shortName: 'MO',
    selected: false,
  }, {
    name: 'Montana',
    shortName: 'MT',
    selected: false,
  }, {
    name: 'Nebraska',
    shortName: 'NE',
    selected: false,
  }, {
    name: 'Nevada',
    shortName: 'NV',
    selected: false,
  }, {
    name: 'New Hampshire',
    shortName: 'NH',
    selected: false,
  }, {
    name: 'New Jersey',
    shortName: 'NJ',
    selected: false,
  }, {
    name: 'New Mexico',
    shortName: 'NM',
    selected: false,
  }, {
    name: 'New York',
    shortName: 'NY',
    selected: false,
  }, {
    name: 'North Carolina',
    shortName: 'NC',
    selected: false,
  }, {
    name: 'North Dakota',
    shortName: 'ND',
    selected: false,
    //  }, {
    //    name: 'Northern Mariana Islands',
    //    shortName: 'MP',
    //    selected: false,
  }, {
    name: 'Ohio',
    shortName: 'OH',
    selected: false,
  }, {
    name: 'Oklahoma',
    shortName: 'OK',
    selected: false,
  }, {
    name: 'Oregon',
    shortName: 'OR',
    selected: false,
    //  }, {
    //    name: 'Palau',
    //    shortName: 'PW',
    //    selected: false,
  }, {
    name: 'Pennsylvania',
    shortName: 'PA',
    selected: false,
  }, {
    name: 'Puerto Rico',
    shortName: 'PR',
    selected: false,
  }, {
    name: 'Rhode Island',
    shortName: 'RI',
    selected: false,
  }, {
    name: 'South Carolina',
    shortName: 'SC',
    selected: false,
  }, {
    name: 'South Dakota',
    shortName: 'SD',
    selected: false,
  }, {
    name: 'Tennessee',
    shortName: 'TN',
    selected: false,
  }, {
    name: 'Texas',
    shortName: 'TX',
    selected: false,
  }, {
    name: 'Utah',
    shortName: 'UT',
    selected: false,
  }, {
    name: 'Vermont',
    shortName: 'VT',
    selected: false,
    //  }, {
    //    name: 'Virgin Islands',
    //    shortName: 'VI',
    //    selected: false,
  }, {
    name: 'Virginia',
    shortName: 'VA',
    selected: false,
  }, {
    name: 'Washington',
    shortName: 'WA',
    selected: false,
  }, {
    name: 'West Virginia',
    shortName: 'WV',
    selected: false,
  }, {
    name: 'Wisconsin',
    shortName: 'WI',
    selected: false,
  }, {
    name: 'Wyoming',
    shortName: 'WY',
    selected: false,
  },
];
