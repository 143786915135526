import { Component } from '@angular/core';

@Component({
  selector: 'app-home-workflow',
  templateUrl: './home-workflow.component.html',
  styleUrls: [
    '../../home.component.scss',
    './home-workflow.component.scss',
  ],
})

export class HomeWorkflowComponent {

  constructor(
    ) {
  }

}
