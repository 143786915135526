/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *  Home choose component contains blocks of choose
 *

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = [".home-choose[_ngcontent-%COMP%]{padding-top:9em;padding-bottom:9em;text-align:center;position:relative}.home-choose-holder[_ngcontent-%COMP%]{justify-content:space-between;max-width:950px;margin:0 auto 20px}.home-choose[_ngcontent-%COMP%]   .home-choose-block[_ngcontent-%COMP%]{height:170px;overflow:hidden;max-width:270px;margin-bottom:30px}@media only screen and (max-width:768px){.home-choose-holder[_ngcontent-%COMP%]{justify-content:center}.home-choose[_ngcontent-%COMP%]{padding-top:3em;padding-bottom:3em}.home-choose[_ngcontent-%COMP%]   .home-choose-block[_ngcontent-%COMP%]{height:auto}}.home-choose[_ngcontent-%COMP%]   .home-choose-block[_ngcontent-%COMP%]   h3[_ngcontent-%COMP%]{font-size:20px}.home-choose[_ngcontent-%COMP%]   .home-choose-block[_ngcontent-%COMP%]   .description[_ngcontent-%COMP%]{font-size:16px;line-height:26px;opacity:.65}.home-choose[_ngcontent-%COMP%]   .btn-signup[_ngcontent-%COMP%]{padding:15px 50px;color:#050507}"];
export { styles as styles };
