<div
  class='window-overlay'
  *ngIf='isGlobal'></div>
<div
  class='loader-ring'
  [class.small]='isSmall'
  [class.pink]='isGlobal || pink'
  [class.static]='static'
  >
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
