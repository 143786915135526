/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../buttons.scss.shim.ngstyle";
import * as i1 from "./button-loader.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../form-loader/form-loader.component.ngfactory";
import * as i4 from "../form-loader/form-loader.component";
import * as i5 from "@angular/common";
import * as i6 from "./button-loader.component";
var styles_ButtonLoaderComponent = [i0.styles, i1.styles];
var RenderType_ButtonLoaderComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_ButtonLoaderComponent, data: {} });
export { RenderType_ButtonLoaderComponent as RenderType_ButtonLoaderComponent };
function View_ButtonLoaderComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-form-loader", [], null, null, null, i3.View_FormLoaderComponent_0, i3.RenderType_FormLoaderComponent)), i2.ɵdid(1, 49152, null, 0, i4.FormLoaderComponent, [], { isSmall: [0, "isSmall"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ButtonLoaderComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "div", [["class", "button-loader"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "button", [], [[8, "disabled", 0], [8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(2, null, [" ", " "])), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ButtonLoaderComponent_1)), i2.ɵdid(4, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.loading; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = i2.ɵinlineInterpolate(1, "btn ", _co.customClasses, ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.actualLabel; _ck(_v, 2, 0, currVal_2); }); }
export function View_ButtonLoaderComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-button-loader", [], null, null, null, View_ButtonLoaderComponent_0, RenderType_ButtonLoaderComponent)), i2.ɵdid(1, 49152, null, 0, i6.ButtonLoaderComponent, [], null, null)], null, null); }
var ButtonLoaderComponentNgFactory = i2.ɵccf("app-button-loader", i6.ButtonLoaderComponent, View_ButtonLoaderComponent_Host_0, { disabled: "disabled", label: "label", loading: "loading", customClasses: "customClasses" }, { buttonClick: "buttonClick" }, []);
export { ButtonLoaderComponentNgFactory as ButtonLoaderComponentNgFactory };
