import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-field-error',
  templateUrl: './field-error.component.html',
  styleUrls: ['./field-error.component.scss'],
})

export class FieldErrorComponent implements OnInit {

  @Input() errors: any;
  @Input() label: string = 'Field';
  @Input() isInvalid: boolean = false;
  @Input() noLabel: boolean = false;
  @Input() minimalError: boolean = false;
  @Input() textCenter: boolean = false;

  private defaultsErrorMessages: any;

  constructor() { }

  ngOnInit() {
    this.defaultsErrorMessages = {
      required: `${this.label} is required`,
      format: `${this.label} has incorrect format`,
      country: `Must be a valid US address`,
      pattern: `${this.label} has incorrect format`,
      invalid: `${this.label} is invalid`,
    };
  }

  public getErrorMessage() {
    if (!this.errors) return;
    if (this.errors && this.errors.minlength) {
      return `${this.label} should be minimum of ${this.errors.minlength.requiredLength} characters`;
    } else if (this.errors && this.errors.maxlength) {
      return `${this.label} should be maximum of ${this.errors.maxlength.requiredLength} characters`;
    } else if (this.errors && this.errors.min) {
      return `${this.label} should be minimum ${this.errors.min.min}`;
    } else if (this.errors && this.errors.max) {
      return `${this.label} should be maximum ${this.errors.max.max}`;
    } else if (this.errors && this.errors.message) {
      return this.noLabel ? this.errors.message : `${this.label} ${this.errors.message}`;
    }

    const key = Object.keys(this.errors)[0];
    return this.defaultsErrorMessages[key];
  }
}
