/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./as-seen-in.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./as-seen-in.component";
var styles_AsSeenInComponent = [i0.styles];
var RenderType_AsSeenInComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AsSeenInComponent, data: {} });
export { RenderType_AsSeenInComponent as RenderType_AsSeenInComponent };
function View_AsSeenInComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [["class", "as-seen-text"]], [[2, "as-seen-text-min", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["As seen in "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.minVersion; _ck(_v, 0, 0, currVal_0); }); }
function View_AsSeenInComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "className", 0], [2, "as-seen-icons-item", null], [2, "as-seen-icons-item-min", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = true; var currVal_2 = _co.minVersion; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_AsSeenInComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "as-seen-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AsSeenInComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "as-seen-icons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AsSeenInComponent_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTitle; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.asSeenBrands; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_AsSeenInComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-as-seen-in", [], null, null, null, View_AsSeenInComponent_0, RenderType_AsSeenInComponent)), i1.ɵdid(1, 49152, null, 0, i3.AsSeenInComponent, [], null, null)], null, null); }
var AsSeenInComponentNgFactory = i1.ɵccf("app-as-seen-in", i3.AsSeenInComponent, View_AsSeenInComponent_Host_0, { minVersion: "minVersion", showTitle: "showTitle" }, {}, []);
export { AsSeenInComponentNgFactory as AsSeenInComponentNgFactory };
