import {
  IBuyer,
  IBuyerAccount,
} from '@common/interfaces/index';

export class BuyerModel {

  private _buyer: IBuyer = { jwt: '' };
  private _buyerAccount: IBuyerAccount = { id: '', firstName: '', lastName: '', emailAddress: '' };

  constructor(buyer: IBuyer | null) {
    this._buyer = buyer || this._buyer;
    this._buyerAccount = { ...this._buyerAccount, ...this._buyer.account };
  }

  get account(): IBuyerAccount {
    return this._buyerAccount;
  }

  get firstName(): string {
    return this.account.firstName;
  }

  get lastName(): string {
    return this.account.lastName;
  }

  get fullName(): string {
    return this.firstName.concat(' ').concat(this.lastName);
  }

  get emailAddress(): string {
    return this.account.emailAddress;
  }

}
