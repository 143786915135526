<div
  [class.floating-label]='floatingLabel && !fullBorder'
  [class.floating-label-new]='floatingLabel && fullBorder'
  [class.disabled]='isDisabled()'
  >
  <input
    #input
    type='tel'
    name='{{name}}'
    inputmode='numeric'
    appTelInput
    class='floating-input'
    [value]='value'
    [placeholder]='actualPlaceholder'
    (change)='onChange($event)'
    (keyup)='onChange($event)'
    [disabled]='isDisabled()'
    [class.error]='isInvalid()'
    [class.success]='isValid()'
    />
  <label>{{label}}</label>
</div>
<app-field-error
  [isInvalid]='isInvalid()'
  [errors]='getErrors()'
  [label]='label'
></app-field-error>
