import moment from 'moment';
import { ICampaign } from '@interfaces';

export class CampaignModel {

  private _settingsRoute: string = '/portal/profile/settings/campaigns';
  private _idSeparator: string = '-';
  private _campaign: ICampaign | null = null;
  private _default: ICampaign = { _id: '', name: 'auto', type: 'insurance', status: 'active' };

  constructor(campaign: any) {
    this._campaign = { ...this.campaign, ...campaign };
  }

  get campaign(): ICampaign {
    return this._campaign || this._default;
  }

  get id(): string {
    return this.campaign._id;
  }

  get key(): string {
    return this.name.concat(this._idSeparator).concat(this.type);
  }

  get label(): string {
    return this.name.concat(' ').concat(this.type);
  }

  get color(): string {
    return this.campaign.color || 'purple';
  }

  get name(): string {
    return this.campaign.name;
  }

  get type(): string {
    return this.campaign.type;
  }

  get status(): string {
    return this.campaign.status; // active", "inactive", "paused"
  }

  set status(value: string) {
    if (this._campaign) {
      this._campaign.status = value || 'active';
    }
  }

  get active(): boolean {
    return this.status === 'active';
  }

  get inactive(): boolean {
    return this.status === 'inactive';
  }

  get paused(): boolean {
    return this.status === 'paused';
  }

  get manageRouterLink(): string {
    return `${this._settingsRoute}/${this.type}/${this.name}`;
  }

  get accountId(): string | undefined {
    return this.campaign.accountId;
  }

  get primaryCarrier(): string | undefined {
    return this.campaign.primaryCarrier;
  }

  get secondaryCarriers(): Array<string> {
    return this.campaign.secondaryCarriers || [];
  }

  get states(): Array<string> {
    return this.campaign.states || [];
  }

  get lastQuoteDate(): string {
    return this.campaign.lastQuoteDate || 'unknown';
  }

  get lastQuoteDatePretty(): string {
    if (this.lastQuoteDate === 'unknown') return this.lastQuoteDate;

    const slicedTime = this.lastQuoteDate.slice(0, 10);
    const start = moment(slicedTime);
    const end = moment();
    return start.from(end);
  }

  get licenseNumbers(): Array<{_id: string, number: string, state: string}> {
    return this.campaign.licenseNumbers || [];
  }

  get maxQuotesPerDay(): number | undefined {
    return this.campaign.maxQuotesPerDay;
  }

  get noQuotesLimit(): boolean {
    return this.campaign.noQuotesLimit || false;
  }

  get quoteOnWeekends(): boolean {
    return this.campaign.quoteOnWeekends || false;
  }

  get useGlobalDelivery(): boolean {
    return this.campaign.useGlobalDelivery || false;
  }

  get createdAt(): string {
    return this.campaign.createdAt || 'unknown';
  }

  get nonexistent(): boolean {
    return !!this.campaign.nonexistent;
  }

  get createdAtPretty(): string {
    if (this.createdAt === 'unknown') return this.createdAt;

    const slicedTime = this.createdAt.slice(0, 10);
    const start = moment(slicedTime);
    const end = moment();
    return start.from(end);
  }

  get updatedAt(): string {
    return this.campaign.updatedAt || 'unknown';
  }

  get updatedAtPretty(): string {
    if (this.updatedAt === 'unknown') return this.updatedAt;

    const slicedTime = this.updatedAt.slice(0, 10);
    const start = moment(slicedTime);
    const end = moment();
    return start.from(end);
  }

  public pause(pause: boolean): void {
    this.status = pause ? 'paused' : 'active';
  }

  public setStatus(status: string): void {
    this.status = status;
  }
}
