var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorage, SessionStorage, } from 'ngx-webstorage';
export class RequestService {
    constructor(httpClient, cookie, platformId) {
        this.httpClient = httpClient;
        this.cookie = cookie;
        this.platformId = platformId;
        this.utmFields = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'tph_id'];
        this.sessionId = '';
    }
    handleError(errorResponse) {
        let errorMessage = 'Oh dear, something went wrong';
        try {
            console.log('RequestService|errorResponse', {
                status: errorResponse.status,
                message: errorResponse.message,
                error: errorResponse.error.body,
                errorResponse: errorResponse,
            });
            errorMessage = errorResponse.error.body.error;
        }
        catch (e) {
            console.log('RequestService|errorResponse', errorResponse);
        }
        if (typeof alert !== 'undefined') {
            alert(errorMessage);
        }
    }
    send(method, url, params = {}, headerOptions) {
        method = method.toLowerCase();
        let request;
        const headers = new HttpHeaders(this.buildHeaders(headerOptions));
        switch (method) {
            case 'get':
                request = this.httpClient.get(url, { params, headers });
                break;
            case 'post':
                request = this.httpClient.post(url, params, { headers });
                break;
            case 'patch':
                request = this.httpClient.patch(url, params, { headers });
                break;
            default:
                request = this.httpClient.request(method, url, Object.assign({ headers }, params));
                break;
        }
        return request;
    }
    /*
     * https://angular.io/guide/http#reading-the-full-response
    */
    request(method, url, options, headerOptions) {
        method = method.toLowerCase();
        options = options || {};
        let request;
        const headers = new HttpHeaders(this.buildHeaders(headerOptions));
        request = this.httpClient.request(method, url, Object.assign({}, options, { headers, observe: 'response' }));
        return request;
    }
    /*
     * use for GET of responseType: 'blob'
    */
    getBlob(url, options, headerOptions) {
        const headers = new HttpHeaders(this.buildHeaders(headerOptions));
        return this.httpClient.get(url, { headers: headers, observe: 'response', params: options, responseType: 'blob' });
    }
    /*
     * use for GET of responseType: 'arraybuffer'
    */
    getArrayBuffer(url, options, headerOptions) {
        const headers = new HttpHeaders(this.buildHeaders(headerOptions));
        return this.httpClient.get(url, { headers: headers, observe: 'events', params: options, responseType: 'arraybuffer' });
    }
    /*  Send requests as promise
     *
     */
    promise(method, url, options, headerOptions) {
        return this.send(method, url, options, headerOptions)
            .toPromise()
            .then((response) => {
            try {
                response = response.json();
            }
            catch (_e) { }
            return response;
        });
    }
    buildHeaders(options) {
        let headers = {
            'x-sqz-client': 'squeeze-web',
        };
        if (options) {
            headers = Object.assign({ headers }, options);
        }
        if (isPlatformBrowser(this.platformId)) {
            // include browser_uuid
            const browserUuid = localStorage.getItem('browser_uuid');
            if (browserUuid) {
                headers['x-sqz-browserid'] = browserUuid;
            }
            headers['x-sqz-sessionid'] = this.sessionId;
            const clientId = this.getGAClientId();
            if (clientId) {
                headers['x-sqz-gaclientid'] = clientId;
            }
            this.utmFields.forEach((key) => {
                const cookie = this.cookie.get(key);
                // correct tph id to what squeeze-auth expects
                if (key === 'tph_id') {
                    key = 'tph-id';
                }
                if (cookie) {
                    headers[`x-sqz-${key}`] = cookie;
                }
            });
        }
        if (this.buyer) {
            if (this.buyer.jwt) {
                headers['x-sqz-token'] = this.buyer.jwt;
            }
            if (this.buyer.account) {
                headers['x-sqz-userid'] = this.buyer.account.id;
            }
        }
        // IE hack for no chaching request
        headers['Cache-Control'] = 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0';
        headers.Pragma = 'no-cache';
        headers.Expires = '0';
        return headers;
    }
    getGAClientId() {
        let id = this.cookie.get('_ga');
        if (id) {
            // omit the first two pieces; typically GA1.1.
            id = id.split('.').splice(2).join('.');
        }
        // console.log('client_id ', id);
        return id;
    }
}
__decorate([
    LocalStorage('buyer'),
    __metadata("design:type", Object)
], RequestService.prototype, "buyer", void 0);
__decorate([
    SessionStorage('session-id'),
    __metadata("design:type", String)
], RequestService.prototype, "sessionId", void 0);
