import {
  AbstractControl,
  ValidationErrors,
  FormGroup,
} from '@angular/forms';

import {
  parse,
  format,
  AsYouType,
} from 'libphonenumber-js';

import moment from 'moment';

declare const accounting: any;

function accountingParse(value: any): any {
  if (typeof accounting === 'undefined') {
    return value;
  } else {
    return accounting.parse(value);
  }
}

export function accountingFormatNumber(value: any, decimals: number = 0): any {
  if (typeof accounting === 'undefined') {
    return value;
  } else {
    return accounting.formatNumber(value, decimals);
  }
}

export const CommonValidators = {
  name: (c: AbstractControl): ValidationErrors | null => {
    if (!c.value) {
      return null;
    }

    const chars = /^[a-zA-Z][a-zA-Z0-9\s\-]*$/.test(c.value);
    return chars ? null : {
      format: true,
      message: 'can only contain letters',
    } as ValidationErrors;
  },

  fullName: (c: AbstractControl): ValidationErrors | null => {
    if (!c.value) {
      return null;
    }

    const chars = /^[a-zA-Z][a-zA-Z0-9\s\-]*$/.test(c.value);
    return chars ? null : {
      format: true,
      message: 'can only contain letters',
    } as ValidationErrors;
  },

  phone: (c: AbstractControl): ValidationErrors | null => {
      if (c.value) {
        let primaryPhone;

        try {
          primaryPhone = parsePhone(c.value);

        } catch (e) {
          return {
            format: true,
            message: 'could not be formatted',
          } as ValidationErrors;
        }

        return primaryPhone!.libFormatted.length === 14 ? null : {
          format: true,
          message: 'is invalid',
        } as ValidationErrors;
      }

      return null;
  },

  passwordMatch: (group: FormGroup): ValidationErrors | null => {
    const password = group.controls.password.value;
    const passwordConfirm = group.controls.passwordConfirm.value;

    return password === passwordConfirm ||
      !group.controls.password.valid ||
      !group.controls.passwordConfirm.valid ? null : {
      format: true,
      message: 'do not match',
    } as ValidationErrors;
  },

  moneyValue: (c: AbstractControl, min: number = 1): ValidationErrors | null => {
    if (c.value) {
      const amount = accountingParse(c.value);

      return amount <= min ? {
        min: true,
        message: `Amount must be greater than $${accountingFormatNumber(min)}`,
      } as ValidationErrors : null;
    }

    return null;
  },

  creditDate: (c: AbstractControl): ValidationErrors | null => {
    if (c.value) {
      const nowYear = +moment().format('YY');
      const reg = /[\d]{2}\/[\d]{2}/ig;
      const checkFormat = reg.test(c.value);
      const splitValues = c.value.split('/');
      const monthValue = parseInt(splitValues[0], 10);
      const yearValue = parseInt(splitValues[1], 10);

      return !checkFormat ||
        monthValue <= 0 ||
        monthValue >= 13 ||
        yearValue < nowYear ||
        c.value.length !== 5 ? {
          format: true,
          message: 'has wrong format',
        } as ValidationErrors : null;
    }

    return null;
  },

  confirmPassword: (form: FormGroup): ValidationErrors | null => {
    if (form.controls.password.value === form.controls.confirmPassword.value) {
      return null;
    } else {
      return { coincidencePassword: false, message: 'Passwords must match' } as ValidationErrors;
    }
  },

  alphanumeric: (c: AbstractControl): ValidationErrors | null => {
    const regex = /^[a-zA-Z0-9_]*$/ig;

    if (regex.test(c.value)) {
      return null;
    } else {
      return {
        format: true,
        message: 'has wrong format',
      } as ValidationErrors;
    }
  },
};

export function parsePhone(value: string): any {
  const asYouType = new AsYouType('US');
  const libFormatted = asYouType.input(value);

  return {
    libFormatted: libFormatted, // (xxx) xxx-xxxx
    international: format(value, 'US', 'International'), // +x xxx xxx xxxx
    parsed: parse(value, 'US'), // { country: 'US', phone: 'xxxxxxxxxx' }
    dashed: libFormatted.replace(' )', '').replace('(', '').replace(') ', '-').replace(')', ''), // xxx-xxx-xxxx
  };
}

export function isMongoId(value: string): any {
  const regex = /^[0-9a-fA-F]{24}$/i;
  return regex.test(value);
}
