/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *  Login component contains form to register in the application
 *

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = [".login-title[_ngcontent-%COMP%]{margin-bottom:0}.login-pointer[_ngcontent-%COMP%]{cursor:pointer}a[_ngcontent-%COMP%]{color:#157ffb;text-decoration:underline}"];
export { styles as styles };
