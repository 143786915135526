import { Component } from '@angular/core';
import { GlobalModalsClass } from '@models';
import { GlobalModalService } from '@services';

@Component({
  selector: 'app-global-notify',
  templateUrl: './global-notify.component.html',
  styleUrls: ['../global-modals.component.scss'],
})
export class GlobalNotifyComponent extends GlobalModalsClass {

  constructor(
    public globalModalService: GlobalModalService,
  ) {
    super(globalModalService);
  }
}
