var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/** Header component contains header of the application
 *
 */
import { OnInit, OnDestroy, ChangeDetectorRef, } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { HEADER_LINKS, HEADER_OTHER_STYLE_URLS, } from './header.constants';
import { NotificationModel } from '@common/models';
import { SessionStorage } from 'ngx-webstorage';
import { NotificationsService } from '@common/services/notifications.service';
export class HeaderComponent {
    constructor(authService, router, cdRef, notificationsService) {
        this.authService = authService;
        this.router = router;
        this.cdRef = cdRef;
        this.notificationsService = notificationsService;
        this.showNav = false;
        this.isShowRegistrationModal = false;
        this.isShowLoginModal = false;
        this.showNotifications = false;
        this.notifications = [];
        this.messages = [];
        this.navIsFixed = false;
        this.showUnsupportedBrowserMessage = false;
        this.menu = HEADER_LINKS;
        this.authService.showUnsupportedModalObserv.subscribe((value) => {
            this.showUnsupportedBrowserMessage = value;
            this.cdRef.detectChanges();
            if (!this.showUnsupportedBrowserMessage && !this.authService.acknowledgedBrowserSupport) {
                this.acknowledgedBrowserSupport = true;
                this.authService.acknowledgedBrowserSupport = this.acknowledgedBrowserSupport;
            }
        });
        this.toggleRegisterSubscription = this.authService.toggleRegistrationModal.subscribe((result) => {
            this.isShowRegistrationModal = result;
        }, (error) => {
            console.log('error', error);
        });
        this.toggleLoginSubscription = this.authService.toggleLoginModal.subscribe((result) => {
            this.isShowLoginModal = result;
        }, (error) => {
            console.log('error', error);
        });
        this.loggedInSubscription = this.authService.getLoggedIn().subscribe((loggedIn) => {
            if (loggedIn) {
                this.initNotifications();
            }
        });
    }
    get otherStyleOfHeader() {
        return !!(this.authService.buyer || HEADER_OTHER_STYLE_URLS.find((url) => this.router.url.indexOf(url) !== -1));
    }
    get headerLinks() {
        return this.menu;
    }
    get notificationCount() {
        const unreadFilter = (obj) => !obj.read;
        const unreadNotifications = this.notifications.filter(unreadFilter);
        const unreadMessages = this.messages.filter(unreadFilter);
        return unreadNotifications.length + unreadMessages.length;
    }
    get isLoggedIn() {
        return this.authService.isLoggedIn;
    }
    get userAccount() {
        return this.authService.userAccount;
    }
    ngOnInit() {
        this.authService.browserName = this.browserName;
        this.authService.acknowledgedBrowserSupport = this.acknowledgedBrowserSupport;
        this.getMessages();
    }
    ngOnDestroy() {
        if (this.toggleRegisterSubscription) {
            this.toggleRegisterSubscription.unsubscribe();
        }
        if (this.toggleLoginSubscription) {
            this.toggleLoginSubscription.unsubscribe();
        }
        if (this.notificationsSubscription) {
            this.notificationsSubscription.unsubscribe();
        }
        if (this.loggedInSubscription) {
            this.loggedInSubscription.unsubscribe();
        }
    }
    initNotifications() {
        this.notificationsService.init();
        this.subscribeToPushNotifications();
    }
    toggleNotifications(event) {
        event.stopPropagation();
        this.showNotifications = !this.showNotifications;
    }
    showRegistrationModal() {
        if (this.authService.browserName !== 'IE' || this.acknowledgedBrowserSupport) {
            this.authService.changeRegistrationModalVisibility(true);
        }
        else {
            this.authService.checkBrowser();
            this.authService.browserModalClosed.subscribe((value) => {
                if (value) {
                    this.authService.changeRegistrationModalVisibility(true);
                }
            });
        }
    }
    emitShowUnsupportedBrowser(val) {
        this.authService.emitShowUnsupportedBrowserModal(val);
    }
    showLoginModal() {
        if (this.authService.browserName !== 'IE' || this.acknowledgedBrowserSupport) {
            this.authService.changeLoginModalVisibility(true);
            this.showNav = false;
        }
        else {
            this.authService.checkBrowser();
            this.authService.browserModalClosed.subscribe((value) => {
                if (value) {
                    this.authService.changeLoginModalVisibility(true);
                    this.showNav = false;
                }
            });
        }
    }
    logout() {
        this.authService.logout().then((_response) => {
            this.router.navigate(['/']);
        });
    }
    getLink() {
        return this.isLoggedIn ? '/portal/dashboard' : '/';
    }
    onCloseBrowserSupportModal() {
        this.showUnsupportedBrowserMessage = false;
        this.acknowledgedBrowserSupport = true;
        this.authService.acknowledgedBrowserSupport = this.acknowledgedBrowserSupport;
        this.authService.browserModalClosed.next(true);
    }
    subscribeToPushNotifications() {
        if (!this.notificationsService.notifications) {
            console.warn('Attempt to subscribe to notifications too early.');
            return;
        }
        if (this.notificationsSubscription) {
            this.notificationsSubscription.unsubscribe();
        }
        this.notificationsSubscription = this.notificationsService.notifications
            .subscribe((notif) => {
            console.log('header notification', notif);
            const notification = new NotificationModel(notif);
            this.notifications.push(notification);
        });
    }
    getMessages() {
        this.notificationsService.getMessages()
            .then((response) => {
            if (response && response.body) {
                this.messages = response.body.results
                    .filter((msg) => !msg.read)
                    .map((msg) => new NotificationModel(msg));
            }
        })
            .catch((errorResponse) => {
            console.log('Could not retrieve messages', errorResponse);
        });
    }
}
__decorate([
    SessionStorage('acknowledgedBrowserSupport', false),
    __metadata("design:type", Boolean)
], HeaderComponent.prototype, "acknowledgedBrowserSupport", void 0);
