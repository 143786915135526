import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: [ '../buttons.scss', './button-loader.component.scss' ],
})

export class ButtonLoaderComponent {

  @Input() disabled: boolean = false;
  @Input() label: string = 'ok';
  @Input() loading: boolean = false;
  @Input() customClasses: [string] = [''];

  @Output() buttonClick:  EventEmitter<any> = new EventEmitter();

  get actualLabel(): string {
    return this.loading ? ' ' : this.label;
  }

  constructor(
  ) {
  }

  public onButtonClick(): void {
    this.buttonClick.emit();
  }
}
