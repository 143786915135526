var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit, } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, } from '@angular/common';
import { LocalStorage, SessionStorage, } from 'ngx-webstorage';
import { GuidService } from './common/services/guid.service';
import { VersionCheckService } from './common/services/version-check.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Location } from '@angular/common';
export class AppComponent {
    constructor(_platformId, document, _guid, _device, _versionCheck, location) {
        this._platformId = _platformId;
        this.document = document;
        this._guid = _guid;
        this._device = _device;
        this._versionCheck = _versionCheck;
        this.location = location;
        this.deviceInfo = null;
        this.sessionId = '';
        this.scrollPos = 0;
        this.navIsFixed = false;
        if (isPlatformServer(_platformId)) {
            // silence console on the server
            // console.log = (): void => {};
            console.error = () => { };
            console.warn = () => { };
        }
        else {
            this._versionCheck.initVersionCheck();
            /* create a session id if not set */
            if (!this.sessionId) {
                this.sessionId = this._guid.guid();
                console.log('New session created', this.sessionId);
            }
            else {
                console.log('Existing sessionId', this.sessionId);
            }
            this.deviceInfo = this._device.getDeviceInfo();
            /*
              https://koderlabs.github.io/ngx-device-detector/miscellaneous/variables.html
      
              deviceInfo.browser
                {
                  CHROME: 'Chrome',
                  FIREFOX: 'Firefox',
                  SAFARI: 'Safari',
                  OPERA: 'Opera',
                  IE: 'IE',
                  MS_EDGE: 'MS-Edge',
                  FB_MESSANGER: 'FB-Messanger',
                  SAMSUNG: 'Samsung',
                  UCBROWSER: 'UC-Browser',
                  UNKNOWN: 'Unknown'
                }
            */
            console.log('Device info', this.deviceInfo);
        }
    }
    onWindowScroll() {
        if (isPlatformBrowser(this._platformId) && typeof window !== 'undefined') {
            this.scrollPos = window.pageYOffset ||
                (this.document && this.document.documentElement && this.document.documentElement.scrollTop) ||
                this.document.body.scrollTop || 0;
            this.navIsFixed = this.scrollPos > 100;
        }
    }
    get useHeader() {
        return !this.location.path().startsWith('/closers');
    }
    get useFooter() {
        return this.location.path().indexOf('dashboard') === -1;
    }
    ngOnInit() {
        if (typeof window !== 'undefined') {
            window.addEventListener('storage', (event) => {
                if (event.key === null) {
                    window.location.href = '/';
                }
            });
        }
    }
}
__decorate([
    LocalStorage('buyer'),
    __metadata("design:type", Object)
], AppComponent.prototype, "buyer", void 0);
__decorate([
    SessionStorage('session-id'),
    __metadata("design:type", String)
], AppComponent.prototype, "sessionId", void 0);
