var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/** Registration form component contains form to register in the application
 *
 */
import { OnInit, OnDestroy, EventEmitter, } from '@angular/core';
import { FormBuilder, Validators, } from '@angular/forms';
import { LocalStorage } from 'ngx-webstorage';
import { CommonValidators } from '@common/validators/common-validators.service';
import { EmailAsyncValidatorService } from '@common/validators/email-async-validator.service';
import { AuthService } from '../../auth.service';
import { RequestService } from '@common/services/request.service';
import { ALREADY_EXIST } from '../../auth.constants';
import { Subscription } from 'rxjs/Subscription';
import { REGISTRATION_STAGE_INFO, REGISTRATION_STAGE_PASSWORD, REGISTRATION_STAGE_COMPLETE, } from '@common/constants';
export class RegistrationFormComponent {
    constructor(formBuilder, emailAsyncValidator, authService, requestService) {
        this.formBuilder = formBuilder;
        this.emailAsyncValidator = emailAsyncValidator;
        this.authService = authService;
        this.requestService = requestService;
        this.registerForm = null;
        this.passwordForm = null;
        this.showRegitrationForm = false;
        this.loading = false;
        this.errorMessage = null;
        this.stage = REGISTRATION_STAGE_INFO;
        this.subscribers = new Subscription();
        this.isExistEmail = false;
        this.stageChange = new EventEmitter();
        const isShowRegistrationSebscriber = this.authService.isShowRegistrationModal().subscribe((result) => {
            this.showRegitrationForm = result;
        }, (error) => {
            console.log('error in subscription', error);
        });
        this.subscribers.add(isShowRegistrationSebscriber);
    }
    ngOnInit() {
        this.createForms();
        console.log('this.registerForm', this.registerForm);
    }
    ngOnDestroy() {
        if (this.subscribers) {
            this.subscribers.unsubscribe();
        }
    }
    hideRegistrationModal() {
        this.authService.changeRegistrationModalVisibility(false);
    }
    createForms() {
        this.registerForm = this.formBuilder.group({
            firstName: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(50),
                    CommonValidators.name,
                ]),
            ],
            lastName: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(50),
                    CommonValidators.name,
                ]),
            ],
            emailAddress: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(100),
                ]),
                this.emailAsyncValidator.validateEmail,
            ],
            companyName: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(56),
                ]),
            ],
            phoneNumber: [
                null,
                Validators.compose([
                    Validators.required,
                    CommonValidators.phone,
                ]),
            ],
        });
        this.passwordForm = this.formBuilder.group({
            password: [
                null,
                Validators.compose([
                    Validators.minLength(8),
                    Validators.maxLength(100),
                    Validators.required,
                ]),
            ],
            passwordConfirm: [
                null,
                Validators.compose([
                    Validators.minLength(8),
                    Validators.maxLength(100),
                    Validators.required,
                ]),
            ],
        }, {
            validators: CommonValidators.passwordMatch,
        });
        const emailExistSubscriber = this.registerForm.controls.emailAddress.statusChanges.subscribe((status) => {
            if (status === 'VALID' && this.registerForm) {
                const email = this.registerForm.controls.emailAddress.value;
                this.emailAsyncValidator.staticExistEmail(email)
                    .then((exist) => {
                    if (exist) {
                        this.isExistEmail = true;
                    }
                    else {
                        this.isExistEmail = false;
                    }
                });
            }
        });
        this.subscribers.add(emailExistSubscriber);
    }
    onRegisterFormSubmit() {
        if (!this.registerForm || !this.registerForm.value) {
            return;
        }
        if (this.isExistEmail) {
            this.authService.changeLoginStatus(ALREADY_EXIST);
            this.authService.changeLoginModalVisibility(true);
            this.authService.changeRegistrationModalVisibility(false);
        }
        this.setStage(REGISTRATION_STAGE_PASSWORD);
    }
    onPasswordSubmit() {
        if (!this.registerForm || !this.registerForm.value ||
            !this.passwordForm || !this.passwordForm.value) {
            return;
        }
        this.loading = true;
        const buyerData = Object.assign({}, this.registerForm.value, { password: this.passwordForm.value.password });
        this.requestService.send('post', '/api/auth/register', buyerData)
            .subscribe(this.onBuyerCreated.bind(this), this.handleError.bind(this));
    }
    onBuyerCreated(buyer) {
        console.log('onBuyerCreated', buyer);
        if (this.registerForm) {
            // save buyer in session
            this.buyer = buyer;
            this.getPartner();
        }
        else {
            return console.log('Error attempting to create partner without buyer info.');
        }
    }
    getPartner() {
        console.log('getPartner');
        // create a partner with buyer info
        this.requestService.send('get', '/api/partners')
            .subscribe(this.onRegistrationComplete.bind(this), this.handleError.bind(this));
    }
    onRegistrationComplete(partner) {
        console.log('onRegistrationComplete', partner);
        this.partner = partner;
        this.loading = false;
        this.setStage(REGISTRATION_STAGE_COMPLETE);
        if (this.passwordForm) {
            this.passwordForm.markAsPristine({ onlySelf: true });
        }
    }
    handleError(errorResponse) {
        this.loading = false;
        this.parseErrorMessage(errorResponse);
        this.passwordForm.markAsPristine({ onlySelf: true });
        this.passwordForm.reset();
        if (this.errorMessage.message.indexOf('mail') > -1) {
            this.setStage(REGISTRATION_STAGE_INFO);
            const emailAddress = this.registerForm.get('emailAddress');
            emailAddress.setErrors({ invalid: true, message: 'already registered' }, { emitEvent: true });
        }
    }
    parseErrorMessage(errorResponse) {
        console.log('errorResponse', errorResponse);
        if (errorResponse &&
            errorResponse.error &&
            errorResponse.error.error &&
            typeof errorResponse.error.error === 'string') {
            this.errorMessage = { message: errorResponse.error.error };
        }
        else {
            this.errorMessage = { message: 'Oh dear, something went wrong' };
        }
    }
    setStage(stageName) {
        this.stage = stageName;
        this.stageChange.emit(stageName);
    }
    isPasswordFormInvalid() {
        return this.passwordForm &&
            ((this.passwordForm.touched || this.passwordForm.dirty) && !this.passwordForm.valid && !this.passwordForm.disabled) ||
            false;
    }
    getErrors() {
        return this.passwordForm && (this.passwordForm.errors);
    }
    isRegistrationError() {
        return !!this.errorMessage &&
            !(this.passwordForm &&
                (this.passwordForm.touched || this.passwordForm.dirty) || false);
    }
}
__decorate([
    LocalStorage('buyer'),
    __metadata("design:type", Object)
], RegistrationFormComponent.prototype, "buyer", void 0);
__decorate([
    LocalStorage('partner'),
    __metadata("design:type", Object)
], RegistrationFormComponent.prototype, "partner", void 0);
