import {
  Pipe,
  PipeTransform,
} from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'DoBPipe' })
export class DateOfBirthPipe implements PipeTransform {

  constructor() {}

  transform(value: string): string {
    if (!value) {
      return 'None';
    }
    return moment(value).format('MM/DD/YYYY');
  }
}
