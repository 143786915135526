import { parse, format, AsYouType, } from 'libphonenumber-js';
import moment from 'moment';
function accountingParse(value) {
    if (typeof accounting === 'undefined') {
        return value;
    }
    else {
        return accounting.parse(value);
    }
}
export function accountingFormatNumber(value, decimals = 0) {
    if (typeof accounting === 'undefined') {
        return value;
    }
    else {
        return accounting.formatNumber(value, decimals);
    }
}
const ɵ0 = (c) => {
    if (!c.value) {
        return null;
    }
    const chars = /^[a-zA-Z][a-zA-Z0-9\s\-]*$/.test(c.value);
    return chars ? null : {
        format: true,
        message: 'can only contain letters',
    };
}, ɵ1 = (c) => {
    if (!c.value) {
        return null;
    }
    const chars = /^[a-zA-Z][a-zA-Z0-9\s\-]*$/.test(c.value);
    return chars ? null : {
        format: true,
        message: 'can only contain letters',
    };
}, ɵ2 = (c) => {
    if (c.value) {
        let primaryPhone;
        try {
            primaryPhone = parsePhone(c.value);
        }
        catch (e) {
            return {
                format: true,
                message: 'could not be formatted',
            };
        }
        return primaryPhone.libFormatted.length === 14 ? null : {
            format: true,
            message: 'is invalid',
        };
    }
    return null;
}, ɵ3 = (group) => {
    const password = group.controls.password.value;
    const passwordConfirm = group.controls.passwordConfirm.value;
    return password === passwordConfirm ||
        !group.controls.password.valid ||
        !group.controls.passwordConfirm.valid ? null : {
        format: true,
        message: 'do not match',
    };
}, ɵ4 = (c, min = 1) => {
    if (c.value) {
        const amount = accountingParse(c.value);
        return amount <= min ? {
            min: true,
            message: `Amount must be greater than $${accountingFormatNumber(min)}`,
        } : null;
    }
    return null;
}, ɵ5 = (c) => {
    if (c.value) {
        const nowYear = +moment().format('YY');
        const reg = /[\d]{2}\/[\d]{2}/ig;
        const checkFormat = reg.test(c.value);
        const splitValues = c.value.split('/');
        const monthValue = parseInt(splitValues[0], 10);
        const yearValue = parseInt(splitValues[1], 10);
        return !checkFormat ||
            monthValue <= 0 ||
            monthValue >= 13 ||
            yearValue < nowYear ||
            c.value.length !== 5 ? {
            format: true,
            message: 'has wrong format',
        } : null;
    }
    return null;
}, ɵ6 = (form) => {
    if (form.controls.password.value === form.controls.confirmPassword.value) {
        return null;
    }
    else {
        return { coincidencePassword: false, message: 'Passwords must match' };
    }
}, ɵ7 = (c) => {
    const regex = /^[a-zA-Z0-9_]*$/ig;
    if (regex.test(c.value)) {
        return null;
    }
    else {
        return {
            format: true,
            message: 'has wrong format',
        };
    }
};
export const CommonValidators = {
    name: ɵ0,
    fullName: ɵ1,
    phone: ɵ2,
    passwordMatch: ɵ3,
    moneyValue: ɵ4,
    creditDate: ɵ5,
    confirmPassword: ɵ6,
    alphanumeric: ɵ7,
};
export function parsePhone(value) {
    const asYouType = new AsYouType('US');
    const libFormatted = asYouType.input(value);
    return {
        libFormatted: libFormatted,
        international: format(value, 'US', 'International'),
        parsed: parse(value, 'US'),
        dashed: libFormatted.replace(' )', '').replace('(', '').replace(') ', '-').replace(')', ''),
    };
}
export function isMongoId(value) {
    const regex = /^[0-9a-fA-F]{24}$/i;
    return regex.test(value);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
