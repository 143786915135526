import { Injectable } from '@angular/core';

import { RequestService } from './request.service';
import { LocalStorage } from 'ngx-webstorage';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PortalService {

  @LocalStorage('buyer')
  public buyer: any;

  @LocalStorage('partner')
  public partner: any;

  constructor(
    private requestService: RequestService,
  ) {

  }

  private checkCampaignType(type: string): string {
    // adjust to API route
    // insurance vs. insurances
    if (type == 'insurance') {
      type = 'insurances';
    }
    return type;
  }

  public getPartners(options?: any): Promise<any> {
    return this.requestService.promise('get', '/api/partners', options)
      .then((response: any): boolean => {
        this.partner = response;
        return true;
      })
      .catch((_errorResponse: any): boolean => {
        console.log('getPartners', _errorResponse);
        return false;
      });
  }

  public updatePartnerProfile(options: any): Promise<any> {
    if (environment.production) {
      if ((this.buyer || {}).jwt) {
        return this.requestService.promise('patch', '/api/partners', options)
          .then((response: any) => {
            this.partner = response.body;
            return response;
          })
          .catch((_errorResponse: any) => {
            console.log('updatePartnerProfile', _errorResponse);
            return null;
          });
      } else {
        return new Promise((resolve: any): void => {
          console.log('PORTAL: no buyer found');
          resolve(false);
        });
      }
    } else {
      return new Promise((resolve: any): void => {
        resolve(true);
      });
    }
  }

  public getCampaign(type: string, name: string, createFlag: boolean = true): Promise<any> {

    if (!environment.production) {
      return this.fakeCampaignResponse();
    }

    // adjust type param to api routes
    type = this.checkCampaignType(type);

    /* Encapsulate get or create a campaign for partner */
    return this.requestService
      .promise('get', `/api/filters/${type}/${name}`, { limit: 1 })
      .then((response: any) => {
        /* assuming now there's one campaign per partner account */
        const campaign = response.body.results[0];

        if (typeof campaign === 'undefined' && createFlag) {
          console.log('no campaign found for this partner.');
          return this.createCampaign(type, name);
        } else if (typeof campaign === 'undefined') {
          return campaign;
        } else {
          campaign.type = type;
          campaign.name = name;
          return campaign;
        }

      }).catch(this.requestService.handleError.bind(this));
  }

  public createCampaign(type: string, name: string): Promise<any> {
    // adjust type param to api routes
    type = this.checkCampaignType(type);

    return this.requestService
      .promise('post', `/api/filters/${type}/${name}`, {})
      .then((response: any) => {
        console.log('new campaign created.');

        response.body.type = type;
        response.body.name = name;
        return response.body;
      })
      .catch(this.requestService.handleError.bind(this));
  }

  public updateCampaign(type: string, name: string, id: string, filter: any): Promise<any> {
    if (!environment.production) {
      return this.fakeCampaignResponse();
    }

    // adjust type param to api routes
    type = this.checkCampaignType(type);

    return this.requestService
      .promise('patch',
        `/api/filters/${type}/${name}/${id}`, filter)
      .then((response: any): boolean => {

        response.body.type = type;
        response.body.name = name;
        return response.body;
      })
      .catch(this.requestService.handleError.bind(this));
  }

  public updateCampaignStatus(id: string , options: any, campaignName: string = 'auto'): Promise<any> {

    if (!environment.production) {
      return this.fakeCampaignResponse();
    }

    return this.requestService
      .promise('patch',
        `/api/filters/insurances/${campaignName}/${id}/status`, options)
      .then((response: any): boolean => response.body)
      .catch(this.requestService.handleError.bind(this));
  }

  private fakeCampaignResponse(): Promise<any>  {
    return new Promise((resolve: any): void => {
      resolve({
        '_id': '5c6ef1e27da6810030df1688',
        'secondaryCarriers': ['21st Century', 'GEICO'],
        'states': ['AL', 'AK', 'DE', 'IN', 'OR'],
        'quoteOnWeekends': true,
        'accountId': '5c6dbb4ce18f510030274603',
        'status': 'pending',
        'primaryCarrier': 'AIG',
        'maxQuotesPerDay': 10,
        'createdAt': '2019-02-21T18:45:54.845Z',
        'updatedAt': '2019-02-23T22:19:47.149Z',
      });
    });
  }
}
