import { Component } from '@angular/core';
import { GlobalModalsClass } from '@models';
import { GlobalModalService } from '@services';

/*
===============
  Use Example:
===============

  this.modalService.setConfigs({
    title: 'Are You Sure?',
    type: 'CONFIRM',
    description: 'Once submitted, you will no ...',
    show: true,
    callbacks: {
      confirm: this.someFunction,
      close: this.someFunction,
    },
  });

  Note: Don`t forget bind 'this' to callbacks if it required !!!
*/
@Component({
  selector: 'app-global-modals',
  templateUrl: './global-modals.component.html',
  styleUrls: [ './global-modals.component.scss' ],
})
export class GlobalModalsComponent extends GlobalModalsClass {

  constructor(
    public modalService: GlobalModalService,
  ) {
    super(modalService);
  }

}
