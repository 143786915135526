import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AuthModule } from '../auth/auth.module';

import { StaticRoutingModule } from './static-routing.module';

import { TermsComponent } from './terms/terms.component';
import { StaticComponent } from './static/static.component';

@NgModule({
  declarations: [
    TermsComponent,
    StaticComponent,
  ],
  imports: [
    SharedModule,
    AuthModule,
    StaticRoutingModule,
  ],
  exports: [
    TermsComponent,
  ],
})
export class StaticModule { }
