/** Registration form component contains form to register in the application
 *
 */
import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LocalStorage } from 'ngx-webstorage';
import { CommonValidators } from '@common/validators/common-validators.service';
import { EmailAsyncValidatorService } from '@common/validators/email-async-validator.service';
import { AuthService } from '../../auth.service';
import { RequestService } from '@common/services/request.service';
import { ALREADY_EXIST } from '../../auth.constants';
import { Subscription } from 'rxjs/Subscription';

import {
  REGISTRATION_STAGE_INFO,
  REGISTRATION_STAGE_PASSWORD,
  REGISTRATION_STAGE_COMPLETE,
} from '@common/constants';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
})

export class RegistrationFormComponent implements OnInit, OnDestroy {

  public registerForm: FormGroup | null = null;
  public passwordForm: FormGroup | null = null;
  public showRegitrationForm: boolean = false;
  public loading: boolean = false;
  public errorMessage: {[key: string]: string} | null = null;
  public stage: string = REGISTRATION_STAGE_INFO;

  private subscribers: Subscription = new Subscription();
  private isExistEmail: boolean = false;

  @LocalStorage('buyer')
  public buyer: any;

   @LocalStorage('partner')
  public partner: any;

  @Output() stageChange: EventEmitter<string> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private emailAsyncValidator: EmailAsyncValidatorService,
    private authService: AuthService,
    private requestService: RequestService,
  ) {
    const isShowRegistrationSebscriber = this.authService.isShowRegistrationModal().subscribe(
      (result: boolean): void => {
        this.showRegitrationForm = result;
      },
      (error: any): void => {
        console.log('error in subscription', error);
      });

    this.subscribers.add(isShowRegistrationSebscriber);
  }

  ngOnInit(): void {
    this.createForms();

    console.log('this.registerForm', this.registerForm);
  }

  ngOnDestroy(): void {
    if (this.subscribers) {
      this.subscribers.unsubscribe();
    }
  }

  public hideRegistrationModal(): void {
    this.authService.changeRegistrationModalVisibility(false);
  }

  private createForms(): void {
    this.registerForm = this.formBuilder.group({
      firstName: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          CommonValidators.name,
        ]),
      ],
      lastName: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          CommonValidators.name,
        ]),
      ],
      emailAddress: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
        this.emailAsyncValidator.validateEmail,
      ],
      companyName: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(56),
        ]),
      ],
      phoneNumber: [
        null,
        Validators.compose([
          Validators.required,
          CommonValidators.phone,
        ]),
      ],
    });

    this.passwordForm = this.formBuilder.group({
      password: [
        null,
        Validators.compose([
          Validators.minLength(8),
          Validators.maxLength(100),
          Validators.required,
        ]),
      ],
      passwordConfirm: [
        null,
        Validators.compose([
          Validators.minLength(8),
          Validators.maxLength(100),
          Validators.required,
        ]),
      ],
    }, {
      validators: CommonValidators.passwordMatch,
    });

    const emailExistSubscriber = this.registerForm.controls.emailAddress.statusChanges.subscribe((status: string): any => {
      if (status === 'VALID' && this.registerForm) {
        const email: string = this.registerForm.controls.emailAddress.value;
        this.emailAsyncValidator.staticExistEmail(email)
          .then((exist: any) => {
            if (exist) {
              this.isExistEmail = true;
            } else {
              this.isExistEmail = false;
            }
          });
        }
    });
    this.subscribers.add(emailExistSubscriber);
  }

  public onRegisterFormSubmit(): void {
    if (!this.registerForm || !this.registerForm.value) {
      return;
    }

    if (this.isExistEmail) {
      this.authService.changeLoginStatus(ALREADY_EXIST);
      this.authService.changeLoginModalVisibility(true);
      this.authService.changeRegistrationModalVisibility(false);
    }

    this.setStage(REGISTRATION_STAGE_PASSWORD);
  }

  public onPasswordSubmit(): void {

    if (!this.registerForm || !this.registerForm.value ||
      !this.passwordForm || !this.passwordForm.value
    ) {
      return;
    }
    this.loading = true;

    const buyerData = { ...this.registerForm.value, password: this.passwordForm.value.password };

    this.requestService.send('post', '/api/auth/register', buyerData)
      .subscribe(this.onBuyerCreated.bind(this), this.handleError.bind(this));
  }

  private onBuyerCreated(buyer: any): void {
    console.log('onBuyerCreated', buyer);
    if (this.registerForm) {
      // save buyer in session
      this.buyer = buyer;
      this.getPartner();
    } else {
      return console.log('Error attempting to create partner without buyer info.');
    }
  }

  private getPartner(): void {
    console.log('getPartner');
    // create a partner with buyer info
    this.requestService.send('get', '/api/partners')
      .subscribe(this.onRegistrationComplete.bind(this), this.handleError.bind(this));
  }

  private onRegistrationComplete(partner: any): void {
    console.log('onRegistrationComplete', partner);
    this.partner = partner;
    this.loading = false;
    this.setStage(REGISTRATION_STAGE_COMPLETE);

    if (this.passwordForm) {
      this.passwordForm.markAsPristine({ onlySelf: true });
    }
  }

  private handleError(errorResponse: any): void {
    this.loading = false;

    this.parseErrorMessage(errorResponse);

    this.passwordForm!.markAsPristine({ onlySelf: true });
    this.passwordForm!.reset();

    if (this.errorMessage!.message.indexOf('mail') > -1) {
      this.setStage(REGISTRATION_STAGE_INFO);
      const emailAddress = this.registerForm!.get('emailAddress')!;
      emailAddress.setErrors(
        { invalid: true, message: 'already registered' },
        { emitEvent: true });
    }
  }

  private parseErrorMessage(errorResponse: any): void {
    console.log('errorResponse', errorResponse);
    if (errorResponse &&
      errorResponse.error &&
      errorResponse.error.error &&
      typeof errorResponse.error.error === 'string') {
      this.errorMessage = { message: errorResponse.error.error };
    } else {
      this.errorMessage = { message: 'Oh dear, something went wrong' };
    }
  }

  private setStage(stageName: string): void {
    this.stage = stageName;
    this.stageChange.emit(stageName);
  }

  public isPasswordFormInvalid(): boolean {
    return this.passwordForm &&
      ((this.passwordForm.touched || this.passwordForm.dirty) && !this.passwordForm.valid && !this.passwordForm.disabled) ||
      false;
  }

  public getErrors(): any {
    return this.passwordForm && (this.passwordForm.errors);
  }

  public isRegistrationError(): boolean {
    return !!this.errorMessage &&
      !(this.passwordForm &&
      (this.passwordForm.touched || this.passwordForm.dirty) || false ) ;
  }
}
