/* Note: (https://angular.io/guide/ngmodule)
 *  Do not specify app-wide singleton providers in a shared module.
 *  A lazy-loaded module that imports that shared module makes its own copy of the service.
 */

/*
  !Importat: Don't forget to add these to exports if components are shared across modules
*/
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InputMaskModule } from 'racoon-mask-raw';

import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environments/environment';



// Form components

// components
import { FieldErrorComponent } from './field-error/field-error.component';
import { EmailInputComponent } from './email-input/email-input.component';
import { TextInputComponent } from './text-input/text-input.component';
import { TelInputComponent } from './tel-input/tel-input.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { CheckboxInputComponent } from './checkbox-input/checkbox-input.component';
import { RadioInputComponent } from './radio-input/radio-input.component';
import { MoneyInputComponent } from './money-input/money-input.component';
import { FormLoaderComponent } from './form-loader/form-loader.component';
import { ButtonLoaderComponent } from './button-loader/button-loader.component';
import { TabsComponent } from './tabs/tabs.component';
import { ModalComponent } from './modal/modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { DateInputComponent } from './date-input/date-input.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { TextareaInputComponent } from './textarea-input/textarea-input.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { SelectComponent } from './select/select.component';
import { AsSeenInComponent } from './as-seen-in/as-seen-in.component';
import { AddressGroupComponent } from './address-group/address-group.component';
import { TextareaComponent } from './textarea/textarea.component';
import { GlobalModalsComponent } from './global-modals/global-modals.component';
import { GlobalConfirmComponent } from './global-modals/confirm/global-confirm.component';
import { GlobalNotifyComponent } from './global-modals/notify/global-notify.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CheckboxSliderComponent } from './checkbox-slider/checkbox-slider.component';
import { LazyLoadComponent } from './lazy-load/lazy-load.component';
import { HelpfulTipComponent } from './helpful-tip/helpful-tip.component';
import { ChevronLinkComponent } from './chevron-link/chevron-link.component';

// services
import { RequestService } from '../common/services/request.service';
import { GuidService } from '../common/services/guid.service';
import { EmailAsyncValidatorService } from '../common/validators/email-async-validator.service';
import { CreditCardService } from '../common/services/credit-card.service';
import { CreditCardValidatorService } from '../common/validators/credit-card-async-validator.service';
import { AccountingService } from '../common/services/accounting.service';
import { GoogleMapsService } from '../common/services/google-maps.service';
import { CampaignService } from '@common/services/campaign.service';
import { LocationAsyncValidatorService } from '@common/validators/location-async.validator';
import { SupportService } from '@services';
import { WebsocketService } from '@common/services/websocket.service';
import { NotificationsService } from '@common/services/notifications.service';
import { GroupByService } from '@services';
import { PromocodeValidator } from '@validators';
import { CarriersService } from '@services';

// directives
import { TelInputDirective } from '../common/directives/tel-input.directive';
import { MoneyInputDirective } from '../common/directives/money-input.directive';
import { DateInputDirective } from '../common/directives/date-input.directive';
import { NumbersOnlyInputDirective } from '../common/directives/numbers-only-input.directive';
import { ClickOutsideDirective } from '../common/directives/click-outside.directive';

// pipes
import { OnlySelectedPipe } from '../common/pipes/only-selected.pipe';
import { FormatMoneyPipe } from '@common/pipes/format-money.pipe';
import { ParseBackendValuePipe } from '@common/pipes/parseBackendValue.pipe';
import { ArrayToStringPipe } from '@common/pipes/arrayToString.pipe';
import { DateOfBirthPipe } from '@common/pipes/dateOfBirth.pipe';

@NgModule({
  imports:      [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google_api_key,
      libraries: ['places'],
    }),
    NgbModule,
    InputMaskModule,
  ],
  declarations: [
    FieldErrorComponent,
    TextInputComponent,
    EmailInputComponent,
    TelInputComponent,
    PasswordInputComponent,
    CheckboxInputComponent,
    RadioInputComponent,
    MoneyInputComponent,
    TelInputDirective,
    MoneyInputDirective,
    FormLoaderComponent,
    ButtonLoaderComponent,
    TabsComponent,
    ModalComponent,
    ConfirmModalComponent,
    OnlySelectedPipe,
    ParseBackendValuePipe,
    FormatMoneyPipe,
    ArrayToStringPipe,
    DateOfBirthPipe,
    DateInputComponent,
    DateInputDirective,
    NumbersOnlyInputDirective,
    NumberInputComponent,
    DropdownComponent,
    ClickOutsideDirective,
    SelectComponent,
    TextareaInputComponent,
    AsSeenInComponent,
    AddressGroupComponent,
    TextareaComponent,
    GlobalModalsComponent,
    GlobalConfirmComponent,
    GlobalNotifyComponent,
    NotificationsComponent,
    CheckboxSliderComponent,
    HelpfulTipComponent,
    LazyLoadComponent,
    ChevronLinkComponent,
  ],
  providers: [
    RequestService,
    EmailAsyncValidatorService,
    CreditCardService,
    CreditCardValidatorService,
    CookieService,
    GuidService,
    OnlySelectedPipe,
    FormatMoneyPipe,
    ParseBackendValuePipe,
    ArrayToStringPipe,
    DateOfBirthPipe,
    AccountingService,
    GoogleMapsService,
    CampaignService,
    LocationAsyncValidatorService,
    SupportService,
    WebsocketService,
    NotificationsService,
    GroupByService,
    PromocodeValidator,
    CarriersService,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FieldErrorComponent,
    TextInputComponent,
    EmailInputComponent,
    TelInputComponent,
    PasswordInputComponent,
    CheckboxInputComponent,
    RadioInputComponent,
    MoneyInputComponent,
    SelectComponent,
    TelInputDirective,
    MoneyInputDirective,
    TextareaInputComponent,
    FormLoaderComponent,
    ButtonLoaderComponent,
    TabsComponent,
    ModalComponent,
    ConfirmModalComponent,
    OnlySelectedPipe,
    FormatMoneyPipe,
    ParseBackendValuePipe,
    ArrayToStringPipe,
    DateOfBirthPipe,
    DateInputComponent,
    DateInputDirective,
    NumbersOnlyInputDirective,
    NumberInputComponent,
    DropdownComponent,
    ClickOutsideDirective,
    AsSeenInComponent,
    AddressGroupComponent,
    AgmCoreModule,
    TextareaComponent,
    GlobalModalsComponent,
    GlobalConfirmComponent,
    GlobalNotifyComponent,
    NotificationsComponent,
    CheckboxSliderComponent,
    HelpfulTipComponent,
    LazyLoadComponent,
    NgbModule,
    InputMaskModule,
    ChevronLinkComponent,
  ],
})

export class SharedModule {}
