import { ITab } from '@shared/tabs/interfaces';

export const personalInjuryProtectionAssociations: any = {
  'insured': 'Insured',
  'insured_relative': 'Insured relative',
  'insured_exclude_workloss': 'Insured exclude workloss',
  'insured_relative_exclude_workloss': 'Insured relative exclude workloss',
};

export const dashboardQuotesTabs: any = {
  newTab: 'new',
  pausedTab: 'paused',
  quotedTab: 'quoted',
  expiredTab: 'expired',
  wonTab: 'won',
  rejectedTab: 'rejected',
};

export const yearsBuiltAliases: any = {
  '5_years_or_less': '5 years or less',
  '5_10_years': '5-10 years',
  '10_15_years': '10-15 years',
  '15_20_years': '15-20 years',
  '20+_years': '20+ years',
};

export const DASHBOARD_TABS: Array<ITab> = [
  {
    label: 'NEW',
    name: 'new',
    active: true,
  },
  {
    label: 'PAUSED',
    name: 'paused',
    active: false,
  },
  {
    label: 'QUOTED',
    name: 'quoted',
    active: false,
  },
  {
    label: 'WON',
    name: 'won',
    active: false,
  },
  {
    label: 'EXPIRED',
    name: 'expired',
    active: false,
  },
  {
    label: 'ALL',
    name: 'all',
    active: false,
  },
];

export const QUOTES_NAME_FOR_SUMMARY: any = {
  expired: 'numExpired',
  quoted: 'numQuoted',
  quoting: 'numQuoting',
  rejected: 'numRejected',
  accepted: 'numWon',
  paused: 'numPaused',
  all: 'totalCount',
};

export const DASHBOARD_QUOTE_DISPLAY_NAMES: any = {
  auto_insurance: 'Auto Ins',
  home_insurance: 'Home Ins',
  renters_insurance: 'Rent Ins',
  condo_insurance: 'Condo Ins',
  business_insurance: 'Business Ins',
  health_insurance: 'Health Ins',
  motorcycle_insurance: 'Moto Ins',
  boat_insurance: 'Boat Ins',
};

export const QUOTE_FREQUENCY_TYPES: Array<{ slug: string, value: string }> = [
  { slug: '6 Months', value: '6_months' },
  { slug: 'Year', value: 'yearly' },
  { slug: 'Month', value: 'monthly' },
];

export const QUOTE_SAME_DETAILS_OPTIONS: Array<{ slug: string, value: string }> = [
  { slug: 'Yes', value: 'true' },
  { slug: 'No', value: 'false' },
];

export const QUOTE_VEHICLE_USES: any = {
  commute: 'Commute',
  pleasure: 'Pleasure',
  business: 'Business',
  delivery_service: 'Delivery service',
  farm: 'Farm',
  racing: 'Racing',
  leased: 'Leased',
  primary_residence: 'Primary Residence',
};

export const QUOTE_VEHICLE_PARKING: any = {
  driveway: 'Driveway',
  private_garage: 'Private garage',
  parking_garage: 'Parking garage',
  parking_lot: 'Parking lot',
  street: 'Street parking',
};

export const QUOTE_VEHICLE_SECURITY: any = {
  no_alarm: 'No alarm system',
  passive_disabling: 'Passive disabling',
  active_disabling: 'Active disabling',
  alarm_only: 'Alarm only',
  lojack: 'Lojack system',
};

export const QUOTE_ADDRESS_STATUSES: any = {
  own_with_mortgage: 'Owns with mortgage',
  own_outright: 'Owns outright',
  rent: 'Renting',
  with_parents: 'Living with parents',
  other: 'Other',
};

export const QUOTE_INSURED_FOR_OPTIONS: Array<{ slug: string, value: string }> = [
  { slug: 'Less than 6 months', value: 'less_than_6_months' },
  { slug: '6 to 12 months', value: '6_to_12_months' },
  { slug: '1 to 3 years', value: '1_to_3_years' },
  { slug: 'More than 3 years', value: '+3_years' },
  { slug: '3 to 5 years', value: '3_to_5_years' },
  { slug: 'More than 5 years', value: '+5_years' },
];

export const QUOTE_INCIDENT_TYPES: Array<{ slug: string, value: string }> = [
  { slug: 'Injury (no damage)', value: 'injury' },
  { slug: 'Injury & Damage', value: 'injury_damage' },
  { slug: 'Damage (no injury)', value: 'damage' },
  { slug: 'Minor accident', value: 'minor' },
  { slug: 'Accident', value: 'accident' },
  { slug: 'Speeding (< 10mph over)', value: 'less_10_mph_over' },
  { slug: 'Speeding (10-19mph over)', value: '10-19mph_over' },
  { slug: 'Speeding (20-39mph over)', value: '20-39mph_over' },
  { slug: 'Speeding (40mph+ over)', value: '40mph+_over' },
  { slug: 'Improper passing', value: 'improper_passing' },
  { slug: 'Failure using lights', value: 'failure_using_lights' },
  { slug: 'Failure using turn signals', value: 'failure_using_turn_signals' },
  { slug: 'Failure to obey sign', value: 'failure_obey_sign_signal' },
  { slug: 'Wrong way', value: 'wrong_way_one_way' },
  { slug: 'Reckless driving', value: 'reckless' },
  { slug: 'Other', value: 'other' },
  { slug: 'Currently', value: 'currently' },
  { slug: 'Driving with suspended license', value: 'driving_while_suspended' },
];

export const QUOTE_INCIDENT_CATEGORIES: Array<{ slug: string, value: string }> = [
  { slug: 'Accident (at fault)', value: 'accident_at_fault' },
  { slug: 'Accident (not at fault)', value: 'accident_not_at_fault' },
  { slug: 'Speeding', value: 'speeding' },
  { slug: 'Moving violation', value: 'moving_violation' },
  { slug: 'DUI/DWI', value: 'dui_dwi' },
  { slug: 'Suspended license', value: 'suspension' },
  { slug: 'Need SR22', value: 'need_sr22' },
  { slug: 'Expired license', value: 'expired' },
  { slug: 'Claims', value: 'claims' },
  { slug: 'Other', value: 'other' },
];

export const COLLISION_TYPE: Array<{slug: string, value: number}> = [
  { slug: 'Not Carried', value: -1 },
  { slug: 'No Deductible', value: 0 },
  { slug: '50', value: 50 },
  { slug: '100', value: 100 },
  { slug: '150', value: 150 },
  { slug: '200', value: 200 },
  { slug: '250', value: 250 },
  { slug: '500', value: 500 },
  { slug: '750', value: 750 },
  { slug: '1000', value: 1000 },
  { slug: '1500', value: 1500 },
  { slug: '2000', value: 2000 },
  { slug: '2500', value: 2500 },
  { slug: '5000', value: 5000 },
];

export const INJURY_LIABILITIES = [
  { value: 10, slug: '10,000' },
  { value: 15, slug: '15,000' },
  { value: 20, slug: '20,000' },
  { value: 25, slug: '25,000' },
  { value: 50, slug: '50,000' },
  { value: 100, slug: '100,000' },
  { value: 250, slug: '250,000' },
  { value: 300, slug: '300,000' },
  { value: 500, slug: '500,000' },
  { value: 750, slug: '750,000' },
  { value: 1000, slug: '1,000,000' },
];

export const INJURY_ACCIDENT_LIABILITIES = [
  { value: 20, slug: '20,000' },
  { value: 30, slug: '30,000' },
  { value: 40, slug: '40,000' },
  { value: 50, slug: '50,000' },
  { value: 100, slug: '100,000' },
  { value: 200, slug: '200,000' },
  { value: 300, slug: '300,000' },
  { value: 500, slug: '500,000' },
  { value: 750, slug: '750,000' },
  { value: 1000, slug: '1,000,000' },
];

export const PAY_FREQUENCY_TYPE: Array<{slug: string, value: string}> = [
  { slug: 'Weekly', value: 'weekly' },
  { slug: 'Biweekly', value: 'biweekly' },
  { slug: 'Monthly', value: 'monthly' },
  { slug: 'Twice Monthly', value: 'twice_monthly' },
  { slug: 'Other', value: 'other' },
];

export const EDUCATION_LEVEL: Array<{slug: string, value: string}> = [
  { slug: 'High School', value: 'high_school' },
  { slug: 'Associate', value: 'associate' },
  { slug: 'Bachelors', value: 'bachelors' },
  { slug: 'Masters', value: 'masters' },
  { slug: 'Doctorate', value: 'doctorate' },
  { slug: 'Other Grad degree', value: 'other_grad_degree' },
  { slug: 'Other', value: 'other' },
];

export const EMPLOYMENT_TYPE: Array<{slug: string, value: string}> = [
  { slug: 'Employed', value: 'employed' },
  { slug: 'Military', value: 'military' },
  { slug: 'Unemployed', value: 'not_employed' },
  { slug: 'Self-employed', value: 'self_employed' },
  { slug: 'Student', value: 'student' },
  { slug: 'Homemaker', value: 'homemaker' },
  { slug: 'Retired', value: 'retired' },
  { slug: 'Other', value: 'other' },
];
