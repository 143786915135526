/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./global-modals.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./confirm/global-confirm.component.ngfactory";
import * as i4 from "./confirm/global-confirm.component";
import * as i5 from "../../common/services/global-modals.service";
import * as i6 from "./notify/global-notify.component.ngfactory";
import * as i7 from "./notify/global-notify.component";
import * as i8 from "./global-modals.component";
var styles_GlobalModalsComponent = [i0.styles];
var RenderType_GlobalModalsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GlobalModalsComponent, data: {} });
export { RenderType_GlobalModalsComponent as RenderType_GlobalModalsComponent };
function View_GlobalModalsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-check modal-icon-check"]], null, null, null, null, null))], null, null); }
function View_GlobalModalsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalModalsComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.iconType === _co.CHECKICON); _ck(_v, 2, 0, currVal_0); }, null); }
function View_GlobalModalsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_GlobalModalsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "pb-4 pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 1, 0, currVal_0); }); }
function View_GlobalModalsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-global-confirm", [], null, null, null, i3.View_GlobalConfirmComponent_0, i3.RenderType_GlobalConfirmComponent)), i1.ɵdid(1, 49152, null, 0, i4.GlobalConfirmComponent, [i5.GlobalModalService], null, null)], null, null); }
function View_GlobalModalsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-global-notify", [], null, null, null, i6.View_GlobalNotifyComponent_0, i6.RenderType_GlobalNotifyComponent)), i1.ɵdid(1, 49152, null, 0, i7.GlobalNotifyComponent, [i5.GlobalModalService], null, null)], null, null); }
function View_GlobalModalsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "modal-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "modal-overlay"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toogleModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "modal-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "button", [["class", "cancel-btn icon icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toogleModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalModalsComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalModalsComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalModalsComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalModalsComponent_6)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalModalsComponent_7)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconType; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.description; _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.type === _co.CONFIRM); _ck(_v, 12, 0, currVal_3); var currVal_4 = (_co.type === _co.NOTIFY); _ck(_v, 14, 0, currVal_4); }, null); }
export function View_GlobalModalsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalModalsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GlobalModalsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-global-modals", [], null, null, null, View_GlobalModalsComponent_0, RenderType_GlobalModalsComponent)), i1.ɵdid(1, 49152, null, 0, i8.GlobalModalsComponent, [i5.GlobalModalService], null, null)], null, null); }
var GlobalModalsComponentNgFactory = i1.ɵccf("app-global-modals", i8.GlobalModalsComponent, View_GlobalModalsComponent_Host_0, {}, {}, []);
export { GlobalModalsComponentNgFactory as GlobalModalsComponentNgFactory };
