<div
  class='modal-holder'
  *ngIf='show'>
  <div
    (click)='toogleModal()'
    class='modal-overlay'></div>
  <div class='modal-inner'>
    <button
      class='cancel-btn icon icon-close'
      (click)='toogleModal()'>
    </button>
    <div *ngIf='iconType'>
      <i
        *ngIf='iconType === CHECKICON'
        class="fas fa-check modal-icon-check"></i>
    </div>
    <p
      *ngIf='title'
      class='title'>
      {{title}}
    </p>
    <p
      *ngIf='description'
      class='pb-4 pt-4'>
      {{description}}
    </p>
    <div>
       <app-global-confirm *ngIf='type === CONFIRM'></app-global-confirm>
       <app-global-notify *ngIf='type === NOTIFY'></app-global-notify>
    </div>
  </div>
</div>
