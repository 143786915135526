<div class='window-overlay'></div>
<div class='{{customClass}} modal'>
  <button
    class='cancel-btn icon icon-close'
    (click)='hideModal()'
    *ngIf='showCloseBtn'
    >
  </button>
  <p class='title' *ngIf='!noTitle'>
    {{title}}
  </p>

  <ng-content></ng-content>
</div>