import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { AuthGuardService as AuthGuard } from './common/services/auth-guard.service';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full'},
  /* tslint:disable-next-line */
  { path: 'static', loadChildren: (): Promise<any> => import('./static/static.module').then(m  => m.StaticModule)},
  /* tslint:disable-next-line */
  { path: 'portal', loadChildren: (): Promise<any> => import('./portal/portal.module').then(m => m.PortalModule), canActivate: [AuthGuard]},
  /* tslint:disable-next-line */
  { path: 'questionnaire', loadChildren: (): Promise<any> => import('./questionnaire/questionnaire.module').then(m => m.QuestionnaireModule), canActivate: [AuthGuard]},
  /* tslint:disable-next-line */
  // PJW: Remove the closers for now (10.24.19)
  // { path: 'closers', loadChildren: (): Promise<any> => import('./closers/closers.module').then(m => m.ClosersModule)},
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'set-password', component: ResetPasswordComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 95],
    }),
  ],
  exports: [RouterModule],
})

export class AppRoutingModule { }
