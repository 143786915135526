/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../form-components.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../common/directives/tel-input.directive";
import * as i3 from "../field-error/field-error.component.ngfactory";
import * as i4 from "../field-error/field-error.component";
import * as i5 from "@angular/forms";
import * as i6 from "./tel-input.component";
var styles_TelInputComponent = [i0.styles];
var RenderType_TelInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TelInputComponent, data: {} });
export { RenderType_TelInputComponent as RenderType_TelInputComponent };
export function View_TelInputComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { input: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], [[2, "floating-label", null], [2, "floating-label-new", null], [2, "disabled", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["input", 1]], null, 1, "input", [["appTelInput", ""], ["class", "floating-input"], ["inputmode", "numeric"], ["type", "tel"]], [[8, "name", 0], [8, "value", 0], [8, "placeholder", 0], [8, "disabled", 0], [2, "error", null], [2, "success", null]], [[null, "change"], [null, "keyup"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onInput($event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.onChange($event) !== false);
        ad = (pd_1 && ad);
    } if (("keyup" === en)) {
        var pd_2 = (_co.onChange($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.TelInputDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-field-error", [], null, null, null, i3.View_FieldErrorComponent_0, i3.RenderType_FieldErrorComponent)), i1.ɵdid(7, 114688, null, 0, i4.FieldErrorComponent, [], { errors: [0, "errors"], label: [1, "label"], isInvalid: [2, "isInvalid"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = _co.getErrors(); var currVal_11 = _co.label; var currVal_12 = _co.isInvalid(); _ck(_v, 7, 0, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.floatingLabel && !_co.fullBorder); var currVal_1 = (_co.floatingLabel && _co.fullBorder); var currVal_2 = _co.isDisabled(); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.name, ""); var currVal_4 = _co.value; var currVal_5 = _co.actualPlaceholder; var currVal_6 = _co.isDisabled(); var currVal_7 = _co.isInvalid(); var currVal_8 = _co.isValid(); _ck(_v, 2, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.label; _ck(_v, 5, 0, currVal_9); }); }
export function View_TelInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-tel-input", [], null, null, null, View_TelInputComponent_0, RenderType_TelInputComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.TelInputComponent]), i1.ɵprd(5120, null, i5.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i6.TelInputComponent]), i1.ɵdid(3, 638976, null, 0, i6.TelInputComponent, [[3, i5.ControlContainer]], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var TelInputComponentNgFactory = i1.ɵccf("app-tel-input", i6.TelInputComponent, View_TelInputComponent_Host_0, { formControlName: "formControlName", placeholder: "placeholder", label: "label", name: "name", iconized: "iconized", iconClass: "iconClass", floatingLabel: "floatingLabel", showLabel: "showLabel", fullBorder: "fullBorder" }, {}, []);
export { TelInputComponentNgFactory as TelInputComponentNgFactory };
