export const environment = {
  envName: 'staging',
  production: true, // act as production
  app_domain: 'http://leads-staging.squeeze.com',
  google_api_key: 'AIzaSyAiCwPHsZCuE22qXfF6qYOAaUssSB3FaIM',
  squeeze_internal_api_url: 'https://squeeze-internal-staging.herokuapp.com/api/v1',
  squeeze_partners_api_url: 'https://squeeze-partners-staging.herokuapp.com/api/v1',
  squeeze_quotes_api_url: 'https://squeeze-quotes-staging.herokuapp.com/api/v1',
  push_api_url: 'squeeze-push-staging.herokuapp.com/api/v1',
  stipe_key: 'pk_test_K4pH5rgAd5C2t04qQ9zlTktR',
  auto_logos_api_url: 'https://squeeze-auto-logo-final.s3.us-east-2.amazonaws.com/',
};
