import { IQuote } from '@interfaces';
import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import moment from 'moment';
import { Router } from '@angular/router';
import {
  NotificationsService,
  GroupByService,
  QuotesService,
} from '@services';
import { NotificationModel } from '@models';
import {
  quoteStatuses,
  dashboardQuotesTabs,
} from '@constants';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {

  @Input() notifications: Array<NotificationModel> = [];
  @Input() messages: Array<NotificationModel> = [];

  @Output() closeEvent: EventEmitter<any> = new EventEmitter();

  get isEmpty(): boolean {
    return this.notifications.length === 0 && this.messages.length === 0;
  }

  get messagesNotifications(): Array<any> {
    return this.notifications.filter((notif: any) => notif.notificationType === 'message');
  }

  get unreadMessages(): Array<any> {
    return this.messages.filter((message: any) => !message.read);
  }

  get unreadQuoteRequestMessages(): Array<any> {
    return this.unreadMessages.filter((message: any) => message.isRequest);
  }

  get unreadSubQuoteMessages(): Array<any> {
    const messages = this.unreadMessages.filter((message: any) => message.isQuote);
    return this.groupByService.groupBy('sid', messages);
  }

  get unreadSubQuoteMessagesKeys(): Array<string> {
    return Object.keys(this.unreadSubQuoteMessages);
  }

  constructor(
    private router: Router,
    private groupByService: GroupByService,
    private notificationsService: NotificationsService,
    private quotesService: QuotesService,
    ) { }

  public close(event: any = new Event('click')): void {
      // this method can be called programmatically
      // but must emit a click event if
      // preventDefault or stopPropagation are needed

    this.closeEvent.emit(event);
  }

  public displayId(id: string): string {
    return `${id.substring(id.length - 4, id.length)}`;
  }

  public fromNow(receivedAt: string): string {
    if (typeof window !== 'undefined' &&
        typeof moment !== 'undefined') {
      return moment(receivedAt).fromNow();
    } else {
      return receivedAt;
    }
  }

  public handleNotificationClick(notification: NotificationModel): void {
    if (notification.read) {
      return;
    }

    this.route(notification);
    this.markAsRead(notification);
  }

  public handleMessageClick(message: any, route: boolean = true): void {
    if (message.read) {
      return;
    }

    if (route) {
      this.route(message);
    }
    this.markAsRead(message);
  }

  public handleMultipleMessagesClick(messages: Array<any>): void {
    messages.forEach((message: any) => {
      this.handleMessageClick(message, false);
    });

    this.route(messages[0]);
  }

  private markAsRead(notification: NotificationModel): void {
    // send read receipts for notification
    this.notificationsService.sendReadReceipt(notification.id)
      .catch((_errorResponse: any) => {
        console.log('could not send read receipt for message', notification.id);
      });

    const markRead = (notif: NotificationModel): void => {
      if (notif.id === notification.id) {
        notif.markAsRead();
      }
    };

    if (notification.isNotification) {
      this.notifications.forEach(markRead);
    }

    if (notification.isMessage) {
      this.messages.forEach(markRead);
    }
  }

  get tabNameToQuoteStatusMap(): any {
    return {
      [quoteStatuses.quotingStatus]: dashboardQuotesTabs.newTab,
      [quoteStatuses.pausedStatus]: dashboardQuotesTabs.pausedTab,
      [quoteStatuses.quotedStatus]: dashboardQuotesTabs.quotedTab,
      [quoteStatuses.expiredStatus]: dashboardQuotesTabs.expiredTab,
      [quoteStatuses.acceptedStatus]: dashboardQuotesTabs.wonTab,
      [quoteStatuses.rejectedStatus]: dashboardQuotesTabs.rejectedTab,
    };
  }

  private route(notification: NotificationModel): void {
    const path: string = '/portal/dashboard';
    let queryParams: any;

    this.quotesService.getQuote(notification.queryParams.qid).then((quote: IQuote) => {

      queryParams = Object.assign({}, {
        tab: this.tabNameToQuoteStatusMap[quote.partner.status],
      }, notification.queryParams);

      this.router.navigate([path], { queryParams: queryParams });
      this.close();
    });
  }
}
