import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractControl, ValidationErrors } from '@angular/forms';

import { environment } from '@environments/environment';
import { RequestService } from '../services/request.service';

@Injectable()
export class EmailAsyncValidatorService {
  invalidEmail: ValidationErrors = {
    invalid: true,
    message: 'is invalid',
  } as ValidationErrors;

  invalidFormat: ValidationErrors = {
    format: true,
    message: 'has invalid format',
  } as ValidationErrors;

  emailExistError: ValidationErrors = {
    exist: true,
    message: 'exists',
  } as ValidationErrors;

  constructor(
    private requestService: RequestService,
  ) {
    this.validateEmail = this.validateEmail.bind(this);
    this.existEmail = this.existEmail.bind(this);
    this.validateEmailFormatOnly = this.validateEmailFormatOnly.bind(this);
  }

  public validateEmail(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    const email = control.value;
    const isFormatValid = this.checkEmailFormat(email);

    if (isFormatValid && environment.production) {
      return this.send(encodeURIComponent(email)).then((response: any): ValidationErrors | null =>
        response && response.valid ? null : this.invalidEmail)
        .catch(() => this.invalidEmail);
    } else {
      return new Promise((resolve: any): void => {
        resolve(isFormatValid ? null : this.invalidFormat);
      });
    }
  }

  public validateEmailFormatOnly(control: AbstractControl): Promise<ValidationErrors | null> {
    const email = control.value;
    const isFormatValid = this.checkEmailFormat(email);
    return new Promise((resolve: any): void => {
      resolve(isFormatValid ? null : this.invalidFormat);
    });
  }

  public existEmail(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    const email = control.value;
    return this.checkEmailExist(email);
  }

  public staticExistEmail(email: string): Promise<ValidationErrors | null> {
    return this.checkEmailExist(email);
  }

  private checkEmailExist(email: string): Promise<ValidationErrors | null> {
    const isFormatValid = this.checkEmailFormat(email);

    if (isFormatValid && environment.production) {
      return this.send(encodeURIComponent(email), 'exist')
        .then((): ValidationErrors | null | any => this.emailExistError)
        .catch(() =>  null);
    } else {
      return new Promise((resolve: any): void => {
        resolve(isFormatValid ? null : this.invalidFormat);
      });
    }
  }

  private checkEmailFormat(email: string): boolean {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
  }

  private send(email: string, ulr: string = 'verify'): Promise<any> {
    return this.requestService.promise('get', `/api/email/${ulr}?email=${email}`);
  }
}
