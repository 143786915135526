<div
  class='input-password {{customClass}}'
  [class.disabled]='control.disabled'
  [class.floating-label]='!fullBorder'
  [class.floating-label-new]='floatingLabel && fullBorder'
  [class.iconized-input]='iconized'>
  <i
    *ngIf='iconized'
    [class]='iconClass'
    (mouseover)='mouseover()'
    (mouseout)='mouseout()'
  ></i>
  <label *ngIf='showLabel'>{{label}}</label>
  <input
    [type]='inputType'
    [name]='name'
    [placeholder]='actualPlaceholder'
    [class.error]='isInvalid()'
    [class.success]='isValid()'
    [class.floating-input]='floatingLabel'
    [formControl]='control'
    (focus)='onFocus()'
    (blur)='onBlur()'
  />
  <span
    class='show-password-btn'
    (click)='toogleShowPassword()'>
    <i class='fas'
      [class.fa-eye]='!showPassword'
      [class.fa-eye-slash]='showPassword'
    ></i>
  </span>
  <label *ngIf='floatingLabel'>{{label}}</label>
</div>
<app-field-error
  [isInvalid]='isInvalid()'
  [errors]='control.errors'
  [label]='label'
></app-field-error>
