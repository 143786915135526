import {
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';

export class InputClass {
  @Input()
  public control!: FormControl;

  @Input()
  public name: string = this.newName;

  @Input()
  public type: string = 'text';

  @Input()
  public label: string = 'Field';

  @Input()
  public showLabel: boolean = false;

  @Input()
  public placeholder: string | boolean = false;

  @Input()
  public fullBorder: boolean = true;

  @Input()
  public floatingLabel: boolean = false;

  @Input()
  public iconized: boolean = false;

  @Input()
  public iconClass: string = '';

  @Input()
  public customClass: string = '';

  @Input()
  public rightIcon: boolean = false;

  @Input()
  public noLabel: boolean = false;

  @Input()
  public contrast: boolean = false;

  @Input()
  set disable(flag: boolean) {
    if (this.control) {
      flag ? this.control.disable() : this.control.enable();
      this._disable = flag;
    }
  }
  get disable(): boolean { return this._disable; }

  private _disable!: boolean;

  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();
  @Output() iconMouseover: EventEmitter<any> = new EventEmitter();
  @Output() iconMouseout: EventEmitter<any> = new EventEmitter();

  @ViewChild('input', { static: false })
  public inputElement!: ElementRef<any>;

  get isDisabled(): boolean {
    return this.control.disabled;
  }

  get actualPlaceholder(): string | boolean {
    return this.floatingLabel ? ' ' : this.placeholder;
  }

  get newName(): string {
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let text = '';

    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  public isValid(): boolean {
    return this.control.value && this.control.valid && !this.control.disabled;
  }

  public isInvalid(): boolean {
    return (this.control.touched || this.control.dirty) && !this.control.valid && !this.control.disabled;
  }

  public mouseover(): any {
    this.iconMouseover && this.iconMouseover.emit();
  }

  public mouseout(): any {
    this.iconMouseout && this.iconMouseout.emit();
  }

  public onFocus(): any {
    this.focus && this.focus.emit();
  }

  public onBlur(): any {
    this.blur && this.blur.emit();
  }
}
