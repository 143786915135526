<p *ngIf="isLoginError()"
  class="error"
  >
  <b>{{ errorMessage.message }}</b>
</p>
<form action='javascript:void(0)' [formGroup]='loginForm' >
  <div class='row'>
    <div class='col-sm-12'>
      <app-email-input
        label='Email'
        name='emailAddress'
        formControlName='emailAddress'
        ></app-email-input>
    </div>
    <div
      *ngIf='loginForm'
      class='col-sm-12'>
      <app-password-input
        label='Password'
        name='password'
        [control]='loginForm.controls.password'
        [floatingLabel]='true'
        ></app-password-input>
    </div>
  </div>
  <div class='row mt-2'>
    <div class='col-sm-12'>
      <a
        class='forgot-password'
        [routerLink]='["/forgot-password"]'
        (click)='hideLoginModal()'
        title='forgot password'
      >Forgot password?</a>
    </div>
    <div class='col-sm-12'>
      <app-button-loader
        [loading]='loading'
        [disabled]='!loginForm || !loginForm.valid'
        [customClasses]='"pink"'
        (buttonClick)='onSubmit()'
        [label]='"Login"'
      ></app-button-loader>
    </div>
  </div>
</form>