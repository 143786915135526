import { ICampaignItem } from '@interfaces';

export const ACTIVE_CAMPAIGN_STATUS = 'active';
export const PAUSED_CAMPAIGN_STATUS = 'paused';
export const INACTIVE_CAMPAIGN_STATUS = 'inactive';
export const PENDING_CAMPAIGN_STATUS = 'pending';

export const CAMPAIGNS_IDENTIFIERS: any = {
  HOME: 'home',
  AUTO: 'auto',
  BOAT: 'boat',
  MOTORCYCLE: 'motorcycle',
};

export const INSURANCE_CAMPAIGNS: ICampaignItem[] = [
  {
    name: CAMPAIGNS_IDENTIFIERS.AUTO,
    label: 'Auto',
    available: true,
    color: 'purple',
  },
  {
    name: CAMPAIGNS_IDENTIFIERS.HOME,
    label: 'Home',
    available: true,
    color: 'blue',
  },
  {
    name: CAMPAIGNS_IDENTIFIERS.BOAT,
    label: 'Boat',
    available: true,
    color: 'green',
  },
  {
    name: CAMPAIGNS_IDENTIFIERS.MOTORCYCLE,
    label: 'Motorcycle',
    available: true,
    color: 'turquoise',
  },
  {
    name: 'renters',
    label: 'Renters',
    available: false,
    color: 'yellow',
  },
  {
    name: 'condo',
    label: 'Condo',
    available: false,
    color: 'pink',
  },
];
