import { ControlValueAccessor, FormControl } from '@angular/forms';

export class ValueAccessorBase<T> implements ControlValueAccessor {
  private data: any = null;

  get value(): T {
    return this.data;
  }

  set value(value: T) {

    if (this.data !== value) {
      this.data = value;
      this.onChange(value);
    }
  }

  public writeValue(value: any): void {
    if (value) {
      this.data = value;
    }
  }

  // registers 'fn' that will be fired wheb changes are made
  // this is how we emit the changes back to the form
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public validate(_c: FormControl): any {

  }

  // not used, used for touch input
  public registerOnTouched(): void {}

  // change events from the textarea
  public onChange(event: any): void {
    let newValue;
    if (!event.target && event.value) {
      newValue = event.value;
    } else if (event.target) {
      newValue = event.target.value;
    } else {
      newValue = event;
    }
    this.data = newValue;

    // update the form
    this.propagateChange(this.data);
  }

  // the method set in registerOnChange to emit changes back to the form
  private propagateChange = (_: any): void => {};
}
