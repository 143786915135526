<form action='javascript:void(0)' [formGroup]='registerForm' *ngIf="stage == 'info'">
  <div class='row'>
    <div class='col-sm-12'>
      <app-text-input
        label='First name'
        name='firstName'
        [control]='registerForm.controls.firstName'
        [floatingLabel]='true'
        ></app-text-input>
    </div>
    <div class='col-sm-12'>
      <app-text-input
        label='Last name'
        name='lastName'
        [control]='registerForm.controls.lastName'
        [floatingLabel]='true'
        ></app-text-input>
    </div>
    <div class='col-sm-12'>
      <app-email-input
        label='Email'
        name='emailAddress'
        formControlName='emailAddress'
        ></app-email-input>
    </div>
    <div class='col-sm-12'>
      <app-tel-input
        label='Phone number'
        name='primaryPhone'
        formControlName='phoneNumber'
        ></app-tel-input>
    </div>
    <div class='col-sm-12'>
      <app-text-input
        label='Company name'
        name='companyName'
        [floatingLabel]='true'
        [control]='registerForm.controls.companyName'
        ></app-text-input>
    </div>
  </div>
  <div class='row'>
    <div class='col-sm-12'>
      <button
        [disabled]='!registerForm || !registerForm.valid || !registerForm.dirty'
        class='btn pink'
        (click)='onRegisterFormSubmit()'
        >Submit and Continue</button>
    </div>
  </div>
</form>
<form action='javascript:void(0)' [formGroup]='passwordForm' *ngIf="stage == 'password'">
  <div class='row'>
    <div class='col-sm-12'>
      <app-password-input
        label='Password'
        name='password'
        [control]='passwordForm.controls.password'
        [floatingLabel]='true'
        ></app-password-input>
    </div>
    <div class='col-sm-12 position-relative'>
      <app-password-input
        label='Verify Password'
        name='passwordConfirm'
        [control]='passwordForm.controls.passwordConfirm'
        [floatingLabel]='true'
        ></app-password-input>
      <app-field-error
        [isInvalid]='isPasswordFormInvalid()'
        [errors]='getErrors()'
        [label]='"Passwords"'
        ></app-field-error>
      <app-field-error
        [isInvalid]='isRegistrationError()'
        [errors]='errorMessage'
        [noLabel]='true'
        ></app-field-error>
    </div>
  </div>
  <div class='row mt-2'>
    <div class='col-sm-12'>
      <app-button-loader
        [loading]='loading'
        [disabled]='!passwordForm || !passwordForm.valid || !passwordForm.dirty'
        [customClasses]='"pink"'
        (buttonClick)='onPasswordSubmit()'
        [label]='"Create Account"'
      ></app-button-loader>
    </div>
  </div>
</form>
