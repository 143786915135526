export class GlobalModalTypes {
  // ============
  // MODAL TYPES
  // ============
  readonly REGULAR: string = 'REGULAR';
  readonly NOTIFY: string = 'NOTIFY';
  readonly CONFIRM: string = 'CONFIRM';

  // ============
  // MODAL TYPES
  // ============
  readonly CHECKICON: string = 'CHECKICON';
}
