/** Home choose component contains blocks of choose
 *
 */

import {
  Component,
} from '@angular/core';

import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-home-choose',
  templateUrl: './home-choose.component.html',
  styleUrls: [ './home-choose.component.scss' ],
})

export class HomeChooseComponent {

  public acknowledgedBrowserSupport!: boolean;

  constructor(
    private authService: AuthService,
  ) {
  }

  public showRegistrationModal(): void {
    if (this.authService.browserName !== 'IE' || this.authService.acknowledgedBrowserSupport) {
      this.authService.changeRegistrationModalVisibility(true);
    } else {
      this.authService.checkBrowser();
      this.authService.browserModalClosed.subscribe((value: any): void => {
        if (value) {
          this.authService.changeRegistrationModalVisibility(true);
        }
      });
    }
  }
}
