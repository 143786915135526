<div class="quote">
  <div class="container">
    <div class='overlay'></div>
    <div class="row quote-body">
      <div class="col-12 col-md-9">
        <p class='quote-text'>
          "This is such a no-brainer. I can't believe how much time we wasted chasing leads through other channels."
        </p>
        <div class='quote-author'>
          <p class='quote-author-name'>
            Shelly J.
          </p>
          <p class='quote-author-position'>
            Independant Agent.
          </p>
          <p class='quote-author-location'>
            Orlando. FL
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
