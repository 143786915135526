<div class="notification-dropdown" id="notification-dropdown">

  <div class="notification-header">
    <span class="triangle"></span>
    <span class="heading">Notifications</span>
    <button class='btn close-btn' (click)="close($event)">
      <i class="fas fa-times"></i>
    </button>
  </div>

  <div class="notification-body">
    <div *ngIf="isEmpty">
      <p class='empty-msg'>No notifications at this time</p>
    </div>

    <!-- messageNotifications - notifications.type == 'message' -->
    <div class="notification"
      (click)="handleNotificationClick(notification)"
      *ngFor="let notification of messagesNotifications"
      [class.read]="notification.read">

      <div class="notification-text">
          <span class="highlight">{{notification.message}}</span>
          <span class="date">&nbsp;{{fromNow(notification.receivedAt)}}</span>
      </div>
    </div>

    <!-- unreadQuoteRequestMessages - message.type == 'request' -->
    <div class='notification quote-request-message'
      *ngIf="unreadQuoteRequestMessages.length > 0"
      (click)="handleMultipleMessagesClick(unreadQuoteRequestMessages)">
      <div class="notification-text">
        <span class="highlight">You have {{unreadQuoteRequestMessages.length}} unread messages</span>
        <span class="date">&nbsp;{{fromNow(unreadQuoteRequestMessages[0].updatedAt)}}</span>
      </div>
    </div>

    <!-- unreadSubQuoteMessages - message.type == 'quote' grouped by subQuoteId -->
    <div *ngFor="let key of unreadSubQuoteMessagesKeys">
      <div *ngIf="key != 'undefined'">
        <div
          class='notification quote-message'
          (click)="handleMultipleMessagesClick(unreadSubQuoteMessages[key])">
          <div class="notification-text">
            <span class="highlight">({{unreadSubQuoteMessages[key].length}}) {{unreadSubQuoteMessages[key][0].message}}</span>
            <span class="id">&nbsp;on {{displayId(key)}}</span>
            <span class="date">&nbsp;{{fromNow(unreadSubQuoteMessages[key][0].updatedAt)}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
