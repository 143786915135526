/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../global-modals.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./global-confirm.component";
import * as i3 from "../../../common/services/global-modals.service";
var styles_GlobalConfirmComponent = [i0.styles];
var RenderType_GlobalConfirmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GlobalConfirmComponent, data: {} });
export { RenderType_GlobalConfirmComponent as RenderType_GlobalConfirmComponent };
export function View_GlobalConfirmComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-confirm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn pink"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["accept"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-outlined"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["cancel"]))], null, null); }
export function View_GlobalConfirmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-global-confirm", [], null, null, null, View_GlobalConfirmComponent_0, RenderType_GlobalConfirmComponent)), i1.ɵdid(1, 49152, null, 0, i2.GlobalConfirmComponent, [i3.GlobalModalService], null, null)], null, null); }
var GlobalConfirmComponentNgFactory = i1.ɵccf("app-global-confirm", i2.GlobalConfirmComponent, View_GlobalConfirmComponent_Host_0, {}, {}, []);
export { GlobalConfirmComponentNgFactory as GlobalConfirmComponentNgFactory };
