/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *  Registration component contains form to register in the application
 *

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./registration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./registration-form/registration-form.component.ngfactory";
import * as i3 from "./registration-form/registration-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../common/validators/email-async-validator.service";
import * as i6 from "../auth.service";
import * as i7 from "../../common/services/request.service";
import * as i8 from "@angular/router";
import * as i9 from "@angular/common";
import * as i10 from "../../shared/modal/modal.component.ngfactory";
import * as i11 from "../../shared/modal/modal.component";
import * as i12 from "./registration.component";
var styles_RegistrationComponent = [i0.styles];
var RenderType_RegistrationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegistrationComponent, data: {} });
export { RenderType_RegistrationComponent as RenderType_RegistrationComponent };
function View_RegistrationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "registration-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Access quality in-market consumers on our platform today! "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "registration-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Join our network "]))], null, null); }
function View_RegistrationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "registration-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Create your password "]))], null, null); }
function View_RegistrationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-registration-form", [], null, [[null, "stageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("stageChange" === en)) {
        var pd_0 = (_co.stageChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RegistrationFormComponent_0, i2.RenderType_RegistrationFormComponent)), i1.ɵdid(1, 245760, null, 0, i3.RegistrationFormComponent, [i4.FormBuilder, i5.EmailAsyncValidatorService, i6.AuthService, i7.RequestService], null, { stageChange: "stageChange" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_RegistrationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["class", "notice"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" By creating an account you are agreeing to our "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["href", "/static/terms"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Terms of use"]))], null, null); }
function View_RegistrationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "success-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Success "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "success-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your account is being created. "])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "col-md-6 offset-md-3 mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "a", [["class", "btn btn-block pink"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i8.RouterLinkWithHref, [i8.Router, i8.ActivatedRoute, i9.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(12, 1), (_l()(), i1.ɵted(-1, null, [" Get Started "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 12, 0, "questionnaire"); _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 11).target; var currVal_1 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_0, currVal_1); }); }
export function View_RegistrationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "app-modal", [["class", "registration"], ["noTitle", "true"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.hideRegistrationModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ModalComponent_0, i10.RenderType_ModalComponent)), i1.ɵdid(1, 49152, null, 0, i11.ModalComponent, [], { showCloseBtn: [0, "showCloseBtn"], noTitle: [1, "noTitle"] }, { close: "close" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RegistrationComponent_1)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RegistrationComponent_2)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RegistrationComponent_3)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RegistrationComponent_4)), i1.ɵdid(9, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RegistrationComponent_5)), i1.ɵdid(11, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isStage("info"); var currVal_1 = "true"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.isStage("info"); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.isStage("password"); _ck(_v, 5, 0, currVal_3); var currVal_4 = !_co.isStage("complete"); _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.isStage("password"); _ck(_v, 9, 0, currVal_5); var currVal_6 = _co.isStage("complete"); _ck(_v, 11, 0, currVal_6); }, null); }
export function View_RegistrationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-registration", [], null, null, null, View_RegistrationComponent_0, RenderType_RegistrationComponent)), i1.ɵdid(1, 114688, null, 0, i12.RegistrationComponent, [i6.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegistrationComponentNgFactory = i1.ɵccf("app-registration", i12.RegistrationComponent, View_RegistrationComponent_Host_0, {}, {}, []);
export { RegistrationComponentNgFactory as RegistrationComponentNgFactory };
