import { GlobalModalTypes } from '@constants';
import * as i0 from "@angular/core";
export class GlobalModalService extends GlobalModalTypes {
    constructor() {
        super();
        // managment
        this.type = this.REGULAR;
        this.iconType = null;
        this.show = false;
        // content
        this.title = null;
        this.description = null;
        // sideeffects
        this.callbacks = {
            confirm: null,
            close: null,
        };
    }
    setConfigs(options) {
        const { show, title, type, description, callbacks: { confirm, close, }, } = options;
        this.show = show;
        this.title = title;
        this.type = type;
        this.description = description;
        // Callbacks
        this.callbacks.confirm = confirm;
        this.callbacks.close = close;
        // Extended
        if (options.iconType) {
            this.iconType = options.iconType;
        }
    }
    toogleModal(confrimFlag) {
        this.show = !this.show;
        confrimFlag ? this.confirm() : this.close();
        if (!this.show) {
            this.reset();
        }
    }
    close() {
        this.callbacks.close && this.callbacks.close();
    }
    confirm() {
        this.callbacks.confirm && this.callbacks.confirm();
    }
    reset() {
        this.show = false;
        this.title = '';
        this.type = this.REGULAR;
        this.callbacks = {
            confirm: null,
            close: null,
        };
    }
}
GlobalModalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalModalService_Factory() { return new GlobalModalService(); }, token: GlobalModalService, providedIn: "root" });
