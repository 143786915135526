import {
  Injectable,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { CookieService } from 'ngx-cookie-service';
import {
  LocalStorage,
  SessionStorage,
} from 'ngx-webstorage';

@Injectable()
export class RequestService {

  private utmFields: Array<string> = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'tph_id'];

  @LocalStorage('buyer')
  private buyer: any;

  @SessionStorage('session-id')
  public sessionId: string = '';

  constructor(
    private httpClient: HttpClient,
    private cookie: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object,
    ) {}

  public handleError(errorResponse: any): void {
    let errorMessage = 'Oh dear, something went wrong';

    try {
      console.log('RequestService|errorResponse', {
        status: errorResponse.status,
        message: errorResponse.message,
        error: errorResponse.error.body,
        errorResponse: errorResponse,
      });

      errorMessage = errorResponse.error.body.error;
    } catch (e) {
      console.log('RequestService|errorResponse', errorResponse);
    }

    if (typeof alert !== 'undefined') {
      alert(errorMessage);
    }
  }

  public send(method: string, url: string, params: any = {}, headerOptions?: any): Observable<Object> {

    method = method.toLowerCase();

    let request: any;
    const headers: HttpHeaders = new HttpHeaders(this.buildHeaders(headerOptions));

    switch (method) {
      case 'get':
        request = this.httpClient.get(url, { params, headers });
        break;

      case 'post':
        request = this.httpClient.post(url, params, { headers });
        break;

      case 'patch':
        request = this.httpClient.patch(url, params, { headers });
        break;

      default:
        request = this.httpClient.request(method, url, { headers, ...params });
        break;
    }
    return request;
  }

  /*
   * https://angular.io/guide/http#reading-the-full-response
  */
  public request(method: string, url: string, options?: any, headerOptions?: any): Observable<Object> {

    method = method.toLowerCase();
    options = options || {};

    let request: Observable<Object>;
    const headers: HttpHeaders = new HttpHeaders(this.buildHeaders(headerOptions));

    request = this.httpClient.request(method, url, {...options, headers , observe: 'response'});

    return request;
  }

  /*
   * use for GET of responseType: 'blob'
  */
  public getBlob(url: string, options?: any, headerOptions?: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders(this.buildHeaders(headerOptions));
    return this.httpClient.get(url, { headers: headers, observe: 'response', params: options, responseType: 'blob' });
  }

  /*
   * use for GET of responseType: 'arraybuffer'
  */
  public getArrayBuffer(url: string, options?: any, headerOptions?: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders(this.buildHeaders(headerOptions));
    return this.httpClient.get(url, { headers: headers, observe: 'events', params: options, responseType: 'arraybuffer' });
  }

  /*  Send requests as promise
   *
   */
  public promise(method: string, url: string, options?: any, headerOptions?: any): Promise<any> {
    return this.send(method, url, options, headerOptions)
    .toPromise()
      .then((response: any) => {
        try {
          response = response.json();
        } catch (_e) {}

        return response;
      });
  }

  private buildHeaders(options?: any): any {

    let headers: any = {
      'x-sqz-client': 'squeeze-web',
    };

    if (options) {
      headers = {headers, ...options };
    }

    if (isPlatformBrowser(this.platformId)) {
      // include browser_uuid
      const browserUuid = localStorage.getItem('browser_uuid');

      if (browserUuid) {
        headers['x-sqz-browserid'] = browserUuid;
      }

      headers['x-sqz-sessionid'] = this.sessionId;

      const clientId = this.getGAClientId();

      if (clientId) {
        headers['x-sqz-gaclientid'] = clientId;
      }

      this.utmFields.forEach((key: string) => {
        const cookie = this.cookie.get(key);
        // correct tph id to what squeeze-auth expects
        if (key === 'tph_id') {
          key = 'tph-id';
        }

        if (cookie) {
          headers[`x-sqz-${key}`] = cookie;
        }
      });

    }

    if (this.buyer) {
      if (this.buyer.jwt) {
        headers['x-sqz-token'] = this.buyer.jwt;
      }
      if (this.buyer.account) {
        headers['x-sqz-userid'] = this.buyer.account.id;
      }
    }

    // IE hack for no chaching request
    headers['Cache-Control'] = 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0';
    headers.Pragma = 'no-cache';
    headers.Expires = '0';

    return headers;
  }

  private getGAClientId(): String {
    let id = this.cookie.get('_ga');
    if (id) {
      // omit the first two pieces; typically GA1.1.
      id = id.split('.').splice(2).join('.');
    }
    // console.log('client_id ', id);
    return id;
  }

}
