import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LoggingService } from './logging.service';

import { environment } from '@environments/environment';

import * as StackTrace from 'stacktrace-js';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
      private injector: Injector,
  ) { }

  handleError(error: any) {
    if (typeof window !== 'undefined') {
      // Don't try to send stacktraces of HTTP errors as they are already logged on the server
      // and they cause stacktrace-js to throw an exception.
      if (!error.url && !error.headers) {
        const loggingService = this.injector.get(LoggingService);
        const locationStrategy = this.injector.get(LocationStrategy);
        const message = error.message ? error.message : error.toString();
        let url: string;
        if (typeof window !== 'undefined') {
          url = location.origin + (locationStrategy instanceof PathLocationStrategy ? locationStrategy.path() : '') + ` (${environment.envName})`;
        } else {
          url = environment.app_domain + (locationStrategy instanceof PathLocationStrategy ? locationStrategy.path() : '') + ` (${environment.envName})`;
        }

        // get the stack trace, lets grab the last 10 stacks only
        StackTrace.fromError(error).then((stackframes) => {
          const stackString = stackframes.splice(0, 20).map( (sf: any) => sf.toString()).join('\n');

          // log to the server
          loggingService.log(message, url, stackString);
        });
        const errorString = error.toString();
        console.log('uncaught error', { error: errorString });
  
      } else {
        console.log('ERROR ' , error);
      }
    }
  }
}
