import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class CreditCardService {

  private url: string = '/api/creditcards'; // deprecated

  constructor(private requestService: RequestService) { }

  public setStripeToken(token: string): Promise<any> {
    return this.requestService.promise('patch', '/api/auth/buyers/stripe', {
      stripeToken: token,
    });
  }

  public getCreditCard(): Promise<any> {

    return this.requestService.promise('get', '/api/auth/buyer/card')
      .catch((errorResponse: any) => {
        if ('Unprocessable Entity' === errorResponse) {
          console.log('No default card found.');
        }
      });
  }

  public format(value: string, separator: string = ' '): string {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = matches && matches[0] || '';
    const parts = [];

    for (let i = 0; i < match.length; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
    return parts.join(separator);
    } else {
      return value;
    }
  }

  /* -----------            ------------ */
  /* ----------- DEPRECATED ------------ */
  /* -----------            ------------ */
  public verifyNumber(_cardNumber: string): Promise<any> {
    return this.requestService
      .promise('get', `${this.url}/validator/number`, {value: _cardNumber});
  }

  /* -----------            ------------ */
  /* ----------- DEPRECATED ------------ */
  /* -----------            ------------ */
  public verifyExpiration(_cardExpDate: string): Promise<any> {
    return this.requestService
      .promise('get', `${this.url}/validator/expiration-date`, {value: _cardExpDate});
  }

}
