/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../home.component.scss.shim.ngstyle";
import * as i1 from "./home-quote.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "./home-quote.component";
var styles_HomeQuoteComponent = [i0.styles, i1.styles];
var RenderType_HomeQuoteComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_HomeQuoteComponent, data: {} });
export { RenderType_HomeQuoteComponent as RenderType_HomeQuoteComponent };
export function View_HomeQuoteComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 13, "div", [["class", "quote"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 12, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 0, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 10, "div", [["class", "row quote-body"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 9, "div", [["class", "col-12 col-md-9"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 1, "p", [["class", "quote-text"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" \"This is such a no-brainer. I can't believe how much time we wasted chasing leads through other channels.\" "])), (_l()(), i2.ɵeld(7, 0, null, null, 6, "div", [["class", "quote-author"]], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 1, "p", [["class", "quote-author-name"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" Shelly J. "])), (_l()(), i2.ɵeld(10, 0, null, null, 1, "p", [["class", "quote-author-position"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" Independant Agent. "])), (_l()(), i2.ɵeld(12, 0, null, null, 1, "p", [["class", "quote-author-location"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" Orlando. FL "]))], null, null); }
export function View_HomeQuoteComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-home-quote", [], null, null, null, View_HomeQuoteComponent_0, RenderType_HomeQuoteComponent)), i2.ɵdid(1, 49152, null, 0, i3.HomeQuoteComponent, [], null, null)], null, null); }
var HomeQuoteComponentNgFactory = i2.ɵccf("app-home-quote", i3.HomeQuoteComponent, View_HomeQuoteComponent_Host_0, {}, {}, []);
export { HomeQuoteComponentNgFactory as HomeQuoteComponentNgFactory };
