import { AuthService } from './../auth/auth.service';
import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import {
  FormBuilder,
  Validators,
} from '@angular/forms';
import { CommonValidators } from '@common/validators/common-validators.service';
import { RequestService } from '@services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [ './reset-password.component.scss' ],
})
export class ResetPasswordComponent implements OnInit {
  public token: string | undefined;
  public loading: boolean | undefined;
  public message: string | undefined;
  public expired: boolean | undefined;
  public invalid: boolean | undefined;
  public password: string | undefined;
  public confirmPassword: string | undefined;
  public form!: FormGroup;
  public title: string = 'New Password';

  get passwordsValid(): boolean {
    return !!this.form && this.form.controls.password.valid && this.form.controls.confirmPassword.valid;
  }

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    public route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private request: RequestService,
    private authService: AuthService,
  ) {

    this.token = this.route.snapshot.queryParams.token;

    console.log('PATH', this.route.snapshot.url);
    if (this.route.snapshot.url[0].path.startsWith('set-pass')) {
      this.title = 'Set Password';
    }
    if (this.token && isPlatformBrowser(this._platformId)) {
      this.validateToken(this.token);
    } else {
      this.router.navigate(['/']);
    }

    this.createForm();
  }

  ngOnInit(): void {
  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      password: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
      confirmPassword: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
    }, {
      validator: CommonValidators.confirmPassword,
    });
  }

  public sendReset(): void {
    this.loading = true;

    this.request.send('post', `/api/auth/reset/${this.token}`, { password: this.form.controls.password.value}).subscribe(
      () => {
        this.loading = false;

        this.authService.changeLoginModalVisibility(true);
      },
      (errorResponse: any): any => {
        this.loading = false;

        this.showError(errorResponse.error);
      },
    );
  }

  private validateToken(token: string): void {
    this.loading = true;
    const path = `/api/auth/reset/validate/${token}`;
    this.request.send('get', path).subscribe(
      (resp: any) => {
        this.loading = false;
        const res = resp;
        if (res.error) {
          this.showError(res.error, true, res.expired);
        }
      },
      (errorResponse: any): any => {
        this.loading = false;

        this.showError(errorResponse.error);
      },
    );
  }

  private showError(error: any, invalid: boolean = false, expired: boolean = false): any {
    this.expired = expired;
    this.invalid = invalid;

    this.message = error ? error.error : expired ? 'Token expired' : 'Something went wrong';
  }
}
