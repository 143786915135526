import { HttpClient, } from '@angular/common/http';
import { environment } from '@environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class GoogleMapsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    geocode(address) {
        const url = 'https://maps.googleapis.com/maps/api/geocode/json';
        const params = {
            key: environment.google_api_key,
            address: address,
        };
        return this.httpClient.get(url, { params })
            .toPromise()
            .then((response) => {
            let results = [];
            if (response.status === 'OK') {
                const places = response.results;
                results = places.map((place) => {
                    this.parseAddresComponents(place.address_components);
                });
            }
            return results;
        });
    }
    parseAddresComponents(components) {
        const data = {
            street_number: '',
            street: '',
            state: '',
            postal_code: '',
            locality: '',
            country: '',
        };
        components.forEach((item) => {
            if (item.types.includes('street_number')) {
                data.street_number = item.short_name;
            }
            if (item.types.includes('route')) {
                data.street = item.short_name;
            }
            if (item.types.includes('administrative_area_level_1')) {
                data.state = item.short_name;
            }
            if (item.types.includes('postal_code')) {
                data.postal_code = item.short_name;
            }
            if (item.types.includes('locality')) {
                data.locality = item.short_name;
            }
            if (item.types.includes('country')) {
                data.country = item;
            }
        });
        return data;
    }
}
GoogleMapsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleMapsService_Factory() { return new GoogleMapsService(i0.ɵɵinject(i1.HttpClient)); }, token: GoogleMapsService, providedIn: "root" });
