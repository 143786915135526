import { GlobalModalService } from '../services/global-modals.service';
import { Injectable } from '@angular/core';
import { GlobalModalTypes } from '@constants';

@Injectable()
export class GlobalModalsClass extends GlobalModalTypes {

  get type(): string {
    return this.globalModalService.type;
  }

  get iconType(): string | null {
    return this.globalModalService.iconType;
  }

  get show (): boolean {
    return this.globalModalService.show;
  }

  get title(): string | null {
    return this.globalModalService.title;
  }

  get description(): string | null {
    return this.globalModalService.description;
  }

  constructor (
    public globalModalService: GlobalModalService,
  ) {
    super();
  }

  public toogleModal(): void {
    this.globalModalService.toogleModal();
  }
}
