/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *  Login form component contains form to register in the application
 *

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = [".forgot-password[_ngcontent-%COMP%]{cursor:pointer;float:right;font-weight:500;color:#3d4f6e;text-decoration:none;font-size:13px;padding:10px 20px}.error[_ngcontent-%COMP%]{color:#fc516a}"];
export { styles as styles };
