import { EventEmitter, } from '@angular/core';
import { PortalService } from './portal.service';
import * as i0 from "@angular/core";
import * as i1 from "./portal.service";
export class CampaignService {
    constructor(portalService) {
        this.portalService = portalService;
        // TODO: make model for this service and carriers.service
        // TODO: make interface for campaign and store
        this.ready = new EventEmitter();
        this.campaignName = 'insurances';
        this.campaignType = 'auto';
        this.store = {
            auto: [],
            home: [],
        };
    }
    get id() {
        if (!this.campaign) {
            return '';
        }
        return this.campaign._id;
    }
    get campaign() {
        return this.store[this.campaignType][this.store[this.campaignType].length - 1];
    }
    init(options) {
        this.setOptions(options);
        if (!this.store[this.campaignType].length) {
            this.fetchCampaign();
        }
        else {
            this.ready.emit(this.campaign);
        }
    }
    setOptions(options) {
        if (options) {
            this.campaignName = options.name;
            this.campaignType = options.type;
        }
    }
    fetchCampaign() {
        this.portalService.getCampaign(this.campaignName, this.campaignType)
            .then((campaign) => {
            this.store[this.campaignType].push(campaign);
            this.ready.emit(this.campaign);
        })
            .catch(this.handleError.bind(this));
    }
    handleError(errorResponse) {
        console.log('CampaignService|error', errorResponse);
    }
}
CampaignService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CampaignService_Factory() { return new CampaignService(i0.ɵɵinject(i1.PortalService)); }, token: CampaignService, providedIn: "root" });
