import { Component } from '@angular/core';
import { InputClass } from '@common/models/input.model';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: [
    '../../shared/form-components.scss',
    '../../shared/floating-label.scss',
    './password-input.component.scss',
  ],
})
export class PasswordInputComponent extends InputClass {

  public showPassword: boolean = false;
  public loading!: boolean;

  get inputType(): string {
    return this.showPassword ? 'text' : 'password';
  }

  constructor(
  ) {
    super();
  }

  toogleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }
}
