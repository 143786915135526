import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';

// components
import { AppComponent } from './app.component';

import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

// second class modules
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { HomeModule } from './home/home.module';
import { StaticModule } from './static/static.module';

// services
import { AuthService } from './auth/auth.service';
import { AuthGuardService as AuthGuard } from './common/services/auth-guard.service';
import { VersionCheckService } from './common/services/version-check.service';
import { GlobalErrorHandler } from '@common/services/error-handler.service';
import { LoggingService } from '@common/services/logging.service';

// third party modules
import { NgxWebstorageModule } from 'ngx-webstorage';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { BuyerMenuComponent } from './layout/header/buyer-menu/buyer-menu.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    NotFoundComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    BuyerMenuComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // Add .withServerTransition() to support Universal rendering.
    // The application ID can be any identifier which is unique on
    // the page.
    BrowserModule.withServerTransition({appId: 'squeeze-leads-web'}),
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AuthModule,
    HomeModule,
    StaticModule,
    DeviceDetectorModule.forRoot(),
  ],
  providers: [
    AuthService,
    AuthGuard,
    VersionCheckService,
    LoggingService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})

export class AppModule { }
