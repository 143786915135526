import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    public authService: AuthService,
    public router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve: any): void => {
      this.authService.isAuthenticated(route.queryParamMap!.get('_t'))
        .then((isAuthenticated: boolean): void => {
          if (!isAuthenticated) {
            this.router.navigate(['/']);
          }
          resolve(isAuthenticated);
        })
        .catch((_error: any): void => {
          this.router.navigate(['/']);
          resolve(false);
        });
    });
  }

}
