/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./buyer-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../common/directives/click-outside.directive";
import * as i5 from "./buyer-menu.component";
var styles_BuyerMenuComponent = [i0.styles];
var RenderType_BuyerMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BuyerMenuComponent, data: {} });
export { RenderType_BuyerMenuComponent as RenderType_BuyerMenuComponent };
function View_BuyerMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "buyer-menu-dropdown"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isVisibleBuyerMenu = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "buyer-logout-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "btn small btn-logout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Logout "])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "buyer-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "buyer-avatar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-user-circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "buyer-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "buyer-email"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵeld(11, 0, null, null, 9, "div", [["class", "buyer-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["title", "Profile"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" Profile "])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [["title", "Billing"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" Billing "])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "a", [["title", "Settings"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" Settings "]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.routes.profile; _ck(_v, 13, 0, currVal_4); var currVal_7 = _co.routes.billing; _ck(_v, 16, 0, currVal_7); var currVal_10 = _co.routes.settings; _ck(_v, 19, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fullName; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.emailAddress; _ck(_v, 10, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 13).target; var currVal_3 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 16).target; var currVal_6 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 19).target; var currVal_9 = i1.ɵnov(_v, 19).href; _ck(_v, 18, 0, currVal_8, currVal_9); }); }
export function View_BuyerMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["appClickOutside", ""], ["class", "buyer-menu-container"]], null, [[null, "clickOutside"], ["window", "touch"], ["document", "click"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:touch" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("clickOutside" === en)) {
        var pd_3 = ((_co.isVisibleBuyerMenu = false) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i4.ClickOutsideDirective, [i1.ElementRef], null, { clickOutside: "clickOutside" }), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "buyer-menu-toggle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isVisibleBuyerMenu = !_co.isVisibleBuyerMenu) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "buyer"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", "\u00A0"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "buyer-avatar fa fa-user-circle"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BuyerMenuComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isVisibleBuyerMenu; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fullName; _ck(_v, 4, 0, currVal_0); }); }
export function View_BuyerMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-buyer-menu", [], null, null, null, View_BuyerMenuComponent_0, RenderType_BuyerMenuComponent)), i1.ɵdid(1, 49152, null, 0, i5.BuyerMenuComponent, [], null, null)], null, null); }
var BuyerMenuComponentNgFactory = i1.ɵccf("app-buyer-menu", i5.BuyerMenuComponent, View_BuyerMenuComponent_Host_0, { account: "account" }, { clickLogout: "clickLogout" }, []);
export { BuyerMenuComponentNgFactory as BuyerMenuComponentNgFactory };
