/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *  Home component contains landing page of the application
 *

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/home-strategy/home-strategy.component.ngfactory";
import * as i3 from "./components/home-strategy/home-strategy.component";
import * as i4 from "../auth/auth.service";
import * as i5 from "./components/home-workflow/home-workflow.component.ngfactory";
import * as i6 from "./components/home-workflow/home-workflow.component";
import * as i7 from "./components/home-choose/home-choose.component.ngfactory";
import * as i8 from "./components/home-choose/home-choose.component";
import * as i9 from "./components/home-quote/home-quote.component.ngfactory";
import * as i10 from "./components/home-quote/home-quote.component";
import * as i11 from "../shared/as-seen-in/as-seen-in.component.ngfactory";
import * as i12 from "../shared/as-seen-in/as-seen-in.component";
import * as i13 from "./home.component";
import * as i14 from "@angular/router";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "home-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "home-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "home-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Stop Chasing Leads & Start Closing Customers "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "home-heading-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Participate in Our Auto Insurance Quote Marketplace to Save Time & Money for You and Your Customers "])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "home-join"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [["class", "home-join-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Start quoting qualified customers today! "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn round btn-signup pink"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showRegistrationModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Join our network "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-home-strategy", [["id", "whatWeDo"]], null, null, null, i2.View_HomeStrategyComponent_0, i2.RenderType_HomeStrategyComponent)), i1.ɵdid(13, 49152, null, 0, i3.HomeStrategyComponent, [i4.AuthService], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-home-workflow", [], null, null, null, i5.View_HomeWorkflowComponent_0, i5.RenderType_HomeWorkflowComponent)), i1.ɵdid(15, 49152, null, 0, i6.HomeWorkflowComponent, [], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-home-choose", [["id", "chooseUs"]], null, null, null, i7.View_HomeChooseComponent_0, i7.RenderType_HomeChooseComponent)), i1.ɵdid(17, 49152, null, 0, i8.HomeChooseComponent, [i4.AuthService], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-home-quote", [], null, null, null, i9.View_HomeQuoteComponent_0, i9.RenderType_HomeQuoteComponent)), i1.ɵdid(19, 49152, null, 0, i10.HomeQuoteComponent, [], null, null), (_l()(), i1.ɵeld(20, 0, null, null, 1, "app-as-seen-in", [], null, null, null, i11.View_AsSeenInComponent_0, i11.RenderType_AsSeenInComponent)), i1.ɵdid(21, 49152, null, 0, i12.AsSeenInComponent, [], { showTitle: [0, "showTitle"] }, null)], function (_ck, _v) { var currVal_0 = false; _ck(_v, 21, 0, currVal_0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 245760, null, 0, i13.HomeComponent, [i4.AuthService, i14.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i13.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
