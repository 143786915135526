export const quoteStatuses = {
  expiredStatus: 'expired',
  queuedStatus: 'queued',
  selectingStatus: 'selecting',
  rejectedStatus: 'rejected',
  quotedStatus: 'quoted',
  quotingStatus: 'quoting',
  acceptedStatus: 'accepted',
  pausedStatus: 'paused',
};

export const QUOTES_VERTICALS: any = [
  {
    name: 'Auto',
    value: 'auto_insurance',
    id: 'auto_insurance',
    status: true,
  },
  {
    name: 'Home',
    value: 'home_insurance',
    id: 'home_insurance',
    status: true,
  },
  {
    name: 'Renters',
    value: 'renters_insurance',
    id: 'renters_insurance',
    status: false,
  },
  {
    name: 'Condo',
    value: 'condo_insurance',
    id: 'condo_insurance',
    status: false,
  },
  {
    name: 'Business',
    value: 'business_insurance',
    id: 'business_insurance',
    status: false,
  },
  {
    name: 'Health',
    value: 'health_insurance',
    id: 'health_insurance',
    status: false,
  },
  {
    name: 'Motorcycle',
    value: 'motorcycle_insurance',
    id: 'motorcycle_insurance',
    status: true,
  },
  {
    name: 'Boat',
    value: 'boat_insurance',
    id: 'boat_insurance',
    status: true,
  },
  {
    name: 'All',
    value: '',
    id: 'all',
    status: true,
  },
];
