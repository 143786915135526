/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *  Home strategy component contains blocks of strategy
 *

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = [".home-strategy[_ngcontent-%COMP%]{padding-top:9em;padding-bottom:5em}@media only screen and (max-width:768px){.home-strategy[_ngcontent-%COMP%]{padding-top:3em;padding-bottom:3em}}.home-strategy[_ngcontent-%COMP%]   .title[_ngcontent-%COMP%]{font-size:30px;color:#222326;font-weight:700}.home-strategy[_ngcontent-%COMP%]   .home-strategy-block[_ngcontent-%COMP%]:not(:last-child){margin-bottom:3em}.home-strategy[_ngcontent-%COMP%]   .home-strategy-block[_ngcontent-%COMP%]   .description[_ngcontent-%COMP%]{font-size:16px;line-height:26px;color:#666980;text-align:left;margin-bottom:1.5em}.home-strategy[_ngcontent-%COMP%]   .home-strategy-block[_ngcontent-%COMP%]   .description[_ngcontent-%COMP%]:last-of-type{margin-bottom:0}.home-strategy[_ngcontent-%COMP%]   .home-strategy-block[_ngcontent-%COMP%]   .image[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{max-width:100%;max-height:100%}.home-strategy[_ngcontent-%COMP%]   .home-strategy-block[_ngcontent-%COMP%]   .list[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]{margin-bottom:25px;padding-left:13px;position:relative}.home-strategy[_ngcontent-%COMP%]   .home-strategy-block[_ngcontent-%COMP%]   .list[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]:after{content:'';width:6px;height:6px;background:#000;border-radius:100%;position:absolute;top:10px;left:0}.home-strategy-btn[_ngcontent-%COMP%]{margin:40px auto 0}"];
export { styles as styles };
