import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

// components
import { LoginComponent } from './login/login.component';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { RegistrationComponent } from './registration/registration.component';
import { RegistrationFormComponent } from './registration/registration-form/registration-form.component';

@NgModule({
  declarations: [
    LoginComponent,
    LoginFormComponent,
    RegistrationComponent,
    RegistrationFormComponent,
  ],
  imports: [
    RouterModule,
    SharedModule,
  ],
  exports: [
    LoginComponent,
    RegistrationComponent,
  ],
  providers: [
  ],
})

export class AuthModule {}
