<app-modal
  noTitle='true'
  class='registration'
  [showCloseBtn]='isStage("info")'
  (close)='hideRegistrationModal()'
  >
  <div *ngIf='isStage("info")'>
    <p class='registration-title' >
      Access quality in-market consumers on our platform today!
    </p>
    <div class='registration-subtitle'>
      <p>
        Join our network
      </p>
    </div>
  </div>
  <div *ngIf='isStage("password")'>
    <div class='registration-subtitle'>
      <p>
        Create your password
      </p>
    </div>
  </div>
  <app-registration-form
    *ngIf='!isStage("complete")'
    (stageChange)='stageChange($event)'
  ></app-registration-form>

  <div *ngIf='isStage("password")' class='mt-5'>
    <p class='notice'>
      By creating an account you are agreeing to our <a href='/static/terms' target='_blank'>Terms of use</a>
    </p>
  </div>

  <div *ngIf='isStage("complete")' class='mt-5'>
    <div class='text-center'>
      <div class='col-sm-12'>
        <i class='icon-success'></i>
        <p class='success-title'>
          Success
        </p>
        <p class='success-subtitle'>
          Your account is being created.
        </p>
      </div>
      <div class='col-md-6 offset-md-3 mt-5'>
        <div class="row">
          <a
            [routerLink]='["questionnaire"]'
            class='btn btn-block pink'>
            Get Started
          </a>
        </div>
      </div>
    </div>
  </div>
</app-modal>
