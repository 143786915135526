import { environment } from '@environments/environment';
import { RequestService } from './request.service';
import { SORT_NAMES } from '@constants';
import moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "./request.service";
export class QuotesService {
    constructor(requestService) {
        this.requestService = requestService;
        this.quotes = [];
        this.status = '';
        this.loading = false;
        this.optionsForLazyLoad = {
            limit: 10,
            offset: 0,
            totalCount: 0,
            uploaded: 0,
        };
        this.extraQuoteOptions = {
            loaded: false,
            id: undefined,
        };
    }
    get quotesForView() {
        return this.quotes;
    }
    get processStatus() {
        return this.loading;
    }
    init(status, extraQuoteId) {
        this.status = status;
        this.vertical = undefined;
        this.optionsForLazyLoad.limit = 10;
        this.optionsForLazyLoad.offset = 0;
        this.optionsForLazyLoad.totalCount = 0;
        this.optionsForLazyLoad.uploaded = 0;
        this.quotes = [];
        this.extraQuoteOptions.loaded = false;
        this.extraQuoteOptions.id = extraQuoteId;
        this.getSummary();
        return this.getQuotes();
    }
    getQuotes() {
        if (this.loading) {
            return new Promise(() => { });
        }
        this.loading = true;
        const options = {
            limit: this.optionsForLazyLoad.limit,
            include_details: true,
            offset: this.optionsForLazyLoad.offset,
            sortBy: 'partnerQuotes.partners.startedAt',
        };
        if (this.status) {
            options.status = this.status;
        }
        if (this.vertical) {
            options.vertical = this.vertical;
        }
        return this.getQuotesRequest(options)
            .then((response) => {
            const cloneDetecter = this.cloneDetecter(response.body.results);
            const findQuote = cloneDetecter.next().value; // find extra quote in download quote
            const quotesWithoutClone = cloneDetecter.next().value; // filter donwload quote if extra quote already uploaded
            const settedQuote = findQuote && !this.extraQuoteOptions.loaded ? response.body.results : quotesWithoutClone;
            // update lazyload settings
            this.optionsForLazyLoad.totalCount = response.body.totalCount;
            this.optionsForLazyLoad.uploaded += response.body.results.length;
            this.optionsForLazyLoad.offset += response.body.limit;
            // Grouped with saved quote, with\without extra quote
            this.quotes = [...this.quotes, ...settedQuote];
            if (this.extraQuoteOptions.id) {
                if (!this.extraQuoteOptions.loaded &&
                    !findQuote) { // Check extra quote upload and exist in last request
                    return this.getQuote(this.extraQuoteOptions.id).then((quote) => {
                        this.extraQuoteOptions.loaded = true;
                        this.optionsForLazyLoad.uploaded += 1;
                        this.quotes = [quote, ...this.quotes];
                    });
                }
                else {
                    this.extraQuoteOptions.loaded = true;
                }
            }
        })
            .finally(() => {
            this.loading = false;
        });
    }
    *cloneDetecter(quotes) {
        yield quotes.find((quote) => quote.id === this.extraQuoteOptions.id);
        yield quotes.filter((quote) => quote.id !== this.extraQuoteOptions.id);
    }
    getQuote(id) {
        return this.getQuoteRequest(id)
            .then(({ body }) => body)
            .finally(() => {
            this.loading = false;
        });
    }
    getSummary() {
        return this.getSummaryRequest()
            .then((response) => {
            this.summaryQuotesInfo = response;
        })
            .finally(() => {
            this.loading = false;
        });
    }
    uploadMore() {
        this.getQuotes();
    }
    sortQuotes(filterKey) {
        if (SORT_NAMES.newest === filterKey) {
            this.quotes = this.quotes.sort((first, second) => moment(second.details.createdAt).unix() - moment(first.details.createdAt).unix());
        }
        else if (SORT_NAMES.oldest === filterKey) {
            this.quotes = this.quotes.sort((first, second) => moment(first.details.createdAt).unix() - moment(second.details.createdAt).unix());
        }
    }
    uploadByType(vertical) {
        this.optionsForLazyLoad.limit = this.optionsForLazyLoad.uploaded !== 0 &&
            this.optionsForLazyLoad.uploaded >= 10 ? this.optionsForLazyLoad.uploaded : 10; // set limit , if before uploaded less then 10
        this.optionsForLazyLoad.offset = 0;
        this.optionsForLazyLoad.totalCount = 0;
        this.optionsForLazyLoad.uploaded = 0;
        this.quotes = [];
        this.vertical = vertical;
        this.extraQuoteOptions.loaded = false;
        this.extraQuoteOptions.id = undefined;
        this.getQuotes();
    }
    getQuotesRequest(options) {
        return this.requestService.promise('get', '/api/quotes', options)
            .catch(this.requestService.handleError.bind(this));
    }
    getQuoteRequest(id, options) {
        return this.requestService.promise('get', `/api/quotes/${id}`, options);
    }
    getSummaryRequest() {
        return this.requestService.promise('get', '/api/quotes/summary').then((response) => response.body)
            .catch(this.requestService.handleError.bind(this));
    }
    rejectQuoteRequest(id, options) {
        return this.requestService.promise('post', `/api/quotes/${id}/reject`, options)
            .catch(this.requestService.handleError.bind(this));
    }
    boundQuoteRequest(id, options) {
        return this.requestService.promise('post', `/api/quotes/${id}/bound`, options)
            .catch(this.requestService.handleError.bind(this));
    }
    deleteQuoteRequest(id, sid) {
        return this.requestService.promise('post', `/api/quotes/${id}/delete/${sid}`)
            .catch(this.requestService.handleError.bind(this));
    }
    // TODO make api for that
    uploadQuote(id, data, buyer, isUpdate = false, sid) {
        const headers = {
            'Accept': 'application/json',
            authorization: 'Bearer ' + buyer.jwt,
        };
        let apiUrl = environment.squeeze_quotes_api_url;
        if (typeof location !== 'undefined') {
            if (location.href.indexOf('localhost') > -1 || location.href.indexOf('stagin') > -1) {
                apiUrl = 'https://squeeze-quotes-staging.herokuapp.com/api/v1';
            }
        }
        const url = sid ? `${apiUrl}/quotes/${id}/quote/${sid}` : `${apiUrl}/quotes/${id}/quote`;
        const method = isUpdate ? 'patch' : 'post';
        return this.requestService.promise(method, url, data, headers)
            .catch(this.requestService.handleError.bind(this));
    }
    downloadFile(id, fileUrl, filetype = 'file') {
        return this.requestService.getArrayBuffer(`/api/quotes/${id}/${filetype}`, { fileUrl: fileUrl });
    }
    getConversations(options = {}) {
        /*
          options can be:
            conversationId optional  String
              Conversation id.
    
            quoteId optional  String
              quote request id.
    
            subQuoteId optional  String
              partner's quote id.
    
            minDate optional  String
              Date from (YYYY-MM-DD).
    
            maxDate optional  String
              Date to (YYYY-MM-DD).
    
            limit optional  Integer
              maximum number to return.
              Default value: 50
    
            next optional  String
              next page
    
            previous optional  String
              previous page
        */
        return this.requestService
            .send('get', '/api/conversations', options);
    }
    createConversation(quoteId, options = {}) {
        /*
          For a consumer, only 'quote' type conversations can be created and must include
          a subQuoteId provided by partner.
        */
        return this.requestService
            .promise('post', '/api/conversations', Object.assign({ quoteId: quoteId }, options));
    }
    addComment(conversationId, options = {}) {
        return this.requestService
            .promise('post', `/api/conversations/${conversationId}/comments`, options);
    }
}
QuotesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QuotesService_Factory() { return new QuotesService(i0.ɵɵinject(i1.RequestService)); }, token: QuotesService, providedIn: "root" });
