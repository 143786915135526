
<app-modal
*ngIf='showUnsupportedBrowserMessage'
[title]="'You are using ' + browserName"
[show]='showUnsupportedBrowserMessage'
[showCloseBtn]='true'
customClass=''
(close)="onCloseBrowserSupportModal()"
>
<div class='pt-4 pb-2 text-center'>
  <i class="fab fa-internet-explorer mb-3" style="font-size:4em;display:block;color:#2FB8CD;"></i>
  <p class='mb-1'>Hello there, it seems you are running a browser we don't fully support at this time.</p>
  <p>To get the most out of Squeeze Partners please consider using <b>Chrome</b>, <b>Edge</b> or <b>Safari</b> browsers.</p>
</div>
<div class='pt-2 pb-4'>
  <button class='btn blue uppercase' (click)="onCloseBrowserSupportModal()">
    Got it
  </button>
</div>
</app-modal>
<!-- Note: above code does not belong in this comopnent structure. Browser checks should be done on the modules that correspond. -->

<header
  class='header'
  [class.fixed]='navIsFixed'
  [class.show-mobile]='showNav'
  [class.logged-in]='otherStyleOfHeader'
  >
  <div class='header-inner'>
    <div class='header-main'>
      <a [routerLink]='getLink()' class='header-logo-link'>
        <span class='logo'>
          <i class='icon-squeeze-logo'></i>
          Squeeze
        </span>
      </a>
      <div class='mobile-menu' *ngIf='!isLoggedIn'>
        <button [hidden]='showNav' (click)='showNav = !showNav;'>
          <div class='icon-menu'></div>
          <span class='hidden'>Open menu</span>
        </button>
        <button [hidden]='!showNav' (click)='showNav = !showNav;'>
          <div class='icon-close'></div>
          <span class='hidden'>Close menu</span>
        </button>
      </div>
    </div>

    <nav class='header-nav'
      *ngIf='!isLoggedIn'
      [class.show-mobile]='showNav'>
      <div *ngFor='let link of headerLinks'
        class='nav-block'
        >
        <a
          [routerLink]='link.path'
          [fragment]='link.fragment'
          (click)='showNav = !showNav;'
          >
          {{link.label}}
        </a>
      </div>
    </nav>

    <div class='header-user-bar' [class.logged-in]='isLoggedIn'>
      <button
        *ngIf='!isLoggedIn'
        class='btn round btn-login'
        (click)='showLoginModal()'
        >
        Log in
      </button>

      <button
        *ngIf='!isLoggedIn'
        class='btn round btn-signup'
        (click)='showRegistrationModal()'
        >
        Join us
      </button>
      <div
        class='logged-user-bar'
        *ngIf='isLoggedIn'
        >

        <div class='logged-nav' appClickOutside (clickOutside)="showNotifications = false">
          <a
            routerLinkActive="active"
            [routerLink]='"/portal/dashboard"'
            (click)="showNotifications = false"
            title="Campaigns">
            <i class="fas fa-home"></i>
          </a>

          <a
            title="Notifications"
            (click)="toggleNotifications($event)">
            <i class="fas fa-bell"></i>
            <span *ngIf="notificationCount > 0"
              class='notification-count'>
              {{notificationCount}}
            </span>

            <app-notifications
              *ngIf="showNotifications"
              (closeEvent)="showNotifications = false; $event.stopPropagation();"
              [notifications]="notifications"
              [messages]="messages"
            ></app-notifications>
          </a>
        </div>

        <app-buyer-menu
          *ngIf="isLoggedIn"
          [account]="userAccount"
          (clickLogout)="logout()"
        ></app-buyer-menu>

      </div>
    </div>
  </div>

</header>
<app-registration *ngIf='isShowRegistrationModal'></app-registration>
<app-login *ngIf='isShowLoginModal'></app-login>
