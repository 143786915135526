import { Injectable } from '@angular/core';

declare const accounting: any;

@Injectable({
  providedIn: 'root',
})
export class AccountingService {

  constructor() {}

  public formatMoney(value: any, decimals: number = 0): any {
    if (typeof accounting === 'undefined') {
      return value;
    } else {
      return accounting.formatMoney(value, '$', decimals);
    }
  }

  public formatNumber(value: any, decimals: number = 0): any {
    if (typeof accounting === 'undefined') {
      return value;
    } else {
      return accounting.formatNumber(value, decimals);
    }
  }

  public parse(value: any): any {
    if (typeof accounting === 'undefined') {
      return value;
    } else {
      return accounting.parse(value);
    }
  }

  public unformat(value: any): any {
    if (typeof accounting === 'undefined') {
      return value;
    } else {
      return accounting.unformat(value);
    }
  }

}
