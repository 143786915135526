<div class='buyer-menu-container' appClickOutside (clickOutside)="isVisibleBuyerMenu = false">

  <div class="buyer-menu-toggle" (click)="isVisibleBuyerMenu = !isVisibleBuyerMenu">
    <span class='buyer'>{{ fullName }}&nbsp;</span>
    <i class="buyer-avatar fa fa-user-circle"></i>
  </div>

  <div class='buyer-menu-dropdown'
    *ngIf="isVisibleBuyerMenu"
    (click)='isVisibleBuyerMenu = false'>

    <div class='buyer-logout-container'>
      <button class='btn small btn-logout' (click)='onClickLogout()'>
        Logout
      </button>
    </div>

    <div class='buyer-info'>
      <div class='buyer-avatar'>
        <i class='fa fa-user-circle'></i>
      </div>

      <p class='buyer-name'>
        {{ fullName }}
      </p>

      <p class='buyer-email'>
        {{ emailAddress }}
      </p>
    </div>

    <div class='buyer-menu'>
      <a title="Profile" [routerLink]="routes.profile">
        Profile
      </a>

      <a title="Billing" [routerLink]="routes.billing">
        Billing
      </a>

      <a title="Settings" [routerLink]="routes.settings">
        Settings
      </a>
    </div>
  </div>

</div>