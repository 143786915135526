import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractControl, ValidationErrors } from '@angular/forms';

import { RequestService } from '../services/request.service';

@Injectable({
  providedIn: 'root',
})
export class CreditCardValidatorService {

  private url: string = '/api/creditcards/validator';
  private validateNumberResponse: any;

  invalid: ValidationErrors = {
    invalid: true,
    message: 'is invalid',
  } as ValidationErrors;

  invalidFormat: ValidationErrors = {
    format: true,
    message: 'has invalid format',
  } as ValidationErrors;

  invalidCardExpDate: ValidationErrors = {
    format: true,
    message: 'has invalid expiration',
  } as ValidationErrors;

  invalidCvc: ValidationErrors = {
    length: true,
    message: 'has invalid format',
  } as ValidationErrors;

  constructor(
    private requestService: RequestService,
  ) {
    this.validateNumberAsync = this.validateNumberAsync.bind(this);
    this.validateCardExpDateAsync = this.validateCardExpDateAsync.bind(this);
    this.validateCVC = this.validateCVC.bind(this);
  }

  public validateNumberAsync(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    const value = control.value;

    return this.verifyNumber(value)
      .then((response: any): ValidationErrors | null => {
        this.validateNumberResponse = response;
        return response && response.isValid ? null : this.invalid;
      })
      .catch(() => this.invalid);
  }

  public validateCVC(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!value) {
      return null;
    }

    let isFormatValid;

    try {
      isFormatValid = value.length === this.validateNumberResponse.card.code.size;
    } catch (e) {
      isFormatValid = false;
    }

    return isFormatValid ? null : this.invalidCvc;
  }

  public validateCardExpDateAsync(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    const value = control.value;

    return this.verifyExpiration(value)
      .then((response: any): ValidationErrors | null => response && response.isPotentiallyValid ? null : this.invalid)
      .catch(() => this.invalidCardExpDate);
  }

  private verifyNumber(cardNumber: string): Promise<any> {
    return this.requestService
      .promise('get', `${this.url}/number`, {value: cardNumber});
  }

  private verifyExpiration(cardExpDate: string): Promise<any> {
    return this.requestService
      .promise('get', `${this.url}/expiration-date`, {value: cardExpDate});
  }

}
