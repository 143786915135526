import { Injectable } from '@angular/core';

@Injectable()
export class GroupByService {

  groupBy(key: string, array: Array<any>): Array<any> {
    return array.reduce((objectsByKeyValue: any, obj: any) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
  }
}
