import { Component, Input, Output, EventEmitter } from '@angular/core';
import { buyerRoutes } from './buyer-menu.constants';
import { BuyerModel } from '@common/models';

@Component({
  selector: 'app-buyer-menu',
  templateUrl: './buyer-menu.component.html',
  styleUrls: ['./buyer-menu.component.scss']
})
export class BuyerMenuComponent {

  constructor() {
  }

  @Input() account: BuyerModel = new BuyerModel(null);

  @Output() clickLogout: EventEmitter<any> = new EventEmitter();

  public isVisibleBuyerMenu: boolean = false;
  public routes: any = buyerRoutes;

  get firstName(): string {
    return this.account.firstName;
  }

  get lastName(): string {
    return this.account.lastName;
  }

  get fullName(): string {
    return this.account.fullName;
  }

  get emailAddress(): string {
    return this.account.emailAddress;
  }

  public onClickLogout(): void {
    this.clickLogout.emit(this.account);
  }

}
