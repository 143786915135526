<div class="workflow">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-7 workflow-left">
        <iframe
          width="635"
          height="357"
          src="https://www.youtube.com/embed/z3px0NA4_8o?rel=0&amp;showinfo=0"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <div class="col-12 col-md-5 workflow-right">
        <p class='title'>How does it work?</p>
        <ol class='list'>
          <li>Consumer completes our auto insurance concierge service form</li>
          <li>A small group of select, matching brokers are charged a small participation fee and are notified of the opportunity and provided comprehensive quote request detail</li>
          <li>Brokers submit quotes for up to three carriers</li>
          <li>Consumer is notified by text / email that quotes are ready and logs in to their profile</li>
          <li>Consumer selects quote and broker is notified of selection</li>
        </ol>
      </div>
    </div>
  </div>
</div>
