import { Injectable } from '@angular/core';
import * as Rx from 'rxjs/Rx';
import { LocalStorage } from 'ngx-webstorage';

/* tslint:disable */
// TODO: should be update to 'new Observable' and 'new Subject' because 'create' is deprecated
@Injectable()
export class WebsocketService {

  constructor() {}

  @LocalStorage('buyer') private user: any;

  private subject: Rx.Subject<MessageEvent> | null = null;

  public connect(url: string): Rx.Subject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
      console.log('Successfully connected: ' + url);
    }
    return this.subject;
  }

  private create(url: string): Rx.Subject<MessageEvent> {

    const jwt = this.user.jwt;

    const ws = new WebSocket(url, ['access_token', jwt]);

    const observable = Rx.Observable.create((obs: Rx.Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });

    const observer = {
      next: (data: Object): void => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      },
    };

    return Rx.Subject.create(observer, observable);
  }
}
