<footer class='footer no-print'>
  <div class='gradient'></div>

  <section class='container'>
    <div class='row'>
      <div class='col-sm-7'>
        <div class='row'>
          <div class='col-sm-4'>
            <p class='footer-title'>
              Insurance
            </p>
            <ul class='footer-nav'>
              <li>
                <a rel='external nofollow' target='_blank' href='https://squeeze.com/insurance/auto'>Auto</a>
              </li>
              <li>
                <a rel='external nofollow' target='_blank' href='https://squeeze.com/insurance/home'>Home</a>
              </li>
            </ul>
          </div>
          <div class='col-sm-4'>
            <p class='footer-title'>
              Partners
            </p>
            <ul class='footer-nav'>
              <li>
                <a rel='external nofollow' target='_blank' href='https://squeeze.com/advertising-disclosure'>Advertising Disclosure</a>
              </li>
              <li>
                <a rel='external nofollow' target='_blank' href='https://squeeze.com/privacy'>Privacy Policy</a>
              </li>
              <li>
                <a rel='external nofollow' target='_blank' href='/static/terms'>Terms &amp; Conditions</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class='col-sm-5'>
        <div class='row justify-content-md-end justify-content-center'>
          <div class='social'>
            <p class='follow-us footer-title'>Follow Us</p>
            <ul>
              <li>
                <a href='https://twitter.com/asksqueeze' target='_blank' rel='nofollow' media='screen' charset='UTF-8'>
                  <div class='inner'>
                    <i class='icon-twitter'></i>
                    <span>twitter</span>
                  </div>
                </a>
              </li>
              <li>
                <a href='https://www.facebook.com/squeeze/' target='_blank' rel='nofollow' media='screen' charset='UTF-8'>
                  <div class='inner'>
                    <i class='icon-facebook'></i>
                    <span>facebook</span>
                  </div>
                </a>
              </li>
              <li>
                <a href='https://www.instagram.com/squeezedotcom/' target='_blank' rel='nofollow' media='screen' charset='UTF-8'>
                  <div class='inner'>
                    <i class='icon-instagram'></i>
                    <span>instagram</span>
                  </div>
                </a>
              </li>
              <li>
                <a href='https://www.pinterest.com/squeezedotcom/' target='_blank' rel='nofollow' media='screen' charset='UTF-8'>
                  <div class='inner'>
                    <i class='icon-pinterest'></i>
                    <span>pinterest</span>
                  </div>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/squeeze-llc/" target="_blank" rel='nofollow' media='screen' charset='UTF-8'>
                  <div class='inner'>
                    <i class="fab fa-linkedin-in"></i>
                    <span>linkedin</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class='row justify-content-md-end justify-content-center mt-4'>
          <p class='footer-title'>We accept</p>
          <span class='footer-accept-bg'></span>
        </div>
      </div>
    </div>
    <div class='row justify-content-start mt-5'>
      <div class='col-sm-6'>
        <div class='rights align-middle'>
          <img src='https://s3-us-west-2.amazonaws.com/squeeze-pics/assets/squeeze-icon-grey.png' alt='Squeeze.com' />
          <span id='copyright' class='text-center' property='dc:rights'>&copy;
            <span property='dc:dateCopyrighted'>{{currentYear}} </span>
            <span property='dc:publisher'>Squeeze.com</span>
          </span>
        </div>
      </div>
      <div class='col-sm-6 justify-content-end'>
        <div class='contact text-right align-middle'>
          <div class='email mb-1' >
            <a href='mailto:support@squeeze.com?Subject=Squeeze%20user' target='_top'>support@squeeze.com</a>
          </div>
          <div class='address'>
            901 NW 35th Street, Boca Raton 33431
          </div>
        </div>
      </div>
    </div>
  </section>
</footer>
