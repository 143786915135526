<div class="reset-container col-md-5">
  <div class="reset-header text-center">
    <h1 class="reset-title">{{ title }}</h1>
  </div>
  <div class="icon-sqz"></div>
  <div class="reset-body">
    <h4
      *ngIf="loading"
      class="loading text-center"
    >Sending...</h4>
    <p
      *ngIf="message"
      class="error text-center">
      <b>{{message}}</b>
    </p>
    <form
      class="form-reset"
      (submit)="sendReset()"
      *ngIf="!loading && !invalid">
        <app-password-input
          [control]="form.controls.password"
          label="Password"
          placeholder="Password"
          name="password"
          floatingLabel="true"
        ></app-password-input>
        <app-password-input
          [control]="form.controls.confirmPassword"
          label="Confirm password"
          placeholder="Confirm password"
          name="confirmPassword"
          floatingLabel="true"
        ></app-password-input>
        <app-field-error
          [isInvalid]="!form.valid && passwordsValid"
          [errors]="form.errors"
          label=""
        ></app-field-error>
      <button
        class="btn btn-lg btn-primary btn-block btn-signin pink"
        [disabled]="form.status !== 'VALID'"
        type="submit">
          Save Password
        </button>
    </form>
  </div>
</div>
<div class="contact-footer text-center">
  <p>support@squeeze.com</p>
  <p>901 NW 35th Street, Boca Raton 33431</p>
</div>
