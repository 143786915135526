import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// taken from https://blog.nodeswat.com/automagic-reload-for-clients-after-deploy-with-angular-4-8440c9fdd96c

@Injectable()
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    private currentHash: string = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    constructor(private http: HttpClient) {}

    /**
     * Checks in every set frequency the version of frontend application
     */
    public initVersionCheck (url: string = '/api/version', frequency: number = 1000 * 60 * 5): void {
        setInterval(() => {
            this.checkVersion(url);
        }, frequency);
    }

    /**
     * Will do the call and check if the hash has changed or not
     */
    private checkVersion (url: string = '/version'): void {
        // timestamp these requests to invalidate caches
        this.http.get(url + '?t=' + new Date().getTime())
            .subscribe(
                (response: any) => {
                    const hash = response.hash;
                    const hashChanged = this.hasHashChanged(this.currentHash, hash);

                    console.log('HASH', hash, 'Current', this.currentHash);

                    // If new version, do something
                    if (hashChanged) {
                        console.log('VERSION HASH HAS CHANGED!!');
                        alert('A new version of the app exists.  Please refresh your browser.');
                        // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                        // for an example: location.reload();
                    }

                    // store the new hash so we wouldn't trigger versionChange again
                    // only necessary in case you did not force refresh
                    // this.currentHash = hash; // DON'T SAVE ... keep reminding user about update.
                },
                (err: any) => {
                    console.error(err, 'Could not get version');
                },
            );
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     */
    private hasHashChanged (currentHash: string, newHash: string): boolean {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }

        return currentHash !== newHash;
    }
}
