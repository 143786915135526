import { Injectable } from '@angular/core';
import {
  Observable,
  of,
  Subject,
  BehaviorSubject,
} from 'rxjs';
import { RequestService } from '../common/services/request.service';
import {
  LocalStorage,
  LocalStorageService,
} from 'ngx-webstorage';
import { DEFAULT_LOGIN } from './auth.constants';
import { BuyerModel as Buyer } from '@models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private requestService: RequestService,
    private storage: LocalStorageService,
  ) {
    this.toggleRegistrationModal.subscribe((value: boolean): void => {
      this.showRegistrationModal = value;
    });
    this.toggleLoginModal.subscribe((value: boolean): void => {
      this.showLoginModal = value;
    });
  }

  public isLoggedIn: boolean = false;
  public showRegistrationModal: boolean = false;
  public toggleRegistrationModal: Subject<boolean> = new Subject<boolean>();
  public showLoginModal: boolean = false;
  public toggleLoginModal: Subject<boolean> = new Subject<boolean>();
  public browserModalClosed: Subject<boolean> = new Subject<boolean>();
  public showBrowserModal: boolean = false;
  public loginStatus: string = DEFAULT_LOGIN;
  public loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public browserName: any;
  public acknowledgedBrowserSupport!: boolean;
  public showUnsupportedModalObserv: Subject<boolean> = new Subject<boolean>();

  @LocalStorage('buyer') public buyer: any;
  @LocalStorage('partner') public partner: any;
  @LocalStorage('uploadedQuotesByRequest') public uploadedQuotesByRequest: any;
  @LocalStorage('uploadedFilesByRequest') public uploadedFilesByRequest: any;
  @LocalStorage('quoteFormValues') public quoteFormValues: any;

  get userAccount(): any {
    return new Buyer(this.buyer);
  }

  setLoggedIn(value: boolean): void {
    this.isLoggedIn = value;
    this.loggedIn$.next(value);
  }

  getLoggedIn(): Observable<boolean> {
    return this.loggedIn$.asObservable();
  }

  isShowRegistrationModal(): Observable<any> {
    return of(this.showRegistrationModal);
  }

  changeRegistrationModalVisibility(value: boolean): void {
    this.toggleRegistrationModal.next(value);
  }

  isShowLoginModal(): Observable<any> {
    return of(this.showLoginModal);
  }

  changeLoginModalVisibility(value: boolean): void {
    this.toggleLoginModal.next(value);
  }

  emitShowUnsupportedBrowserModal(val: boolean): void {
    this.showUnsupportedModalObserv.next(val);
  }

  public changeLoginStatus(status: string): void {
    this.loginStatus = status;
  }

  public isAuthenticated(token: string | null): Promise<any> {
    if ((this.buyer || {}).jwt || token) {

      return this.requestService.promise('post', '/api/auth/buyer', {token})
        .then((response: any): any => {
          // Update buyer account
          if (!this.buyer) {
            this.buyer = {jwt: token};
          }
          this.buyer.account = response;
          this.buyer = this.buyer; // force a save to local storage.
          this.setLoggedIn(true);
          return this.requestService.promise('get', '/api/partners');
        })
        .then((response: any): any => {
          let partner = response.body;
          if (typeof partner === 'string') {
            partner = JSON.parse(partner);
          }
          console.log('got partner', partner);
          this.partner = partner;
          return true;
        })
        .catch((_errorResponse: any) => {
          this.setLoggedIn(false);
          // JWT must be expired or invalid, clear buyer from local storage
          console.log('Error trying to authenticate. JWT must be expired or invalid');
          this.buyer = null;
          return false;
        });

    } else {
      return new Promise((resolve: any): void => {
        console.log('AUTH: no buyer found');
        this.setLoggedIn(false);
        resolve(false);
      });
    }
  }

  public logout(): Promise<any> {
    return this.requestService.promise('post', '/api/auth/logout', { token: this.buyer.jwt })
      .then((response: any): any => {
        this.storage.clear();

        // ngx-webstorage bug, releated with cache
        this.uploadedQuotesByRequest = {};
        this.uploadedFilesByRequest = {};
        this.quoteFormValues = {};

        this.setLoggedIn(false);
        return response;
      });
  }

  public login(buyerData: any): Promise<any> {
    return this.requestService.promise('post', '/api/auth/login', buyerData)
      .then((buyer: any): void => {
        this.buyer = buyer;
        this.setLoggedIn(true);
        return buyer;
      })
      .catch((errorResponse: any): void => {
        this.setLoggedIn(false);
        throw errorResponse;
      });
  }

  public checkBrowser(): void {
    if ('IE' === this.browserName &&
    !this.acknowledgedBrowserSupport) {
      this.emitShowUnsupportedBrowserModal(true);
    } else {
      this.emitShowUnsupportedBrowserModal(false);
    }
  }
}
