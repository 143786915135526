import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {
  ValidationErrors,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { RequestService } from '@common/services/request.service';
import { ICheckStatusResponse } from '@interfaces';

@Injectable()
export class PromocodeValidator {
  notAvailable: any = {
    invalid: true,
    data: '',
    message: 'not available',
  } as ValidationErrors;

  constructor(
    private requestService: RequestService,
  ) {
    this.async = this.async.bind(this);
  }

  public async(control: FormControl | AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    if (control.value) {
      return this.requestService.promise('get', `/api/quotes/promocodes/check/${encodeURIComponent(control.value)}`)
      .then(({ status }: ICheckStatusResponse): any => {
        if (status === 'not_found') {
          return this.notAvailable;
        }

        return null;
      });
    } else {
      return new Promise((): void => {});
    }
  }
}
