import { Component, OnInit } from '@angular/core';
import { RequestService } from '@services';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailAsyncValidatorService } from '@common/validators/email-async-validator.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})

export class ForgotPasswordComponent implements OnInit {
  public loading!: boolean;
  public message!: string;
  public form!: FormGroup;

  constructor(
    private request: RequestService,
    private router: Router,
    private emailAsyncValidator: EmailAsyncValidatorService,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      email: [null,
        Validators.compose([
          Validators.required,
        ]),
        this.emailAsyncValidator.validateEmail,
      ],
    });
  }

  public sendReset(): void {
    this.loading = true;
    this.request.send('post', '/api/auth/forgot', { emailAddress: this.form.value.email }).subscribe(
      (): any => {
        this.loading = false;
        this.message = 'Reset email was successfully sent. Please check your email.';
        setTimeout(() => this.close, 5000);
      },
      (errorResponse: any): any => {
        this.loading = false;
        this.message = errorResponse ? (errorResponse.error || 'Something went wrong') : 'Something went wrong';
      },
    );
  }

  public close(): void {
    this.router.navigate(['/']);
  }

}
