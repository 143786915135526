import { RequestService } from './request.service';
import * as i0 from "@angular/core";
import * as i1 from "./request.service";
export class CreditCardService {
    constructor(requestService) {
        this.requestService = requestService;
        this.url = '/api/creditcards'; // deprecated
    }
    setStripeToken(token) {
        return this.requestService.promise('patch', '/api/auth/buyers/stripe', {
            stripeToken: token,
        });
    }
    getCreditCard() {
        return this.requestService.promise('get', '/api/auth/buyer/card')
            .catch((errorResponse) => {
            if ('Unprocessable Entity' === errorResponse) {
                console.log('No default card found.');
            }
        });
    }
    format(value, separator = ' ') {
        const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        const matches = v.match(/\d{4,16}/g);
        const match = matches && matches[0] || '';
        const parts = [];
        for (let i = 0; i < match.length; i += 4) {
            parts.push(match.substring(i, i + 4));
        }
        if (parts.length) {
            return parts.join(separator);
        }
        else {
            return value;
        }
    }
    /* -----------            ------------ */
    /* ----------- DEPRECATED ------------ */
    /* -----------            ------------ */
    verifyNumber(_cardNumber) {
        return this.requestService
            .promise('get', `${this.url}/validator/number`, { value: _cardNumber });
    }
    /* -----------            ------------ */
    /* ----------- DEPRECATED ------------ */
    /* -----------            ------------ */
    verifyExpiration(_cardExpDate) {
        return this.requestService
            .promise('get', `${this.url}/validator/expiration-date`, { value: _cardExpDate });
    }
}
CreditCardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreditCardService_Factory() { return new CreditCardService(i0.ɵɵinject(i1.RequestService)); }, token: CreditCardService, providedIn: "root" });
