
<app-global-modals></app-global-modals>

<div class='site-wrapper'
  [class.fixed-nav]='navIsFixed'
  [class.common-page]='buyer && buyer.account'
  >

  <app-header *ngIf='useHeader' class='no-print' [navIsFixed]='navIsFixed' [browserName]='deviceInfo.browser'></app-header>

  <router-outlet></router-outlet>

</div>

<app-footer *ngIf="useFooter"></app-footer>
