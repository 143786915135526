import {
  Pipe,
  PipeTransform,
} from '@angular/core';

@Pipe({ name: 'parseBackendValue' })
export class ParseBackendValuePipe implements PipeTransform {

  constructor(
  ) {}

  transform(value: string): string {
    if (!value) {
      return 'None';
    }

    return value.replace(/_/ig, ' ');
  }
}
