/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form-loader.component";
var styles_FormLoaderComponent = [i0.styles];
var RenderType_FormLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormLoaderComponent, data: {} });
export { RenderType_FormLoaderComponent as RenderType_FormLoaderComponent };
function View_FormLoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "window-overlay"]], null, null, null, null, null))], null, null); }
export function View_FormLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormLoaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "loader-ring"]], [[2, "small", null], [2, "pink", null], [2, "static", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isGlobal; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isSmall; var currVal_2 = (_co.isGlobal || _co.pink); var currVal_3 = _co.static; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_FormLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-loader", [], null, null, null, View_FormLoaderComponent_0, RenderType_FormLoaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.FormLoaderComponent, [], null, null)], null, null); }
var FormLoaderComponentNgFactory = i1.ɵccf("app-form-loader", i3.FormLoaderComponent, View_FormLoaderComponent_Host_0, { isSmall: "isSmall", isGlobal: "isGlobal", pink: "pink", static: "static" }, {}, []);
export { FormLoaderComponentNgFactory as FormLoaderComponentNgFactory };
