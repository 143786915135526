var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { of, Subject, BehaviorSubject, } from 'rxjs';
import { RequestService } from '../common/services/request.service';
import { LocalStorage, LocalStorageService, } from 'ngx-webstorage';
import { DEFAULT_LOGIN } from './auth.constants';
import { BuyerModel as Buyer } from '@models';
import * as i0 from "@angular/core";
import * as i1 from "../common/services/request.service";
import * as i2 from "ngx-webstorage";
export class AuthService {
    constructor(requestService, storage) {
        this.requestService = requestService;
        this.storage = storage;
        this.isLoggedIn = false;
        this.showRegistrationModal = false;
        this.toggleRegistrationModal = new Subject();
        this.showLoginModal = false;
        this.toggleLoginModal = new Subject();
        this.browserModalClosed = new Subject();
        this.showBrowserModal = false;
        this.loginStatus = DEFAULT_LOGIN;
        this.loggedIn$ = new BehaviorSubject(false);
        this.showUnsupportedModalObserv = new Subject();
        this.toggleRegistrationModal.subscribe((value) => {
            this.showRegistrationModal = value;
        });
        this.toggleLoginModal.subscribe((value) => {
            this.showLoginModal = value;
        });
    }
    get userAccount() {
        return new Buyer(this.buyer);
    }
    setLoggedIn(value) {
        this.isLoggedIn = value;
        this.loggedIn$.next(value);
    }
    getLoggedIn() {
        return this.loggedIn$.asObservable();
    }
    isShowRegistrationModal() {
        return of(this.showRegistrationModal);
    }
    changeRegistrationModalVisibility(value) {
        this.toggleRegistrationModal.next(value);
    }
    isShowLoginModal() {
        return of(this.showLoginModal);
    }
    changeLoginModalVisibility(value) {
        this.toggleLoginModal.next(value);
    }
    emitShowUnsupportedBrowserModal(val) {
        this.showUnsupportedModalObserv.next(val);
    }
    changeLoginStatus(status) {
        this.loginStatus = status;
    }
    isAuthenticated(token) {
        if ((this.buyer || {}).jwt || token) {
            return this.requestService.promise('post', '/api/auth/buyer', { token })
                .then((response) => {
                // Update buyer account
                if (!this.buyer) {
                    this.buyer = { jwt: token };
                }
                this.buyer.account = response;
                this.buyer = this.buyer; // force a save to local storage.
                this.setLoggedIn(true);
                return this.requestService.promise('get', '/api/partners');
            })
                .then((response) => {
                let partner = response.body;
                if (typeof partner === 'string') {
                    partner = JSON.parse(partner);
                }
                console.log('got partner', partner);
                this.partner = partner;
                return true;
            })
                .catch((_errorResponse) => {
                this.setLoggedIn(false);
                // JWT must be expired or invalid, clear buyer from local storage
                console.log('Error trying to authenticate. JWT must be expired or invalid');
                this.buyer = null;
                return false;
            });
        }
        else {
            return new Promise((resolve) => {
                console.log('AUTH: no buyer found');
                this.setLoggedIn(false);
                resolve(false);
            });
        }
    }
    logout() {
        return this.requestService.promise('post', '/api/auth/logout', { token: this.buyer.jwt })
            .then((response) => {
            this.storage.clear();
            // ngx-webstorage bug, releated with cache
            this.uploadedQuotesByRequest = {};
            this.uploadedFilesByRequest = {};
            this.quoteFormValues = {};
            this.setLoggedIn(false);
            return response;
        });
    }
    login(buyerData) {
        return this.requestService.promise('post', '/api/auth/login', buyerData)
            .then((buyer) => {
            this.buyer = buyer;
            this.setLoggedIn(true);
            return buyer;
        })
            .catch((errorResponse) => {
            this.setLoggedIn(false);
            throw errorResponse;
        });
    }
    checkBrowser() {
        if ('IE' === this.browserName &&
            !this.acknowledgedBrowserSupport) {
            this.emitShowUnsupportedBrowserModal(true);
        }
        else {
            this.emitShowUnsupportedBrowserModal(false);
        }
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.RequestService), i0.ɵɵinject(i2.LocalStorageService)); }, token: AuthService, providedIn: "root" });
__decorate([
    LocalStorage('buyer'),
    __metadata("design:type", Object)
], AuthService.prototype, "buyer", void 0);
__decorate([
    LocalStorage('partner'),
    __metadata("design:type", Object)
], AuthService.prototype, "partner", void 0);
__decorate([
    LocalStorage('uploadedQuotesByRequest'),
    __metadata("design:type", Object)
], AuthService.prototype, "uploadedQuotesByRequest", void 0);
__decorate([
    LocalStorage('uploadedFilesByRequest'),
    __metadata("design:type", Object)
], AuthService.prototype, "uploadedFilesByRequest", void 0);
__decorate([
    LocalStorage('quoteFormValues'),
    __metadata("design:type", Object)
], AuthService.prototype, "quoteFormValues", void 0);
