/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../static.scss.shim.ngstyle";
import * as i1 from "./terms.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "./terms.component";
var styles_TermsComponent = [i0.styles, i1.styles];
var RenderType_TermsComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_TermsComponent, data: {} });
export { RenderType_TermsComponent as RenderType_TermsComponent };
export function View_TermsComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "div", [["class", "static-banner"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 0, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 2, "div", [["class", "static-block"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "h1", [["class", "static-heading"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" Partner Terms & Conditions "])), (_l()(), i2.ɵeld(5, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 1, "div", [["class", "col-md-10 offset-md-1 iframe-container"]], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 0, "iframe", [["height", "100%"], ["src", "https://docs.google.com/document/d/e/2PACX-1vSHmgObQHrzguYsPHVjj9uYuYzdDbZ4ExCAJUU3IlPokZGfFPZz2rfhoSfrFPHr-XXHnOp6-c6H3kP2/pub?embedded=true"], ["width", "100%"]], null, null, null, null, null))], null, null); }
export function View_TermsComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-terms", [], null, null, null, View_TermsComponent_0, RenderType_TermsComponent)), i2.ɵdid(1, 114688, null, 0, i3.TermsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermsComponentNgFactory = i2.ɵccf("app-terms", i3.TermsComponent, View_TermsComponent_Host_0, {}, {}, []);
export { TermsComponentNgFactory as TermsComponentNgFactory };
