import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: [
    '../static.scss',
    './terms.component.scss',
  ],
})
export class TermsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
