import {
  Component,
  Inject,
  PLATFORM_ID,
  HostListener,
  OnInit,
} from '@angular/core';
import {
  isPlatformBrowser,
  isPlatformServer,
  DOCUMENT,
} from '@angular/common';
import {
  LocalStorage,
  SessionStorage,
} from 'ngx-webstorage';
import { GuidService } from './common/services/guid.service';
import { VersionCheckService } from './common/services/version-check.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {

  private scrollPos: number;

  public navIsFixed: boolean;
  public deviceInfo: any | null = null;

  @LocalStorage('buyer')
  public buyer: any;

  @SessionStorage('session-id')
  public sessionId: string = '';

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (isPlatformBrowser(this._platformId) && typeof window !== 'undefined') {
      this.scrollPos = window.pageYOffset ||
        (this.document && this.document.documentElement && this.document.documentElement.scrollTop) ||
        this.document.body.scrollTop || 0;

      this.navIsFixed = this.scrollPos > 100;
    }
  }

  get useHeader(): boolean {
    return !this.location.path().startsWith('/closers');
  }

  get useFooter(): boolean {
    return this.location.path().indexOf('dashboard') === -1;
  }

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private _guid: GuidService,
    private _device: DeviceDetectorService,
    private _versionCheck: VersionCheckService,
    private location: Location,
  ) {

    this.scrollPos = 0;
    this.navIsFixed = false;

    if (isPlatformServer(_platformId)) {
      // silence console on the server
      // console.log = (): void => {};
      console.error = (): void => {};
      console.warn = (): void => {};
    } else {
      this._versionCheck.initVersionCheck();

      /* create a session id if not set */
      if (!this.sessionId) {
        this.sessionId = this._guid.guid();
        console.log('New session created', this.sessionId);
      } else {
        console.log('Existing sessionId', this.sessionId);
      }

      this.deviceInfo = this._device.getDeviceInfo();
      /*
        https://koderlabs.github.io/ngx-device-detector/miscellaneous/variables.html

        deviceInfo.browser
          {
            CHROME: 'Chrome',
            FIREFOX: 'Firefox',
            SAFARI: 'Safari',
            OPERA: 'Opera',
            IE: 'IE',
            MS_EDGE: 'MS-Edge',
            FB_MESSANGER: 'FB-Messanger',
            SAMSUNG: 'Samsung',
            UCBROWSER: 'UC-Browser',
            UNKNOWN: 'Unknown'
          }
      */
      console.log('Device info', this.deviceInfo);
    }
  }

  ngOnInit(): void {
    if (typeof window !== 'undefined') {
      window.addEventListener('storage', (event: any) => {
        if (event.key === null) {
          window.location.href = '/';
        }
      });
    }
  }
}
