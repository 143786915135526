/** Home component contains landing page of the application
 *
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit, OnDestroy, } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { AuthService } from '../auth/auth.service';
export class HomeComponent {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
        this.isShowRegistrationModal = false;
        this.isShowLoginModal = false;
    }
    ngOnInit() {
        if (this.buyer && this.buyer.account) {
            return this.router.navigate(['/portal/dashboard']);
        }
    }
    ngOnDestroy() {
        this.authService.changeRegistrationModalVisibility(false);
    }
    // TODO: make class with this functionality, and extend components
    showRegistrationModal() {
        if (this.authService.browserName !== 'IE' || this.authService.acknowledgedBrowserSupport) {
            this.authService.changeRegistrationModalVisibility(true);
        }
        else {
            this.authService.checkBrowser();
            this.authService.browserModalClosed.subscribe((value) => {
                if (value) {
                    this.authService.changeRegistrationModalVisibility(true);
                }
            });
        }
    }
}
__decorate([
    LocalStorage('buyer'),
    __metadata("design:type", Object)
], HomeComponent.prototype, "buyer", void 0);
