export const SORT_OPTIONS = [
  {
    name: 'Newest',
    value: 'newest',
    iconClass: null,
    selected: true,
  },
  {
    name: 'Oldest',
    value: 'oldest',
    iconClass: null,
    selected: false,
  },
];

export const SORT_NAMES = {
  newest: 'newest',
  oldest: 'oldest',
};
