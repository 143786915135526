import { Injectable } from '@angular/core';

@Injectable()
export class SupportService {

  constructor() { }

  public checkIE(): boolean | void {
    if (!window) {
      return;
    }

    const msie = window.navigator.userAgent.indexOf('MSIE ');
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      return true;
    }
  }
}
