<!-- top banner block -->
<div class='static-banner'>
  <div class='overlay'></div>
  <div class='static-block'>
    <h1 class='static-heading'>
      Partner Terms & Conditions
    </h1>
    <!-- <p class='static-heading-subtitle'>
    </p> -->
  </div>
</div>

<!-- container -->
<div class='container'>
  <div class='row'>

    <div class='col-md-10 offset-md-1 iframe-container'>

      <iframe 
        width="100%"
        height="100%"
        src="https://docs.google.com/document/d/e/2PACX-1vSHmgObQHrzguYsPHVjj9uYuYzdDbZ4ExCAJUU3IlPokZGfFPZz2rfhoSfrFPHr-XXHnOp6-c6H3kP2/pub?embedded=true"
      ></iframe>

    </div>

  </div>
</div>


