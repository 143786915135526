export const BOAT_TYPES: { [k: string]: string } = {
  airboat: 'Airboat',
  aluminum_jet: 'Aluminium Jet',
  aluminum_runabout: 'Aluminium Runabout',
  amphibious: 'Amphibious',
  bass: 'Bass',
  bay: 'Bay',
  bow_rider: 'Bow Rider',
  canoe_dinghy_kayak: 'Canoe Dinghy Kayak',
  dinghy_runabout: 'Dinghy Runabout',
  center_console: 'Center Console',
  express_sedan: 'Express Sedan',
  houseboat: 'Houseboat',
  flats: 'Flats',
  jet: 'Jet',
  jon_utility: 'Jon Utility',
  motor_yacht: 'Motor Yacht',
  performance_catamaran: 'Performance Catamaran',
  performance_runabout: 'Performance Runabout',
  pontoon: 'Pontoon',
  sail: 'Sail',
  ski_wake: 'Ski Wake',
  sportfish_cruiser: 'Sportfish Cruiser',
  tunnel_hull: 'Tunnel Hull',
  walkaround_cuddy: 'Walkaround Cuddy',
};

export const HULL_MATERIAL: { [k: string]: string } = {
  fiberglass: 'Fiberglass',
  aluminum: 'Aluminum',
  wood: 'Wood',
  inflatable: 'Inflatable',
  other: 'Other',
};

export const PROPULSION_TYPES: { [k: string]: string } = {
  jet: 'Jet',
  outboard: 'Outboard',
};
