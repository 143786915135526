import { environment } from '@environments/environment';
import { RequestService } from './request.service';
import * as i0 from "@angular/core";
import * as i1 from "./request.service";
export class LoggingService {
    constructor(request) {
        this.request = request;
    }
    log(message, url, stackString) {
        const isLocalHost = typeof location !== 'undefined' && location.hostname.indexOf('localhost') > -1;
        if (environment.production && !isLocalHost) {
            this.request.send('post', `${environment.squeeze_internal_api_url}/logger/log`, {
                message: message,
                url: url,
                stack: stackString
            }).subscribe();
        }
    }
}
LoggingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggingService_Factory() { return new LoggingService(i0.ɵɵinject(i1.RequestService)); }, token: LoggingService, providedIn: "root" });
