/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *  Login component contains form to register in the application
 *

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../shared/modal/modal.component.ngfactory";
import * as i5 from "../../shared/modal/modal.component";
import * as i6 from "./login-form/login-form.component.ngfactory";
import * as i7 from "./login-form/login-form.component";
import * as i8 from "@angular/forms";
import * as i9 from "../../common/validators/email-async-validator.service";
import * as i10 from "../auth.service";
import * as i11 from "./login.component";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
function View_LoginComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This email address is already registered with an account."])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please sign in below, or "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["title", "Join Us"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.goToRegister() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 1), (_l()(), i1.ɵted(-1, null, ["register a new account"])), (_l()(), i1.ɵted(-1, null, [" with a different email address."]))], function (_ck, _v) { var currVal_2 = _ck(_v, 7, 0, "/"); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).target; var currVal_1 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_0, currVal_1); }); }
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-modal", [["title", "Log in"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.hideLoginModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ModalComponent_0, i4.RenderType_ModalComponent)), i1.ɵdid(1, 49152, null, 0, i5.ModalComponent, [], { title: [0, "title"] }, { close: "close" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_LoginComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "app-login-form", [], null, null, null, i6.View_LoginFormComponent_0, i6.RenderType_LoginFormComponent)), i1.ɵdid(5, 245760, null, 0, i7.LoginFormComponent, [i8.FormBuilder, i9.EmailAsyncValidatorService, i10.AuthService, i2.Router], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Log in"; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.currentStatus === _co.alreadyExistStatus); _ck(_v, 3, 0, currVal_1); _ck(_v, 5, 0); }, null); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i11.LoginComponent, [i10.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i11.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
