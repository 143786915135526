import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-form-loader',
  templateUrl: './form-loader.component.html',
  styleUrls: [ './form-loader.component.scss' ],
})

export class FormLoaderComponent {

  @Input() isSmall: boolean = false;
  @Input() isGlobal: boolean = false;
  @Input() pink: boolean = false;
  @Input() static: boolean = false;

  constructor(
  ) {
  }

}
