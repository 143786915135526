import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AuthModule } from '../auth/auth.module';

// components
import { HomeComponent } from './home.component';
import { HomeStrategyComponent } from './components/home-strategy/home-strategy.component';
import { HomeChooseComponent } from './components/home-choose/home-choose.component';
import { HomeOfferingsComponent } from './components/home-offerings/home-offerings.component';
import { HomeTestimonialsComponent } from './components/home-testimonials/home-testimonials.component';
import { HomeContactComponent } from './components/home-contact/home-contact.component';
import { HomeWorkflowComponent } from './components/home-workflow/home-workflow.component';
import { HomeQuoteComponent } from './components/home-quote/home-quote.component';

@NgModule({
  declarations: [
    HomeComponent,
    HomeStrategyComponent,
    HomeChooseComponent,
    HomeOfferingsComponent,
    HomeTestimonialsComponent,
    HomeContactComponent,
    HomeWorkflowComponent,
    HomeQuoteComponent,
  ],
  imports: [
    SharedModule,
    AuthModule,
  ],
  exports: [
    HomeComponent,
    HomeStrategyComponent,
    HomeChooseComponent,
    HomeOfferingsComponent,
    HomeTestimonialsComponent,
    HomeContactComponent,
    HomeWorkflowComponent,
    HomeQuoteComponent,
  ],
  providers: [
  ],
})

export class HomeModule { }
