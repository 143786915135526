var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { RequestService } from './request.service';
import { LocalStorage } from 'ngx-webstorage';
import { environment } from '@environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./request.service";
export class PortalService {
    constructor(requestService) {
        this.requestService = requestService;
    }
    checkCampaignType(type) {
        // adjust to API route
        // insurance vs. insurances
        if (type == 'insurance') {
            type = 'insurances';
        }
        return type;
    }
    getPartners(options) {
        return this.requestService.promise('get', '/api/partners', options)
            .then((response) => {
            this.partner = response;
            return true;
        })
            .catch((_errorResponse) => {
            console.log('getPartners', _errorResponse);
            return false;
        });
    }
    updatePartnerProfile(options) {
        if (environment.production) {
            if ((this.buyer || {}).jwt) {
                return this.requestService.promise('patch', '/api/partners', options)
                    .then((response) => {
                    this.partner = response.body;
                    return response;
                })
                    .catch((_errorResponse) => {
                    console.log('updatePartnerProfile', _errorResponse);
                    return null;
                });
            }
            else {
                return new Promise((resolve) => {
                    console.log('PORTAL: no buyer found');
                    resolve(false);
                });
            }
        }
        else {
            return new Promise((resolve) => {
                resolve(true);
            });
        }
    }
    getCampaign(type, name, createFlag = true) {
        if (!environment.production) {
            return this.fakeCampaignResponse();
        }
        // adjust type param to api routes
        type = this.checkCampaignType(type);
        /* Encapsulate get or create a campaign for partner */
        return this.requestService
            .promise('get', `/api/filters/${type}/${name}`, { limit: 1 })
            .then((response) => {
            /* assuming now there's one campaign per partner account */
            const campaign = response.body.results[0];
            if (typeof campaign === 'undefined' && createFlag) {
                console.log('no campaign found for this partner.');
                return this.createCampaign(type, name);
            }
            else if (typeof campaign === 'undefined') {
                return campaign;
            }
            else {
                campaign.type = type;
                campaign.name = name;
                return campaign;
            }
        }).catch(this.requestService.handleError.bind(this));
    }
    createCampaign(type, name) {
        // adjust type param to api routes
        type = this.checkCampaignType(type);
        return this.requestService
            .promise('post', `/api/filters/${type}/${name}`, {})
            .then((response) => {
            console.log('new campaign created.');
            response.body.type = type;
            response.body.name = name;
            return response.body;
        })
            .catch(this.requestService.handleError.bind(this));
    }
    updateCampaign(type, name, id, filter) {
        if (!environment.production) {
            return this.fakeCampaignResponse();
        }
        // adjust type param to api routes
        type = this.checkCampaignType(type);
        return this.requestService
            .promise('patch', `/api/filters/${type}/${name}/${id}`, filter)
            .then((response) => {
            response.body.type = type;
            response.body.name = name;
            return response.body;
        })
            .catch(this.requestService.handleError.bind(this));
    }
    updateCampaignStatus(id, options, campaignName = 'auto') {
        if (!environment.production) {
            return this.fakeCampaignResponse();
        }
        return this.requestService
            .promise('patch', `/api/filters/insurances/${campaignName}/${id}/status`, options)
            .then((response) => response.body)
            .catch(this.requestService.handleError.bind(this));
    }
    fakeCampaignResponse() {
        return new Promise((resolve) => {
            resolve({
                '_id': '5c6ef1e27da6810030df1688',
                'secondaryCarriers': ['21st Century', 'GEICO'],
                'states': ['AL', 'AK', 'DE', 'IN', 'OR'],
                'quoteOnWeekends': true,
                'accountId': '5c6dbb4ce18f510030274603',
                'status': 'pending',
                'primaryCarrier': 'AIG',
                'maxQuotesPerDay': 10,
                'createdAt': '2019-02-21T18:45:54.845Z',
                'updatedAt': '2019-02-23T22:19:47.149Z',
            });
        });
    }
}
PortalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PortalService_Factory() { return new PortalService(i0.ɵɵinject(i1.RequestService)); }, token: PortalService, providedIn: "root" });
__decorate([
    LocalStorage('buyer'),
    __metadata("design:type", Object)
], PortalService.prototype, "buyer", void 0);
__decorate([
    LocalStorage('partner'),
    __metadata("design:type", Object)
], PortalService.prototype, "partner", void 0);
