import {
  Component,
  Input,
  forwardRef,
  ViewChild,
  ElementRef,
  OnChanges,
  OnInit,
  Optional,
  Host,
  SkipSelf,
} from '@angular/core';

import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  AbstractControl,
  ControlValueAccessor,
  ControlContainer,
} from '@angular/forms';

import { ValueAccessorBase } from '../../common/models/value-accessor.model';

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: [ '../form-components.scss' ],
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailInputComponent),
      multi: true,
    },
  ],
})

export class EmailInputComponent extends ValueAccessorBase<string> implements ControlValueAccessor, OnInit, OnChanges {

  @Input() formControlName: string = '';
  @Input() placeholder: string = ' ';
  @Input() label: string = 'Field';
  @Input() name: string = 'name';
  @Input() minLength: number = 0;
  @Input() maxLength: number = 10000000;
  @Input() iconized: boolean = false;
  @Input() iconClass: string = '';
  @Input() floatingLabel: boolean = true;
  @Input() showLabel: boolean = false;
  @Input() fullBorder: boolean = true;

  @ViewChild('input', { static: true }) input: ElementRef | null = null;

  private control: AbstractControl | null = null;

  get actualPlaceholder(): string {
    return this.floatingLabel ? ' ' : this.placeholder;
  }

  constructor(
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.controlContainer && this.controlContainer.control) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName);
      } else {
        console.warn('Missing FormControlName directive from host element of the component');
      }
    } else {
      console.warn('Can\'t find parent FormGroup directive');
    }
  }

  ngOnChanges(): void {
    if (!this.control || !this.input) {
      return;
    }

    this.input.nativeElement.value = this.control.value;
  }

  public isInvalid(): boolean {
    return this.control &&
      ((this.control.touched || this.control.dirty) && !this.control.valid && !this.control.disabled) ||
      false;
  }

  public isValid(): boolean {
    return this.control &&
      ((this.control.touched || this.control.dirty) && this.control.valid && !this.control.disabled) ||
      false;
  }

  public isDisabled(): boolean {
    return this.control && this.control.disabled || false;
  }

  public getErrors(): any {
    return this.control && (this.control.errors);
  }
}
