export interface IPushNotification {
  count: number;
  payload: {
    cid: string, // "5d137cdd86a94f003aecaad3",
    count: number,
    id: string, // "5d137cdd1d98e20022da6d05",
    msg: string, // "Consumer Response",
    qid: string, // "5cc75476005826003a0be821",
    type: string, // "request",
    v: string, // "auto_insurance",
  };
  type: string; // "message"
}

export interface IPushMessage {
  _id: string; // "5d0cf401089a2500298362f2"
  accountId: string; // "5c99458be7adc400309e0dbf"
  commentId: string; // "5d0cf401f305d50041d4fdfc"
  createdAt: string; // "2019-06-21T15:13:05.891Z"
  message: string; // "Consumer Question"
  qid: string; // "5d0a52443d5027003a49a3fd"
  quoteId: string; // "5d0a52443d5027003a49a3fd"
  read: boolean;
  sid: string; // "5d0b6da03ea64e004186d629"
  subQuoteId: string; // "5d0b6da03ea64e004186d629"
  type: string; // "quote"
  updatedAt: string; // "2019-06-25T15:01:44.214Z"
}

export class NotificationModel {

  constructor(notification: IPushNotification | IPushMessage) {

    this._notification = JSON.parse(JSON.stringify(notification));
    this._notification.receivedAt = new Date();

    if (this.isNotification) {
      // notifications always are
      // "unread" when received
      this.read = false;
    }
  }

  private _notification: any = {};

  get isMessage(): boolean {
    return typeof this._notification.payload === 'undefined';
  }

  get isNotification(): boolean {
    return typeof this._notification.payload !== 'undefined';
  }

  get id(): string {
    return this.isNotification ? this.payload.id : this._notification._id;
  }

  get read(): boolean {
    return this._notification.read;
  }

  get vertical(): string {
    return this.payload.v || 'auto_insurance';
  }

  get type(): string {
    return this.payload.type;
  }

  get qid(): string {
    return this.payload.qid;
  }

  get sid(): string | null {
    return this.payload.sid;
  }

  get message(): string {
    return this.isNotification ? this.payload.msg : this.payload.message;
  }

  get isQuote(): boolean {
    return this.type === 'quote';
  }

  get isRequest(): boolean {
    return this.type === 'request';
  }

  get notificationType(): string {
    return this.payload.notificationType;
  }

  get notificationCount(): string {
    return this.payload.notificationCount;
  }

  get payload(): any {
    // copy obj
    const notif = JSON.parse(JSON.stringify(this._notification));

    if (this.isNotification) {
      // rename notification keys
      const obj = {
        notificationType: this._notification.type,
        notificationCount: this._notification.count,
      };
      // flatten payload
      return { ...obj, ...notif.payload };
    } else {
     // rename message keys
      notif.qid = this._notification.quoteId;
      notif.sid = this._notification.subQuoteId;
      notif.cid = this._notification.commentId;

      return notif;
    }
  }

  get queryParams(): any {
    const queryParams: any = {
      qtab: this.isRequest ? 'messages' : 'quotes',
    };

    if (this.qid) {
      queryParams.qid = this.qid;
    }

    if (this.sid) {
      queryParams.sid = this.sid;
    }

    return queryParams;
  }

  set read(read: boolean) {
    this._notification.read = read;
  }

  public markAsRead(): void {
    this.read = true;
  }

  public isQuoteId(quote: any | null): boolean {
    if (!quote) {
      return false;
    }
    return quote.id === this.qid;
  }
}
