<div class='home-choose'>
  <div class='container'>
    <div class='row'>
      <div class='col-sm-12 mb-5'>
        <h2>Why choose us?</h2>
      </div>
    </div>
    <div class='row  home-choose-holder'>
      <section class='home-choose-block col-sm-4'>
        <h3>
          Capture &amp; Delivery
        </h3>
        <p class='description'>
          In-Market Consumers Looking for Quotes Delivered to Your Inbox
        </p>
      </section>
      <section class='home-choose-block col-sm-4'>
        <h3>
          Quote Dashboard
        </h3>
        <p class='description'>
          Manage the Quoting Process from our Simple to Use Dashboard
        </p>
      </section>
      <section class='home-choose-block col-sm-4'>
        <h3>
          Opportunity
        </h3>
        <p class='description'>
          You will have the opportunity to Quote Customers Looking for Auto Insurance.
        </p>
      </section>
      <section class='home-choose-block col-sm-4'>
        <h3>
          Support
        </h3>
        <p class='description'>
          Receive ongoing support and expertise from our account managers to help your business grow and succeed.
        </p>
      </section>
      <section class='home-choose-block col-sm-4'>
        <h3>
          Patent-Pending Technology
        </h3>
        <p class='description'>
          Matches you with the right customers and allows you to quote and communicate directly.
        </p>
      </section>
      <section class='home-choose-block col-sm-4'>
        <h3>
          Notifications
        </h3>
        <p class='description'>
          Get Notified Immediately when a Customer is Ready to Bind Their Auto Policy.
        </p>
      </section>
    </div>
    <div class='row'>
      <div class='col-sm-12'>
        <button class='btn round btn-signup pink'
          (click)='showRegistrationModal()'>
          Join our network
        </button>
      </div>
    </div>
  </div>
</div>
