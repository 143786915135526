<div class='home-strategy container'>
  <p class='title mb-5'>How are you competing in this digital age, when the majority of people are searching for insurance
  products online?</p>
  <section class='home-strategy-block row'>
    <div class='image col-md-6 col-12'>
      <img src='https://s3-us-west-2.amazonaws.com/squeeze-pics/Leads/SQZ_LP_Home_colab.jpg' alt='Lead generation and customer acquisition'/>
    </div>
    <div class='description-block col-md-6 col-12'>
      <p class='title'>The problem, as we see it:</p>
      <ul class='list'>
        <li>The cost to compete online is too expensive - requiring a lot of specialized skill sets, costly software, and large budgets</li>
        <li>Traditional media such as newspapers are no longer effective</li>
        <li>Growth is dependent on word-of-mouth and networking</li>
        <li>Direct-to-market companies spend billions of dollars competing for the same customers</li>
      </ul>
    </div>
  </section>
  <section class='home-strategy-block row'>
    <div class="col-12">
      <p class='title'>Why buying leads typically doesn't work?</p>
      <p class='description'>Because it requires a lot of time and resources to pursue the leads you purchased (how many times have you had to chase a person down hoping that they would answer their phone or return your messages?) You're probably not equipped with the infrastructure for a "speed-to-lead" business model. You are competing against a number of other agencies simultaneously trying to reach out to the same customer, creating a poor experience for the customer as well as you.</p>
    </div>
  </section>
  <section class='home-strategy-block row'>
    <div class="col-12">
      <p class='title'>What makes Squeeze's marketplace the right solution for my business?</p>
      <p class='description'>To start with, you no longer need to race to be the first to chase down a lead, or spend a ton of money across multiple marketing channels hoping your phone will ring.
        Squeeze connects high-intent, in-market consumers with independent agents in a manner that works for both sides. Simply choose the number of customers you'd like to get in front of per day, and Squeeze will deliver completed insurance applications to your inbox.
        You provide up to three quotes to be sent back to the customer and we alert them when they are ready for their review.
      </p>
      <p class='description'>
        Put plainly, by participating in our online marketplace you save time and money - and the consumer receives a better experience.
      </p>
      <p class='description'>
        Even better, while even the most sophisticated insurance agents can benefit from our platform,  you do not have to be a marketing expert. You do what you do best, and let Squeeze take care of the rest.
      </p>
    </div>
    <button
      class='btn round btn-signup pink home-strategy-btn'
      (click)='showRegistrationModal()'
    >Join Our Network</button>
  </section>
</div>
