import { Component } from '@angular/core';
import { GlobalModalsClass } from '@models';
import { GlobalModalService } from '@services';

@Component({
  selector: 'app-global-confirm',
  templateUrl: './global-confirm.component.html',
  styleUrls: ['../global-modals.component.scss'],
})
export class GlobalConfirmComponent extends GlobalModalsClass {

  constructor(
    public globalModalService: GlobalModalService,
  ) {
    super(globalModalService);
  }

  public onConfirm(): void {
    this.globalModalService.toogleModal(true);
  }

  public onCancel(): void {
    this.globalModalService.toogleModal(false);
  }
}
