/** Footer component contains footer of the application
 *
 */

import {
  Component,
  OnInit,
} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.scss' ],
})
export class FooterComponent implements OnInit {

  get currentYear(): number {
    return moment().year();
  }

  constructor(
    ) {
  }

  ngOnInit(): void {
  }
}
