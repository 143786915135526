import {
  Pipe,
  PipeTransform,
} from '@angular/core';

@Pipe({ name: 'ArrayToString' })
export class ArrayToStringPipe implements PipeTransform {

  constructor(
  ) {}

  transform(value: string[]): string {
    return value.map((elem: string) => elem.replace(/_/ig, ' ')).join(', ');
  }
}
