/** Home component contains landing page of the application
 *
 */

import {
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';

import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';

import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements OnInit, OnDestroy {

  public isShowRegistrationModal: boolean = false;
  public isShowLoginModal: boolean = false;

  @LocalStorage('buyer')
  public buyer: any;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {

  }

  ngOnInit(): any {
    if (this.buyer && this.buyer.account) {
      return this.router.navigate(['/portal/dashboard']);
    }
  }

  ngOnDestroy(): any {
    this.authService.changeRegistrationModalVisibility(false);
  }

  // TODO: make class with this functionality, and extend components
  public showRegistrationModal(): void {
    if (this.authService.browserName !== 'IE' || this.authService.acknowledgedBrowserSupport) {
      this.authService.changeRegistrationModalVisibility(true);
    } else {
      this.authService.checkBrowser();
      this.authService.browserModalClosed.subscribe((value: any): void => {
        if (value) {
          this.authService.changeRegistrationModalVisibility(true);
        }
      });
    }
  }

}
