<div class='button-loader'>
  <button
    [disabled]='disabled'
    class='btn {{customClasses}}'
    (click)='onButtonClick()'
    >
    {{actualLabel}}
  </button>
  <app-form-loader
    [isSmall]='true'
    *ngIf='loading'
    >
  </app-form-loader>
</div>