import {
  Injectable,
  EventEmitter,
} from '@angular/core';
import { PortalService } from './portal.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  // TODO: make model for this service and carriers.service
  // TODO: make interface for campaign and store

  public ready: EventEmitter<boolean> = new EventEmitter();

  public campaignName: string = 'insurances';
  public campaignType: string = 'auto';

  private store: any = {
    auto: [],
    home: [],
  };

  constructor(
    private portalService: PortalService,
    ) { }

  get id(): string {
    if (!this.campaign) {
      return '';
    }
    return this.campaign._id;
  }

  get campaign(): any {
    return this.store[this.campaignType][this.store[this.campaignType].length - 1];
  }

  public init(options?: {name: string, type: string}): void {
    this.setOptions(options);
    if (!this.store[this.campaignType].length) {
      this.fetchCampaign();
    } else {
      this.ready.emit(this.campaign);
    }
  }

  private setOptions(options?: {name: string, type: string}): void {
    if (options) {
      this.campaignName = options.name;
      this.campaignType = options.type;
    }
  }

  private fetchCampaign(): void {
    this.portalService.getCampaign(this.campaignName, this.campaignType)
      .then((campaign: any) => {
        this.store[this.campaignType].push(campaign);
        this.ready.emit(this.campaign);
      })
      .catch(this.handleError.bind(this));
  }

  private handleError(errorResponse: any): void {
    console.log('CampaignService|error', errorResponse);
  }
}
