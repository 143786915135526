import {
  Component,
  Input,
} from '@angular/core';
import { asSeenBrands } from './as-seen-in.constants';

@Component({
  selector: 'app-as-seen-in',
  templateUrl: './as-seen-in.component.html',
  styleUrls: ['./as-seen-in.component.scss'],
})
export class AsSeenInComponent {

  @Input()
  public minVersion: boolean = false;

  @Input()
  public showTitle: boolean = true;

  public asSeenBrands: String[] = asSeenBrands;

  constructor(
    ) {
  }
}
