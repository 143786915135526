import {
  Injectable,
  EventEmitter,
} from '@angular/core';
import { RequestService } from '@common/services/request.service';
import { environment } from '@environments/environment';
import { CARRIERS_TYPES } from '@constants';

@Injectable({
  providedIn: 'root',
})
export class CarriersService {
  // TODO: make model for this service and campaign.service

  private store: any = {};
  private type: string = CARRIERS_TYPES.auto;

  public ready: EventEmitter<any> = new EventEmitter();

  get carriers(): any {
    return this.store[this.type] ? this.store[this.type] : null;
  }

  get blockingValues(): string[] {
    // These carrier will be locked for selection, but showed for users which selected it before
    return ['GEICO'];
  }

  constructor(
    private requestService: RequestService,
    ) { }

  public init(carrierType: string = CARRIERS_TYPES.auto): void {
    this.type = carrierType;

    if (!this.store[carrierType]) {
      this.fetchCarriers(carrierType);
    } else {
      this.ready.emit(this.store[this.type]);
    }
  }

  private fetchCarriers(type: string = CARRIERS_TYPES.auto): void {
    this.requestService
      .promise('GET', `${environment.squeeze_internal_api_url}/carriers?tags=${type}`)
      .then((response: any): void => {
        this.store[type] = response;
        this.ready.emit(response);
      })
      .catch(this.handleError.bind(this));
  }

  private handleError(errorResponse: any): void {
    console.log('CarrierService|error', errorResponse);
  }
}
