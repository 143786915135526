/** Login form component contains form to register in the application
 *
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit, OnDestroy, } from '@angular/core';
import { FormBuilder, Validators, } from '@angular/forms';
import { LocalStorage } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { EmailAsyncValidatorService } from '../../../common/validators/email-async-validator.service';
import { AuthService } from '../../auth.service';
export class LoginFormComponent {
    constructor(formBuilder, emailAsyncValidator, authService, router) {
        this.formBuilder = formBuilder;
        this.emailAsyncValidator = emailAsyncValidator;
        this.authService = authService;
        this.router = router;
        this.showLoginForm = false;
        this.isInfoStage = true;
        this.loading = false;
        this.errorMessage = null;
        this.subscription = this.authService.isShowLoginModal().subscribe((result) => {
            this.showLoginForm = result;
        }, (error) => {
            console.log('error in subscription', error);
        });
    }
    ngOnInit() {
        this.createForms();
    }
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    hideLoginModal() {
        this.authService.changeLoginModalVisibility(false);
    }
    createForms() {
        this.loginForm = this.formBuilder.group({
            emailAddress: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(100),
                ]),
                this.emailAsyncValidator.validateEmailFormatOnly,
            ],
            password: [
                null,
                Validators.compose([
                    Validators.minLength(0),
                    Validators.maxLength(100),
                    Validators.required,
                ]),
            ],
        });
    }
    onSubmit() {
        if (!this.loginForm || !this.loginForm.value) {
            return;
        }
        const buyerData = Object.assign({}, this.loginForm.value);
        this.loading = true;
        this.authService.login(buyerData)
            .then((buyer) => {
            this.buyer = buyer;
            this.loading = false;
            if (this.loginForm) {
                this.loginForm.markAsPristine({ onlySelf: true });
            }
            this.hideLoginModal();
            this.router.navigate(['/portal/dashboard']);
        })
            .catch((errorResponse) => {
            if (this.loginForm) {
                this.loginForm.markAsPristine({ onlySelf: true });
            }
            this.loading = false;
            this.errorMessage = { message: 'Oh dear, something went wrong' };
            // not sure why, but just in case:
            try {
                errorResponse = errorResponse.json();
            }
            catch (e) { }
            if (errorResponse && errorResponse.error) {
                if (errorResponse.error &&
                    typeof errorResponse.error === 'string') {
                    this.errorMessage = { message: errorResponse.error };
                }
                else if (errorResponse.error.error &&
                    typeof errorResponse.error.error === 'string') {
                    this.errorMessage = { message: errorResponse.error.error };
                }
            }
        });
    }
    isLoginError() {
        return !!this.errorMessage && (this.loginForm.touched || this.loginForm.dirty);
    }
}
__decorate([
    LocalStorage('buyer'),
    __metadata("design:type", Object)
], LoginFormComponent.prototype, "buyer", void 0);
