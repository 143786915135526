/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notifications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./notifications.component";
import * as i4 from "@angular/router";
import * as i5 from "../../common/services/group-by.service";
import * as i6 from "../../common/services/notifications.service";
import * as i7 from "../../common/services/quotes.service";
var styles_NotificationsComponent = [i0.styles];
var RenderType_NotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationsComponent, data: {} });
export { RenderType_NotificationsComponent as RenderType_NotificationsComponent };
function View_NotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "empty-msg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No notifications at this time"]))], null, null); }
function View_NotificationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "notification"]], [[2, "read", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleNotificationClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "notification-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "highlight"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["\u00A0", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.read; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.message; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.fromNow(_v.context.$implicit.receivedAt); _ck(_v, 5, 0, currVal_2); }); }
function View_NotificationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "notification quote-request-message"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleMultipleMessagesClick(_co.unreadQuoteRequestMessages) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "notification-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "highlight"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["You have ", " unread messages"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["\u00A0", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.unreadQuoteRequestMessages.length; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.fromNow(_co.unreadQuoteRequestMessages[0].updatedAt); _ck(_v, 5, 0, currVal_1); }); }
function View_NotificationsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "notification quote-message"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleMultipleMessagesClick(_co.unreadSubQuoteMessages[_v.parent.context.$implicit]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "notification-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "highlight"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["(", ") ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "id"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["\u00A0on ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["\u00A0", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.unreadSubQuoteMessages[_v.parent.context.$implicit].length; var currVal_1 = _co.unreadSubQuoteMessages[_v.parent.context.$implicit][0].message; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.displayId(_v.parent.context.$implicit); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.fromNow(_co.unreadSubQuoteMessages[_v.parent.context.$implicit][0].updatedAt); _ck(_v, 8, 0, currVal_3); }); }
function View_NotificationsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit != "undefined"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NotificationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "notification-dropdown"], ["id", "notification-dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "notification-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "triangle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Notifications"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "notification-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_2)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_3)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_4)), i1.ɵdid(15, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isEmpty; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.messagesNotifications; _ck(_v, 11, 0, currVal_1); var currVal_2 = (_co.unreadQuoteRequestMessages.length > 0); _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.unreadSubQuoteMessagesKeys; _ck(_v, 15, 0, currVal_3); }, null); }
export function View_NotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notifications", [], null, null, null, View_NotificationsComponent_0, RenderType_NotificationsComponent)), i1.ɵdid(1, 49152, null, 0, i3.NotificationsComponent, [i4.Router, i5.GroupByService, i6.NotificationsService, i7.QuotesService], null, null)], null, null); }
var NotificationsComponentNgFactory = i1.ɵccf("app-notifications", i3.NotificationsComponent, View_NotificationsComponent_Host_0, { notifications: "notifications", messages: "messages" }, { closeEvent: "closeEvent" }, []);
export { NotificationsComponentNgFactory as NotificationsComponentNgFactory };
