import { Injectable } from '@angular/core';
import {
  HttpClient,
} from '@angular/common/http';
import { environment } from '@environments/environment';

interface IPlace {
  street_number: string;
  street: string;
  state: string;
  postal_code: string;
  locality: string;
  country: string;
}

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {

  constructor(private httpClient: HttpClient) { }

  public geocode(address: string): Promise<any>  {
    const url = 'https://maps.googleapis.com/maps/api/geocode/json';
    const params = {
      key: environment.google_api_key,
      address: address,
    };
    return this.httpClient.get(url, { params })
      .toPromise()
      .then((response: any) => {
        let results: Array<any> = [];
        if (response.status === 'OK') {
          const places = response.results;
          results = places.map((place: any) => {
            this.parseAddresComponents(place.address_components);
          });
        }
        return results;
      });
  }

  private parseAddresComponents(components: Array<any>): IPlace {
    const data: IPlace = {
      street_number: '',
      street: '',
      state: '',
      postal_code: '',
      locality: '',
      country: '',
    };

    components.forEach((item: any) => {
      if (item.types.includes('street_number')) {
        data.street_number = item.short_name;
      }
      if (item.types.includes('route')) {
        data.street = item.short_name;
      }
      if (item.types.includes('administrative_area_level_1')) {
        data.state = item.short_name;
      }
      if (item.types.includes('postal_code')) {
        data.postal_code = item.short_name;
      }
      if (item.types.includes('locality')) {
        data.locality = item.short_name;
      }
      if (item.types.includes('country')) {
        data.country = item;
      }
    });

    return data;
  }
}
