var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as Rx from 'rxjs/Rx';
import { LocalStorage } from 'ngx-webstorage';
/* tslint:disable */
// TODO: should be update to 'new Observable' and 'new Subject' because 'create' is deprecated
export class WebsocketService {
    constructor() {
        this.subject = null;
    }
    connect(url) {
        if (!this.subject) {
            this.subject = this.create(url);
            console.log('Successfully connected: ' + url);
        }
        return this.subject;
    }
    create(url) {
        const jwt = this.user.jwt;
        const ws = new WebSocket(url, ['access_token', jwt]);
        const observable = Rx.Observable.create((obs) => {
            ws.onmessage = obs.next.bind(obs);
            ws.onerror = obs.error.bind(obs);
            ws.onclose = obs.complete.bind(obs);
            return ws.close.bind(ws);
        });
        const observer = {
            next: (data) => {
                if (ws.readyState === WebSocket.OPEN) {
                    ws.send(JSON.stringify(data));
                }
            },
        };
        return Rx.Subject.create(observer, observable);
    }
}
__decorate([
    LocalStorage('buyer'),
    __metadata("design:type", Object)
], WebsocketService.prototype, "user", void 0);
