export const QUESTIONNAIRE_TYPES = {
  DONE: 'done',
};

export const QUESTIONNAIRE_AUTO_FLOW = [
  {
    index: 0,
    disabled: true,
    name: 'Carriers',
    id: 'carriers',
  },
  {
    index: 1,
    disabled: true,
    name: 'Location',
    id: 'location',
  },
  {
    index: 2,
    disabled: true,
    name: 'Quantity',
    id: 'quantity',
  },
  {
    index: 3,
    disabled: true,
    name: 'Delivery',
    id: 'delivery',
  },
];

export const QUESTIONNAIRE_HOME_FLOW = [
  {
    index: 0,
    disabled: true,
    name: 'Carriers',
    id: 'carriers',
  },
  {
    index: 1,
    disabled: true,
    name: 'Location',
    id: 'location',
  },
  {
    index: 2,
    disabled: true,
    name: 'Quantity',
    id: 'quantity',
  },
  {
    index: 3,
    disabled: true,
    name: 'Delivery',
    id: 'delivery',
  },
];

export const QUESTIONNAIRE_PAYMENT_FLOW = [
  {
    index: 0,
    disabled: false,
    name: 'Details',
    id: 'payment',
  },
];

export const QUESTIONNAIRE_PAYMENT_SECTION = {
  name: 'Payment',
  label: 'Payment',
  id: 'payment',
  disabled: true,
  available: false,
  extra: true,
  flow: QUESTIONNAIRE_PAYMENT_FLOW,
};

export const QUESTIONNAIRE_AVAILABLE_SECTIONS = [
  {
    name: 'Auto Insurance',
    label: 'Auto',
    id: 'auto',
    disabled: true,
    availableForUser: true,
    flow: QUESTIONNAIRE_AUTO_FLOW,
  },
  {
    name: 'Home Insurance',
    label: 'Home',
    id: 'home',
    disabled: true,
    availableForUser: true,
    flow: QUESTIONNAIRE_HOME_FLOW,
  },
  {
    name: 'Boat Insurance',
    label: 'Boat',
    id: 'boat',
    disabled: true,
    availableForUser: true,
    flow: QUESTIONNAIRE_HOME_FLOW,
  },
  {
    name: 'Motorcycle Insurance',
    label: 'Motorcycle',
    id: 'motorcycle',
    disabled: true,
    availableForUser: true,
    flow: QUESTIONNAIRE_HOME_FLOW,
  },
];

export const QUESTIONNAIRE_INACCESSIBLE_SECTIONS = [
  {
    name: 'Condo',
    label: 'Condo',
    id: 'condo',
    availableForUser: false,
  },
  {
    name: 'Renters',
    label: 'Renters',
    id: 'renters',
    availableForUser: false,
  },
  {
    name: 'Manufactured / Mobile Home',
    label: 'Manufactured / Mobile Home',
    id: 'manufactured',
    availableForUser: false,
  },
];

export const QUESTIONNAIRE_SECTIONS = [
  ...QUESTIONNAIRE_AVAILABLE_SECTIONS,
  ...QUESTIONNAIRE_INACCESSIBLE_SECTIONS,
];

export const QUESTIONNAIRE_INTERESTS: any = [
  {
    name: 'insurance',
    label: 'Insurance',
    items: [
      {
        name: 'health_insurance',
        label: 'Health Insurance',
        active: false,
      },
      {
        name: 'life_insurance',
        label: 'Life Insurance',
        active: false,
      },
      {
        name: 'business_insurance',
        label: 'Business Insurance',
        active: false,
      },
    ],
  },
];
